import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { UtilService } from 'src/app/services/util-service/util.service';
import { FormlyBuilderService } from '../../formly-builder.service';
import { DryPipeValveTripTestDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/drypipe-valve-trip-test-data.model';


// Dry pipe valve trip test Sheet datas needs to be saved when user hits on apply in Dry pipe valve trip test sheet
// (i.e) In Case of Dry pipe valve trip test there will be two sheets one top of another, 
// When user hits apply on the top level sheet, it should call the updateData method and the changes needs to be saved to s3
@Injectable({
  providedIn: 'root',
})
export class DryPipeValveTripTestService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    // Will get Called only when user hits apply inside Dry Pipe Valve Trip Sprinkler Test sheet
   const DryPipeValveTripTests = [];
   model?.sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.dryPipeValveTripTests?.forEach((dryPipeValveTripTest) => {
    // DryPipeValveTripTests.push(DryPipeValveTripTestDataModel.to(dryPipeValveTripTest));
    const DryPipeValveTrip = DryPipeValveTripTestDataModel.to(dryPipeValveTripTest);
      if (DryPipeValveTrip?.TestDate) {
        DryPipeValveTrip.TestDate = this.utilService.convertDateToISOStringFormat(DryPipeValveTrip.TestDate);
      }
      DryPipeValveTripTests.push(DryPipeValveTrip);
   });
   const newRr = {
     ...riskReportData,
     SprinklerReport: {
       ...riskReportData.SprinklerReport,
       AsgrSprinklerSystemTest: {
         ...riskReportData.SprinklerReport.AsgrSprinklerSystemTest,
         ...{ DryPipeValveTripTests: DryPipeValveTripTests }
       }
     }
   }
   return { ...riskReportData, ...newRr };
 }

 loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
   // Will get Called only when user hits apply inside Dry pipe valve trip test sheet or hit close inside Dry pipe valve trip test sheet
   const DryPipeValveTripTests = sectionData;
   let dryPipeValveTripTest = [];
   if (DryPipeValveTripTests && DryPipeValveTripTests.length) {
    DryPipeValveTripTests.forEach((test) => {
       const dryPipeValveTripTestData = DryPipeValveTripTestDataModel.fromS3(test);
      dryPipeValveTripTest.push(dryPipeValveTripTestData);
     });
   }
   
   const newModel = {
     ...model,
     sprinklerReport: {
       ...model.sprinklerReport,
       asgrSprinklerSystemTest: {
         ...model.sprinklerReport.asgrSprinklerSystemTest,
         dryPipeValveTesting: {
          ...model.sprinklerReport.asgrSprinklerSystemTest.dryPipeValveTesting,
         ...{ dryPipeValveTripTests: dryPipeValveTripTest }
         }
       }
     }
   }
   if (newModel) {
     this.formlyBuilderService.riskReport.model = { ...newModel };
   }
   return this.formlyBuilderService.riskReport.model;
 }


  async onApply(modalCtrl, field) {
    // When apply we need to save the value to S3 here
    const dryPipeValveTripTestService = field.options.formState.service.dryPipeValveTripTestService;
    const riskReportService = field.options.formState.riskReportService;
   const rr = await riskReportService.updateSectionData(SectionEnum.FR_SR_ST_DRY_PIPE_VALVE_TRIP_TEST, false, null, DryPipeValveTripTestService);

   // After hits apply in the sheet, It needs to be sorted based on most recent date
   let DryPipeValveTripTests = rr?.SprinklerReport?.AsgrSprinklerSystemTest?.DryPipeValveTripTests;
   DryPipeValveTripTests = field.options.formState.service.sprinklerReportService.sortArrayWithMostRecentDate(DryPipeValveTripTests, 'TestDate');

   // loadData to set the sorted value back to model
   dryPipeValveTripTestService.loadData(DryPipeValveTripTests, rr, true, riskReportService.model);
   setTimeout(() => {
    if (DryPipeValveTripTests && DryPipeValveTripTests.length) {
      if (DryPipeValveTripTests[length].TestDate && !field?.form?.get('dpvTestDate').value) {
        field?.form?.get('dpvTestDate')?.setValue(DryPipeValveTripTests[length].TestDate);
      }
    }
  },100);

   modalCtrl.dismiss(null, 'save');
 }

 onClose(modalCtrl, field, initialModel) {
   // Tried with patchValue and resetModel
   // Tried with reset and patchValue with forLoop for each array values
   // The above two didin't help so going with load Data on close 
   const formlyBuilderService = field.options.formState.service;
   const riskReportService = field.options.formState.riskReportService;
   const formlyModel = riskReportService.model;
   const rr = riskReportService.report;
   formlyBuilderService.dryPipeValveTripTestService.loadData(rr?.SprinklerReport?.AsgrSprinklerSystemTest?.DryPipeValveTripTests, rr, true, formlyModel);
   formlyBuilderService.errorService.closePreviousToaster();
   modalCtrl.dismiss(null, 'cancel');
 }

 getTestResultOptions(): any[] {
  return [
    { value: 'S', label: 'Satisfactory' },
    { value: 'U', label: 'Unsatisfactory' },
  ]
 }

 // will return the label of TestResultcode
 getTestResultValue(field, testResultCodeValue) {
  const dryPipeValveTripTestService = field.options.formState.service.dryPipeValveTripTestService;
  const options = dryPipeValveTripTestService.getTestResultOptions();
  const option = options.find((option) => option.value === testResultCodeValue);
  return option ? option.label : '';
 }

 hasDuplicateWithDifferentFields(field): boolean {
  let allValues;

  if (typeof field.formControl.value === 'string') {
    allValues = field?.parent?.form?.value;
  } else {
    allValues = field?.formControl?.value;
  }
  const systemNumbers = {};
  for (const obj of allValues) {
    if (systemNumbers[obj.systemNumber]) {
      // Found a duplicate systemNumber
      const existingObjs = systemNumbers[obj.systemNumber];
      for (const existingObj of existingObjs) {
        if (
          existingObj.testDate !== obj.testDate ||
          existingObj.airPressure !== obj.airPressure ||
          existingObj.waterPressure !== obj.waterPressure ||
          existingObj.alarmActivationTime !== obj.alarmActivationTime ||
          existingObj.testType !== obj.testType ||
          existingObj.testResults !== obj.testResults ||
          existingObj.testConductedBy !== obj.testConductedBy
        ) {
          // Other fields are different, continue checking
          continue;
        } else {
          // Other fields are the same, return true
          return true;
        }
      }
      // If no match found, add the object to existingObjs array
      existingObjs.push(obj);
    } else {
      // Store the object with its systemNumber
      systemNumbers[obj.systemNumber] = [obj];
    }
  }
  // No duplicate systemNumber with different fields found
  return false;
 }

}

