import { formatDate } from '@angular/common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class SurveyDetailsModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor() {
    super({
      key: 'survey',
      wrappers: [ 'simple-section-wrapper' ],
      props: {
        label: 'Survey Details',
        isCollapsable: true
      },
      fieldGroupClassName: 'survey-details-container',
      fieldGroup: [
        {
          key: 'surveyDate',
          type: 'rf-date-picker',
          wrappers: [ 'question-wrapper' ],
          props: {
            label: 'Survey Date',
            displayFormat: 'MM/dd/yy',
            datePickerId: 'surveyDate',
            required: true,
            isSectionLabelNormal: true,
            readonly: false,
            showError: false,
            isTitleDisableable: false,
            isAsteriskNotRequired: true,
            minDate: new Date()
          },
          expressions: {
            'props.minDate': () => {
              const date = new Date();
              date.setMonth(date.getMonth() - 2);
              return formatDate(date, 'YYYY-MM-ddThh:mm:ss', 'en-US');
            }
          },
          validators: {
            validation: [ { name: 'future-date-validator', options: { msg: 'Survey date cannot be in the  future' } } ]
          },
          // hooks: {
          //   onInit: (field: FormlyFieldConfig) => {
          //     if (field.formControl?.value === null && field.options.formState?.order?.AppointmentSet &&
          //       field.options.formState?.order?.AppointmentSet?.length > 0) {
          //       field.formControl.setValue(field.options.formState?.order?.AppointmentSet);
          //     }
          //   }
          // }
        },
        {
          key: 'dataCreation',
          type: 'simple-select',
          wrappers: [ 'question-wrapper' ],
          defaultValue: 'On-site Survey',
          props: {
            label: 'Data Creation',
            isHorizontalOrientation: true,
            isLabelDisabled: true,
            required: true,
            isAsteriskNotRequired: true,
            isSectionLabelNormal: true,
            options: [
              { value: 'On-site Survey', label: 'On-site Survey' },
              { value: 'OneXperience Loss Cost', label: 'OneXperience Loss Cost' },
              { value: 'OneXperience ELC', label: 'OneXperience ELC' },
            ]
          },
        },
        {
          key: 'riskTypeCodeValue',
          defaultValue: 'SDAP',
          props: { label: 'Survey Type' }
        }
      ],
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.formControl?.valueChanges?.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
            debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
                const formlyService = field.options.formState.service;
                formlyService.riskReport.updateSectionData(SectionEnum.SURVEY_DETAILS, false);
              }
            });
        },
        onDestroy: () => {
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
        }
      }
    });
  }

}
