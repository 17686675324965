import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserData } from '../app-service/app.interfaces';
import { AuthService as IonicAuth } from 'ionic-appauth';
import { FridService } from '../frid-service/frid.service';
import { AmplifyService } from '../amplify/amplify.service';
import { TokenResponse } from '@openid/appauth';
import { Auth, DataStore } from 'aws-amplify';
import { NavController, Platform } from '@ionic/angular';
import { ENV, Environment } from 'src/app/interfaces/env.interface';
import { UtilService } from '../util-service/util.service';
import { SurveyOrder, User } from 'src/models';
import { StorageService } from '../storage-service/storage.service';
// import { Auth } from 'aws-amplify';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: UserData | undefined;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public federatedSignInComplete: boolean = false;
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  public loggedInUser: any;
  constructor(
    @Inject(ENV) private environment: Environment,
    private ionicAuth: IonicAuth,
    private fridService: FridService,
    private amplifyService: AmplifyService,
    private navCtrl: NavController,
    public utilService: UtilService,
    public platform: Platform,
    private storage: StorageService
  ) {}
  async postLoginSetup(loggedInUser, homePageNavigate) {
    this.loggedInUser = loggedInUser;
    await this.updateTokens();

    if (!this.platform.is('capacitor')) {
      const frId = await this.fridService.getToken();
      const allUsers = await DataStore.query(User);
      let amplifyUser: User = allUsers.find((item) => item.userId === frId);
      if (this.environment.users) {
        const user = this.environment.users.find((item) => item === frId);
        if (user) {
          if (homePageNavigate) this.navCtrl.navigateRoot('home');
        } else {
          this.verifyUserRolesFromAmplify(amplifyUser, homePageNavigate);
        }
      } else {
        this.verifyUserRolesFromAmplify(amplifyUser, homePageNavigate);
      }
    } else {
      if (homePageNavigate) this.navCtrl.navigateRoot('home');
    }
  }

  verifyUserRolesFromAmplify(amplifyUser, homePageNavigate) {
    if (amplifyUser.data && amplifyUser.data['Roles'].length >= 1) {
      const isFieldRep = amplifyUser.data['Roles'].some((element) => element.Name === 'Field Rep');     
      
      if (isFieldRep) {
        this.navCtrl.navigateRoot('no-access');
      } else if (homePageNavigate) {
        this.navCtrl.navigateRoot('home');
      }
    } else {
      if (homePageNavigate) this.navCtrl.navigateRoot('home');
    }
  }

  async updateTokens() {
    let oktaToken = await this.ionicAuth.getValidToken();
    //Save acces token for offline use
    if (oktaToken) {
      this.storage.setObject('access_token', oktaToken.accessToken);
    }
    let amplifyToken = localStorage.getItem('aws-amplify-federatedInfo');
    let currentCredentials = await Auth.currentCredentials();
    // this.utilService.addTextConsoleLog(
    //   'currentCredentials',
    //   currentCredentials.authenticated + '-' + currentCredentials?.expiration
    // );
    console.log(currentCredentials);
    if (!amplifyToken || !currentCredentials.authenticated) {
      await Auth.signOut();
      const oktaDomainName: string = this.environment.auth_config.server_host.split('//')[1];
      let token: TokenResponse = await this.ionicAuth.getValidToken();
      let idToken: string | undefined = token.idToken;
      let expireAt: number = JSON.parse(atob(idToken.toString().split('.')[1])).exp;
      this.utilService.addTextConsoleLog('federatedSignin', 'trying auth fed signin');
      const expiryDate = new Date(expireAt * 1000);
      this.utilService.addTextConsoleLog('idTokenexpireAt', expiryDate);
      await Auth.federatedSignIn(
        oktaDomainName,
        {
          token: idToken,
          expires_at: expireAt,
        },
        this.loggedInUser
      );
    }
    const frid = await this.fridService.getToken();
    await this.amplifyService.DataStoreInit(frid);
    this.amplifyService.syncLookupFiles();
    this.amplifyService.syncTemplateFiles();
    const surveyOrders = await DataStore.query(SurveyOrder);
    this.amplifyService.syncSurveyOrders(surveyOrders);

    return oktaToken;
  }

  async amplifySignOut() {
    await Auth.signOut();
  }
  async hasTokenExpired() {
    let token = localStorage.getItem('aws-amplify-federatedInfo');
    // Check if amplifyToken exists in localStorage
    if (!token) {
      return true; // No token available;
    }
    let amplifyToken = JSON.parse(token);
    let expTimelength = amplifyToken.expires_at.toString().length;
    let currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    let expirationTime = parseInt(amplifyToken.expires_at);
    if (expTimelength > 10) {
      //If length is greater than 10 then it is in milliseconds
      // Assuming amplifyToken.expires_at is in milliseconds
      if (expirationTime < currentTimeInSeconds * 1000) {
        localStorage.removeItem('aws-amplify-federatedInfo');
        return true; // Token expired;
      }
    } else {
      // Assuming amplifyToken.expires_at is in seconds
      if (expirationTime < currentTimeInSeconds) {
        localStorage.removeItem('aws-amplify-federatedInfo');
        return true; // Token expired;
      }
    }
    let currentCredentials = await Auth.currentCredentials();
    if (!amplifyToken || !currentCredentials.authenticated) {
      return true; // No token or authenticated credentials; consider as expired.
    }
    return false; // Token is valid and not expired.
  }
}
