/* eslint-disable @typescript-eslint/naming-convention */
export class EnhancedWindEligibleDataModel {
  static sectionName = 'enhancedWindEligible';

  isWindReportDeleted: string;

  static fromS3(reports: any): EnhancedWindEligibleDataModel {
    let isWindReportDeleted = null;

    if (reports.IsWindReportDeleted === true) {
      isWindReportDeleted = 'false';
    } else if (reports.IsWindReportDeleted === false && Object.keys(reports.WindReport).length > 2) {
      isWindReportDeleted = 'true';
    } else if (reports.IsWindReportDeleted === false) {
      isWindReportDeleted = 'false';
    }
    return { isWindReportDeleted };
  }

  static to(model: any) {
    let isWindReportDeleted = null;
    if (model.isWindReportDeleted === 'true' || model.isWindReportDeleted === true) {
      isWindReportDeleted = false;
    } else if (model.isWindReportDeleted === 'false' || model.isWindReportDeleted === false) {
      isWindReportDeleted = true;
    }
    return {
      IsWindReportDeleted: isWindReportDeleted
    };
  }

  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    if (riskReportModel.IsWindReportDeleted === undefined) {
      return true;
    }

    if (formlyModel.isWindReportDeleted === riskReportModel.IsWindReportDeleted) {
      return false;
    } else {
      return true;
    }
  }

}
