import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionConfig, SectionEnum } from 'src/app/services/formly-builder/section.config';

export class CommentAndAttachmentSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      id: SectionConfig.get(SectionEnum.FR_COMMENT_ATTACHMENT).name,
      expressions: {
        'props.isDisabled': 'formState.service.mode !== 2',
      },
      fieldGroup: [
        {
          key: 'comments',
          type: 'rf-comment',
          wrappers: [ 'simple-section-wrapper' ],
          props: {
            label: 'Risk Form Comments',
            sheetLabel:'Comments',
            isLeftSwipeEnabled: true,
            isCollapsable: true,
            isCollapsed: false,
            isDisableable: true,
            commentActionKey:'formlyCommentSegmentText',
            actionButtons: [ { action: 'addSheet', label: 'Add', service: 'formlyCommentService' } ],
          },
          fieldGroup:[
            {
              key:'formlyCommentSegmentText',
              defaultValue:null
            },
            {
              key:'commentDateTime',
              defaultValue:null
            },
            {
              key : 'commentBy',
              defaultValue:null
            },
            {
              key : 'commentType',
              defaultValue:'COMT'
            },
            {
              key: 'commentIdentifier',
              defaultValue:null
            },
            {
              key: 'reportIdentifier',
              defaultValue:null
            },
            {
              key: 'commentSegments',
                fieldGroup: [
                  {
                    key: 'commentSegmentSequenceNumber',
                defaultValue:null
  
                  },
                  {
                     key : 'commentSegmentText',
                defaultValue:null
  
                  },
                  {
                    key: 'commentSegmentIdentifier',
                defaultValue:null
  
                  },
                  {
                    key: 'commentIdentifier',
                defaultValue:null
  
                  }
                ]
            

          
            }
          ],
          expressions: {
            'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.formControl?.value?.formlyCommentSegmentText ? 'Edit': 'Add',
            'props.isCollapsed': (field: FormlyFieldConfig) => field?.formControl?.value?.formlyCommentSegmentText ? false : true,
            'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => field?.formControl?.value?.formlyCommentSegmentText ? false : true       
          }
        },
        {
          key: 'frNotes',
          type: 'rf-comment',
          wrappers: ['simple-section-wrapper'],
          props: {
            label: 'FR Notes',
            sheetLabel: 'Notes',
            isLeftSwipeEnabled: true,
            isCollapsable: true,
            isCollapsed: false,
            isDisableable: true,
            commentActionKey: 'formlyFRNotesSegmentText',
            actionButtons: [{ action: 'addSheet', label: 'Add', service: 'formlyCommentService' }]
          },
          fieldGroup: [
            {
              key: 'formlyFRNotesSegmentText',
              defaultValue: null,
            },
            {
              key: 'commentDateTime',
              defaultValue: null,
            },
            {
              key: 'commentBy',
              defaultValue: null,
            },
            {
              key: 'commentType',
              defaultValue: 'FRNT',
            },
            {
              key: 'commentIdentifier',
              defaultValue: null,
            },
            {
              key: 'reportIdentifier',
              defaultValue: null,
            },
            {
              key: 'commentSegments',
              fieldGroup: [
                {
                  key: 'commentSegmentSequenceNumber',
                  defaultValue: null,
                },
                {
                  key: 'commentSegmentText',
                  defaultValue: null,
                },
                {
                  key: 'commentSegmentIdentifier',
                  defaultValue: null,
                },
                {
                  key: 'commentIdentifier',
                  defaultValue: null,
                }
              ]
            }
          ],
          expressions: {
            'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.formControl?.value?.formlyFRNotesSegmentText ? 'Edit' : 'Add',
            'props.isCollapsed': (field: FormlyFieldConfig) => field?.formControl?.value?.formlyFRNotesSegmentText ? false : true,
            'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => field?.formControl?.value?.formlyFRNotesSegmentText ? false : true
          }
        },
        {
          key: 'attachments',
          type: 'fr-common-custom-grid-view',          
          wrappers: ['simple-section-wrapper'],
          props: {
            label: 'Attachments',
            isLeftSwipeEnabled: true,
            isCollapsable: true,
            isCollapsed: false,
            isDisableable: true,
            isDelete: true,
            isEdit: true,
            gridSection: 'attachments',
            actionButtons: [
              { section: 'attachments', action: 'add', label: 'Add', isDisabled: false },
            ],            
            gridColConfig: [
              { label: 'Date', propertyPath: 'attachmentTimestamp', isDate: true, isFullYear: true, className: 'common-grid-view-width-120' },
              { label: 'ext.', propertyPath: 'fileExtension', isIcon: true, iconPrefix: 'filetype-', className: 'common-grid-view-width-100' },
              { label: 'Description', propertyPath: 'attachmentDescription', className: 'common-grid-view-width-300' },
            ],
            crudService: 'riskAttachmentService',
            isCustomEdit: true,
            className: 'padding-bottom',  
            isPaddingDisabled: true
          },
          expressions: {
            'props.isCollapsed': (field: FormlyFieldConfig) => !field?.formControl?.value?.length
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'attachmentTimestamp',
                type: 'simple-input',
                className: 'input-width-xs',
                props: {
                  label: 'Date',
                  required: true,
                  isAsteriskNotRequired: true
                },
              },
              {
                key: 'fileExtension',
                type: 'simple-input',
                className: 'input-width-xs',
                props: {
                  label: 'ext.',
                  required: true,
                  isAsteriskNotRequired: true
                }
              },              
              {
                key: 'attachmentDescription',
                type: 'simple-input',
                className: 'input-width-xs',
                props: {
                  label: 'Description',
                  required: true,
                  isAsteriskNotRequired: true
                },
              },
              {
                key: 'reportAttachmentIdentifier'
              },
              {
                key: 'reportIdentifier'
              },    
              {
                key: 'attachmentSequence'
              },
              {
                key: 'fileName'
              },
              {
                key: 'attachmentTypeCodeValue'
              },
              {
                key: 'isSprinklerAttachment'
              },              
            ]
          }
        }
      ]
    });
  }
}
