import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import moment from 'moment';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';

export class MainDrainTestingSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'mainDrainTesting',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Main Drain Testing',
        isCollapsable: true,
        isCollapsed: false,
        shallowErrors: true,
        className: 'padding-bottom'
      },
      className: 'main-drain-testing-container',
      fieldGroup: [
        {
          key: 'mainDrainTestDate',
          type: 'rf-date-picker',
          className: 'date-width',
          props: {
            label: 'Test Date',
            displayFormat: 'MM/dd/yy',
            datePickerId: 'mainDrainTestDate',
            isHorizontalOrientation: true,
            isCardEnabled: true
          },
          validators: {
            validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }],
            compareWithSurveyDate: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                try {
                  let surveyDate = field?.form?.root?.get('survey')?.value?.surveyDate;
                  if (surveyDate && control.value) {
                    surveyDate = moment(surveyDate);
                    const testDate = moment(control.value);
                    const diff = testDate.diff(surveyDate, 'days');
                    return !(diff && diff > 0);
                  }
                }
                catch (e) {
                  console.log(e);
                }
                return true;
              },
              message: 'If Main Drain Test date is after survey date, then use the Schedule Applied Date to enter the test date.'
            }
          },
          hooks: {
            onInit:(field: FormlyFieldConfig) => {
              if(!field.model?.mainDrainTestDate && field.model?.twoInchDrainTests?.length) {
                if(field.model?.twoInchDrainTests[0]?.testDate) {
                  field.model.mainDrainTestDate = field.model.twoInchDrainTests[0].testDate;
                }
              }
            }
          }
        },
        {
          key: 'mainDrainDateAfterSurvey',
          type: 'rf-date-picker',
          className: 'date-width',
          props: {
            label: 'Test Date After Survey',
            displayFormat: 'MM/dd/yy',
            datePickerId: 'mainDrainAfterSurveyDate',
            isHorizontalOrientation: true,
            isCardEnabled: true
          },
          resetOnHide: true,
          validators: {
            validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }]
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => !field?.parent?.parent?.model?.systemTesting?.hasScheduledAppliedDate
          }
        },
        {
          key: 'twoInchDrainTests',
          type: 'fr-common-custom-grid-view',
          className: 'two-inch-drain-grid-view-container',
          wrappers: ['simple-section-wrapper'],
          props: {
            // Props for simple section wrapper
            className: 'padding-bottom', 

            // Props for fr-common-custom-grid-view
            label: 'Two Inch Drain Tests',
            isCollapsable: true,
            isCollapsed: false,
            actionButtons: [{ section: 'systemTesting', action: 'openArraySheetAdd', label: 'Add', isDisabled: false }],
            gridSection: 'twoInchDrainTests',
            gridColConfig: [
              { label: 'System', propertyPath: 'systemNumber', className: 'common-grid-view-width-100' },
              { label: 'Date', propertyPath: 'testDate', isDate: true, className: 'common-grid-view-width-105' },
              { label: 'Static', propertyPath: 'staticPsi', unit: 'PSI', className: 'common-grid-view-width-100' },
              { label: 'Residual', propertyPath: 'residualPsi', unit: 'PSI', className: 'common-grid-view-width-100' },
            ],

            // Props for edit sheet
            title: 'System Testing',
            subTitle: 'Two Inch Drain Tests',
            isAddIdentifier: true,
            identifierKey: 'twoInchDrainTestId',
            reportIdentifierKey: 'reportId',
            reportName: 'SprinklerReport',
            isCloneFnRequired: true,
            sheetButtons: [
              {
                section: 'twoInchDrainTests',
                action: 'onClose',
                label: 'Close',
                service: 'twoInchDrainTestService',
                className: 'close-btn medium-btn',
                passInitialModel: true,
              },
              {
                section: 'twoInchDrainTests',
                action: 'onApply',
                label: 'Apply',
                service: 'twoInchDrainTestService',
                className: 'apply-btn large-btn',
                role: 'apply'
              }
            ],
          },
          expressions: {
            'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.model?.length ? 'Edit' : Constants.btnText,
            'props.actionButtons[0].action': (field: FormlyFieldConfig) => field?.model?.length ? 'openArraySheetEdit' : 'openArraySheetAdd'
          },
          fieldGroupClassName: 'container-test',
          fieldArray: {
            fieldGroupClassName: 'formly-flex-container two-inch-drain-tests-container',
            wrappers: [ 'error-info-wrapper' ],
            fieldGroup: [
              {
                key: 'systemNumber',
                type: 'simple-input',
                className: 'input-width-xs',
                props: {
                  label: 'System',
                  required: true,
                  isAsteriskNotRequired: true
                },
              },
              {
                key: 'testDate',
                type: 'rf-date-picker',
                className: 'smd-width ss-input-padding',
                props: {
                  label: 'Date Tested',
                  displayFormat: 'MM/dd/yy',
                  required: true,
                  isAsteriskNotRequired: true,
                  datePickerId: 'twoInchDrainTest'
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    if( field?.parent && field?.parent['index']) {
                      field.props.datePickerId = 'twoInchDrainTest' + (field?.parent['index']+1) ;
                    }
                  }
                },
                validators: {
                  validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }]
                }
              },
              {
                key: 'staticPsi',
                type: 'simple-input',
                className: 'static-psi-width ss-input-padding',
                props: {
                  label: 'Static Pressure',
                  type: 'number',
                  unit: 'PSI',
                  required: true,
                  isAsteriskNotRequired: true,
                },
              },
              {
                key: 'residualPsi',
                type: 'simple-input',
                className: 'residual-psi-width ss-input-padding',
                props: {
                  label: 'Residual Pressure',
                  type: 'number',
                  unit: 'PSI',
                  isAsteriskNotRequired: true,
                  required: true,
                },
              },
              {
                key: 'testConductedBy',
                type: 'simple-input',
                className: 'test-conducted-by-width ss-input-padding',
                props: {
                  label: 'Conducted By',
                  required: true,
                  isAsteriskNotRequired: true
                },
              }
            ]
          }
        }
      ]
    });
  }
}
