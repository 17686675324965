import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { FirePumpTestResultDataModel } from './fire-pump-test-result-data.model';
import { Guid } from 'guid-typescript';

export class FirePumpTestDataModel {
    static sectionName = 'systemTesting';
    firePumpTestId: string;
    reportId: string;
    testConductedBy: string;
    firePumpTestInput: FirepumpTestInput;
    firePumpTestResults?: any[];

    static fromS3(firePumpTestData: any): FirePumpTestDataModel {
        const firePumpTestResultsArray: any[] = [];
        if (firePumpTestData.FirePumpTestResults && firePumpTestData.FirePumpTestResults.length) {
            firePumpTestData.FirePumpTestResults.forEach((test) => {
                const firePumpTestResultData = FirePumpTestResultDataModel.fromS3(test);
                firePumpTestResultsArray.push(firePumpTestResultData);
                firePumpTestResultsArray.sort((a, b) => Number(a.ratedCapacity) - Number(b.ratedCapacity));
            });
        }
        const firepumpTestInputData = FirePumpTestDataModel.setFirepumpTestInput(firePumpTestData);
        return {
            firePumpTestId: firePumpTestData.FirePumpTestId,
            reportId: firePumpTestData.ReportId,
            testConductedBy: firePumpTestData.TestConductedBy,
            firePumpTestInput: firepumpTestInputData,
            firePumpTestResults: firePumpTestResultsArray
        };
    }

    static to(model: any, reportId?: Guid) {
        const firePumpTestResultsData = [];
        const firePumpTestId = model.firePumpTestId ? model.firePumpTestId : Guid.create().toString();
        model.firePumpTestResults?.forEach((test) => {
            firePumpTestResultsData.push(FirePumpTestResultDataModel.to(test, firePumpTestId));
        });
        return {
            FirePumpTestId: firePumpTestId,
            ReportId: model.reportId ? model.reportId : reportId,
            TestConductedBy: model.testConductedBy ? model.testConductedBy : '',
            PumpRatingGpm: model.firePumpTestInput?.firePump?.pumpRatingGpm ? Number(model.firePumpTestInput.firePump.pumpRatingGpm) : null,
            PumpPsi: model.firePumpTestInput?.firePump?.pumpPsi ? Number(model.firePumpTestInput.firePump.pumpPsi) : null,
            TestDate: model.firePumpTestInput?.testDate ? model.firePumpTestInput.testDate : null,
            Comments: model.firePumpTestInput?.comments ? model.firePumpTestInput.comments : null,
            PumpMake: model.firePumpTestInput?.pumpMake ? model.firePumpTestInput.pumpMake : null,
            PumpType: model.firePumpTestInput?.pumpType ? model.firePumpTestInput.pumpType : null,
            PumpPower: model.firePumpTestInput?.pumpPower ? model.firePumpTestInput.pumpPower : null,
            PumpSupvervision: model.firePumpTestInput?.pumpSupvervision === true ? 'Yes' : model.firePumpTestInput?.pumpSupvervision === false ? 'No' : null,
            PumpController: model.firePumpTestInput?.pumpController ? model.firePumpTestInput.pumpController : null,
            PumpListed: model.firePumpTestInput?.pumpListed === true ? 'Yes' : model.firePumpTestInput?.pumpListed === false ? 'No' : null,
            PumpOtherInfo: model.firePumpTestInput?.pumpOtherInfo ? model.firePumpTestInput.pumpOtherInfo : null,
            FirePumpTestResults: firePumpTestResultsData
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }

    static setFirepumpTestInput(firePumpTestData) {
        return {
            firePump: {
                pumpRatingGpm: firePumpTestData.PumpRatingGpm,
                pumpPsi: firePumpTestData.PumpPsi,
            },
            testDate: firePumpTestData.TestDate,
            comments: firePumpTestData.Comments,
            pumpMake: firePumpTestData.PumpMake,
            pumpType: firePumpTestData.PumpType,
            pumpPower: firePumpTestData.PumpPower,
            pumpSupvervision: firePumpTestData.PumpSupvervision === 'Yes' ? true : firePumpTestData.PumpSupvervision === 'No' ? false : null,
            pumpController: firePumpTestData.PumpController,
            pumpListed: firePumpTestData.PumpListed === 'Yes' ? true : firePumpTestData.PumpListed === 'No' ? false : null,
            pumpOtherInfo: firePumpTestData.PumpOtherInfo,
        };
    }


}
class FirepumpTestInput {
    firePump: {
        pumpRatingGpm?: number;
        pumpPsi?: number;
    };
    testDate: Date;
    comments?: string;
    pumpMake?: string;
    pumpType?: string;
    pumpPower?: string;
    pumpSupvervision?: boolean; // It is a string and expected values yes - no for local access making this filed as boolean
    pumpController?: string;
    pumpListed?: boolean; // It is a string and expected values yes - no for local access making this filed as boolean
    pumpOtherInfo?: string;
}

