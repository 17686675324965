import { Inject, Injectable, Injector } from '@angular/core';
import { DataStoreSnapshot } from '@aws-amplify/datastore';
import { DataStore } from 'aws-amplify';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrderCollectionModel } from '../../models/order/order-collection.model';
import { OrderDataBillingModel } from '../../models/order/order-data-billing.model';
import { OrderModel } from '../../models/order/order.model';
import { Group, SurveyOrder } from '../../../models/index';
import { OrderDataBillingServiceModel } from 'src/app/models/order/order-data-billing-service.model';
import { AmplifySaveService } from '../amplify/amplify.save';
import { S3SyncService } from '../s3-sync-service/s3-sync.service';
import { OrderAssociatedRiskModel } from 'src/app/models/order/order-associated-risk.model';
import { HttpClient } from '@angular/common/http';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { ENV, Environment } from 'src/app/interfaces/env.interface';
import { environment } from 'src/environments/environment';
import { Constants, FormType } from '../util-service/constants';
import { LockOrUnlockEnum } from 'src/app/enums/lock-order.enum';
import { OrderAssociatedUWFormsModel } from 'src/app/models/order/order-associated-uw-forms.model';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { UtilService } from '../util-service/util.service';
import { AmplifyService } from '../amplify/amplify.service';
import { addToGroupService } from '../add-to-groups/add.to.groups.service';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { QueueTypes, SyncStatus } from 'src/app/models/risk-report/queue-service.model';
import { QueueService } from '../queue/queue.service';
import { LoadingService } from '../loading-service/loading.service';
import { AuthService as VeriskAuth } from '../../services/auth-service/auth.service';
import { FridService } from '../frid-service/frid.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  public selectedOrderNo;
  public selectedOrderFromS3;
  public fabButtonSubject = new BehaviorSubject<boolean>(true);
  public lockOrUnlockButtonSubject = new BehaviorSubject<any>({ selectedorderId: '' });
  fabVisible = this.fabButtonSubject.asObservable();
  public fileSavedToLocal = new BehaviorSubject<any>(null);
  public fileSavedToLocal$ = this.fileSavedToLocal.asObservable();
  public lockOrUnlockInProgress = false;
  private dataType = 'Order';
  public selectedRiskData: any;
  public selectMode: boolean = false;
  public riskAddedOrDeleted: boolean = false;
  public sequenceChanged: boolean = false;
  public formType: string;
  public isCustomerServiceUser: boolean = false;
  public titleChange = new BehaviorSubject<any>(null);
  public titleChange$ = this.titleChange.asObservable();
  private queueService;
  public selectedRiskStatus: string = '';
  constructor(
    private amplifySaveService: AmplifySaveService,
    private httpService: AuthHttpService,
    @Inject(ENV) private environment: Environment,
    public s3Sync: S3SyncService,
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private utilService: UtilService,
    private amplify: AmplifyService,
    public groupService: addToGroupService,
    private injector: Injector,
    private loadingService: LoadingService,
    private veriskAuth: VeriskAuth,
    private fridService: FridService
  ) {}

  setBgClass(color) {
    switch (color) {
      case '':
        return 'new-bg';
      case 'Blue':
        return 'new-bg';
      case 'Teal':
        return 'progress-bg';
      case 'Green':
        return 'done-bg';
      case 'Red':
        return 'error-bg';
      case 'DarkRed':
        return 'deleted-bg';
    }
  }

  setBorderClass(color) {
    switch (color) {
      case '':
        return 'new-border';
      case 'Blue':
        return 'new-border';
      case 'Teal':
        return 'progress-border';
      case 'Green':
        return 'done-border';
      case 'Red':
        return 'error-border';
      case 'DarkRed':
        return 'error-border';
      case 'Grey':
        return 'existing-border';
    }
  }

  setIndicatorIcon(color) {
    switch (color) {
      case 'Teal':
        return 'assets/icon/inprogress.svg';
      case 'Green':
        return 'assets/icon/done.svg';
      case 'Grey':
        return 'assets/icon/add-existing.svg';
    }
  }

  async getAssociatedRisk(risks, orderNo): Promise<any> {
    return new Promise((resolve, reject) => {
      let riskAssociatedOrder: Array<OrderAssociatedRiskModel> = [];
      if (risks.length > 0) {
        for (let risk of risks) {
          this.amplify.getRiskReportFromS3(orderNo,risk.ReportId).then(
            async (data) => {
              let riskData = await data;
              if (riskData.RiskUIStatus === 1) {
                riskData.RiskReport['RiskUIStatus'] = Constants.In_Progress_Status;
              } else if (riskData.RiskUIStatus === 2) {
                riskData.RiskReport['RiskUIStatus'] = Constants.Validation_Issues_Status;
              } else if (riskData.RiskUIStatus === 3) {
                riskData.RiskReport['RiskUIStatus'] = Constants.Completed_Status;
              } else {
                riskData.RiskReport['RiskUIStatus'] =
                  risk && risk.RiskUIStatus ? risk.RiskUIStatus : Constants.New_Status;
              }
              riskAssociatedOrder.push({
                BuildingDescription: riskData.RiskReport.BuildingDescription,
                ReportAddresses: riskData.RiskReport.ReportAddresses[0],
                RiskId: riskData.RiskReport.RiskId,
                RiskUIStatus: riskData.RiskReport.RiskUIStatus,
                Count: riskData.RiskReport.Count,
                IsCloneRiskShown: false,
                DeleteReasonCode: '',
                OtherComments: '',
                ReportIdentifier:riskData.RiskReport.ReportIdentifier,
                SendToCustomer: risk.SendToCustomer,
              });

              resolve(riskAssociatedOrder);
            },
            (error) => {
              if (error.message == 'NotFound') {
                let riskid = risk.RiskId ?? '';
                this.utilService.showAlert('', '', 'Risk ' + riskid + ' not found in s3!');
              }
              reject(error);
            }
          );
        }
      } else {
        resolve(risks);
      }
    });
  }

  getAssociatedPackForms(services) {
    let uwForms: Array<OrderAssociatedUWFormsModel> = [];
    for (let service of services) {
      if (service.Service && service.Service.PackForms?.length > 0) {
        for (let packform of service.Service.PackForms) {
          const obj = this.utilService.splitDescription(packform.Description);
          uwForms.push({
            ServiceCode: obj.code,
            FormName: obj.name,
            Quantity: service.Quantity,
            Code: service.Service.Code,
            Id: service.Service.Id,
            Packform: packform,
            IsUIAdded: service.Service.IsUIAdded,
            Index: 0,
            FormType: FormType.PackForm,
            ExternalLink: '',
          });
        }
      } else {
        if (this.isServiceExists(service.Service.Code) && service.Quantity > 0) {
          uwForms.push({
            ServiceCode: service.Service.Code,
            FormName: service.Service.Description,
            Quantity: service.Quantity,
            Code: service.Service.Code,
            Id: service.Service.Id,
            Packform: null,
            IsUIAdded: service.Service.IsUIAdded,
            Index: 0,
            FormType: this.formType,
            ExternalLink: '',
          });
        }
      }
    }
    return uwForms;
  }

  isServiceExists(serviceCode) {
    switch (true) {
      case this.isValidServiceCode(Constants.threeSixtyService, serviceCode):
        this.formType = FormType.ThreeSixtyValuation;
        return true;
      case this.isValidServiceCode(Constants.coreLogicServiceCodes, serviceCode):
        this.formType = FormType.CoreLogicValuation;
        return true;
      default:
        return false;
    }
  }


  // To query for a single order, pass in the ID of the order as the second argument to the query.
  get(id: string): Observable<SurveyOrder> {
    return from(DataStore.query(SurveyOrder, id));
  }

  /* get the order which is saved in amplify by order id */
  getbyOrderId(orderId: number): Promise<SurveyOrder[]> {
     return  DataStore.query(SurveyOrder, (c) => c.orderId.eq(orderId));
  }

 async syncAllUpdates(){
   

     //Verify the status of the amplify token.
     if (await this.veriskAuth.hasTokenExpired()) {
      await this.veriskAuth.updateTokens();
      await this.amplify.handleAmplifyStorageTokenRefresh();
    }

    await DataStore.query(SurveyOrder).then(async orders => {
      
     for(const order of orders){
      if (order.data['uiStatus'] === 'PendingSync' && order.status != 'InPinpoint') {
        await this.syncPendingModel(order);
      }

      if(Constants.inValidStatus.find((status) =>  status=== order.status.toLowerCase())){
        console.log('Deleting Completed Orders ');
        this.deleteOrder(order.id);
        //TODO: This is an issue , we should delete the folder but current code tries to delete file which causes issue. 
        //This has to be fix , causes lot of console errors and User's local storage is not getting deleted if order is completed/cancelled/reassigned. 
        //this.s3Sync.deleteLocalAsset(`${'Order'}/${order.orderNo}`); 
         
        await this.groupService.deletegroup(order.id);
      }
     }
    });

  }

  // Convert DataStore observeQuery ZenObservable to Rxjs Observable
  observeCollection(): Observable<OrderCollectionModel> {
    const o = new Observable((subscriber) => {
      DataStore.observeQuery(SurveyOrder).subscribe(subscriber);
    }) as Observable<DataStoreSnapshot<SurveyOrder>>;
    return o.pipe(
      switchMap((data) => {
        //Some users were able to see all user data. We reached out to the Amplify team but couldn't find a solution, 
        //so we implemented a workaround: if another user's record is found in the Amplify database, we reinitialize the Amplify DB.
        return this.fridService.getToken().then(async (loggedInUserFrID) => {
          const hasDifferentFrID = data.items.some((survyOrder) => survyOrder.frID !== loggedInUserFrID);
          if (hasDifferentFrID) {
            // Reset DataStore if a different frID is found
            await this.amplify.DataStoreInit(loggedInUserFrID);
            // After initialization, re-query DataStore
            return new Promise<DataStoreSnapshot<SurveyOrder>>((resolve) =>
              DataStore.observeQuery(SurveyOrder).subscribe(resolve)
            );
          }
          return Promise.resolve(data); // No reset needed, proceed with current data
        });
      }),
      map((data) => {
        let orderModel = [];
        let apiResponse = data.items.map(async (survyOrder) => {

         if(!Constants.inValidStatus.find((status) =>  status=== survyOrder.status.toLowerCase()) ) {
          orderModel.push({
            AppointmentSet: survyOrder.apptDate,
            ValidatedCity: survyOrder.city,
            CompanyDetails: {
              CompanyName: survyOrder.companyName,
            },
            ValidatedCounty: survyOrder.county,
            CreatedAt: survyOrder.createdAt,
            DueDate: survyOrder.dueDate,
            FrID: survyOrder.frID,
            GroupName: survyOrder.grpName,
            OrderAmplifyId: survyOrder.id,
            InsuredNameDba: survyOrder.insuredName,
            IsAccelerated: survyOrder.isAccl,
            PropertyLocationPoint: {
              Latitude: survyOrder.data['propertyLocationPoint']?.latitude,
              Longitude: survyOrder.data['propertyLocationPoint']?.longitude,
            },
            OrderId: survyOrder.orderId,
            OrderIdFullOrderNumber: survyOrder.orderNo,
            ValidatedStateAbbrev: survyOrder.state,
            Status: survyOrder.status,
            ValidatedAddress1: survyOrder.streetAddr,
            UpdatedAt: survyOrder.updatedAt,
            ValidatedPostalCodeFive: survyOrder.zip5,
            ApptoTime: survyOrder.data['appToTime'],
            ReportSymbol: survyOrder.data['reportSymbols'],
            isSelected: false,
          });
        }
        }
        );
        return new OrderCollectionModel(orderModel as any);
      })
    ) as Observable<OrderCollectionModel>;
  }

  async deleteOrder(amplifyId: string) {
    const todelete = await DataStore.query(SurveyOrder, amplifyId);
    DataStore.delete(todelete);
  }

  /**
   * Saving updated order data to amplify
   * @param {SurveyOrder, OrderDataBillingModel}
   * @return {void}.
   */
  updateBilling(order: OrderModel, billingModal: OrderDataBillingModel) {
    let filePath = `${'Order'}/${order.OrderIdFullOrderNumber}/${'order.json'}`;
    this.localStorageService.saveOrderJSONToLocal(order).then((orderData) => {
      console.info(orderData);
    });
  }

  dataSave(selectedOrder: OrderModel, updatedSeviceBillings: OrderDataBillingServiceModel[]) {
    this.get(selectedOrder.OrderAmplifyId).subscribe((selectedOrder) => {
      const addSevices = SurveyOrder.copyOf(selectedOrder, (updated) => {});
    });
  }

  public _compareAsc(o1: any, o2: any): number {
    const leftOrderValue: any = o1.toLowerCase();
    const rightOrderValue: any = o2.toLowerCase();

    if (leftOrderValue > rightOrderValue) {
      return 1;
    }

    if (leftOrderValue < rightOrderValue) {
      return -1;
    }

    return 0;
  }

  setFabVisibility(isVisible: boolean): void {
    this.fabButtonSubject.next(isVisible);
  }

  getDummyFullRiskData() {
    return this.http.get('./assets/json/risk/new-full-risk-report.json');
  }

  lockOrder(selectedOrderId, lockOrUnLockAPI): Promise<any> {
    return this.httpService.request(
      'POST',
      environment.iSurveyAPIurl + '/orders/' + lockOrUnLockAPI + '?orderId=' + selectedOrderId
    );
  }

  getLc360Link(lc360RequestInput): Promise<any> {
    return this.httpService.request('POST', environment.iSurveyAPIurl + Constants.getL360Link, lc360RequestInput);
  }

  getCoreLogicValuationLink(): Promise<any> {
    return this.httpService.request('POST', environment.iSurveyAPIurl + Constants.getCoreLogicValuationLink);
  }

  getFederatedCoreLogicValuationLink(orderId): Promise<any> {
    return this.httpService.request('POST', environment.iSurveyAPIurl + Constants.getFederatedCoreLogicValuationLink + '?orderId=' + orderId);
  }

  async lockOrderInAmplify(selectedOrderId, lockOrUnLocktext) {
    let orderInAmplify = await this.getbyOrderId(selectedOrderId);
    const lockOrder = SurveyOrder.copyOf(orderInAmplify[0], (updated) => {
      const orderModel = updated as any;
      orderModel.data['Lock'] = lockOrUnLocktext === 'lock' ? true : false;
      orderModel.status = lockOrUnLocktext === 'lock' ? LockOrUnlockEnum.INPINPOINT : LockOrUnlockEnum.INPROGRESS;
    });
    await this.amplifySaveService.saveData(lockOrder);
    this.lockOrUnlockButtonSubject.next({ selectedOrderId: selectedOrderId });
  }

  async updateOrderStatusInAmplify(selectedOrderId, status){
    let orderInAmplify = await this.getbyOrderId(selectedOrderId);
    const failedOrder = SurveyOrder.copyOf(orderInAmplify[0], (updated) => {
      const orderModel = updated as any;
      orderModel.status = status;
    });
    await this.amplifySaveService.saveData(failedOrder);
  }

  navigateToRiskForm(risk, selectedOrder, orderNo) {
    let reportIdentifier = risk.Id || risk.ReportIdentifier;
    const navigationExtras: NavigationExtras = {
      state: {
        orderRisk: true,
        order: selectedOrder,
        risk,
        filePath:
          'Order/' +
          orderNo +
          '/riskreport/' +
          reportIdentifier +
          '/' +
          reportIdentifier +
          '.json',
      },
    };

    this.router.navigate(['/my-orders/order-details/forms/survey'], navigationExtras);
  }

  async getAllRisk(riskList, orderNo) {
    return new Promise(async (resolve) => {
      let allOrderRisks = [];
      for (let risk of riskList) {
        await this.amplify.getRiskReportFromS3(orderNo,risk.ReportId).then((data: any) => {
          let report = data;
          allOrderRisks.push(report);
        });
      }
      resolve(allOrderRisks);
    });
  }

  public setGroupName(): void {
    this.groupService.getGroups().then((groups: Group[]) => {
      let groupName = this.groupService.getGroupName(groups, this.selectedOrderFromS3.OrderAmplifyId);
      let name: string;
      if (this.selectedOrderFromS3.OrderIdFullOrderNumber && groupName) {
        name = groupName + ' - ' + this.selectedOrderFromS3.OrderIdFullOrderNumber;
      } else {
        name = this.selectedOrderFromS3.OrderIdFullOrderNumber;
      }
      this.titleChange.next(name);
    });
  }
  //To avoid circular dependancy added this method here
   isValidServiceCode(validServiceCodes: any, serviceCode: any): boolean {
    return validServiceCodes.includes(serviceCode);
  }

  async syncPendingModel(survyOrder){
    try{
      console.log("Syncing Order" + survyOrder.orderNo);
      let localFilePath = `${'Order'}/${survyOrder.orderNo}` ;
      await this.localStorageService.checkDirExist(localFilePath).then(async (folderExist) => {
          if(folderExist) //try to delete a folder only if it exists to avoid errors.
            await this.s3Sync.clearLocalDataDirectory(localFilePath);
       });
      await this.amplify.syncSurveyOrder(survyOrder.orderNo).then(() => {
         const updatedModel = SurveyOrder.copyOf(survyOrder, (updated) => {
           const orderModel = updated as any;
           orderModel.data['uiStatus'] = 'SyncComplete';
         });
         this.amplifySaveService.saveData(updatedModel);
         // Return a resolved Promise if everything is successful
         return Promise.resolve("Sync completed successfully");
       });
    } catch (error) {
      console.log(error);  
      return Promise.resolve(true);
   }
  }

  validateOrCompleteOrder(orderNumber, validateOrCompleteAPI): Promise<any> {
    return this.httpService.request(
      'POST',
      environment.iSurveyAPIurl + validateOrCompleteAPI + '?orderNumber=' + orderNumber
    );
  }

  reworkOrder(orderId){
    return this.httpService.request(
      'POST',
      environment.iSurveyAPIurl + Constants.fieldRework + '?orderId=' + orderId
    );
  }

  processOrder(orderId){
    return this.httpService.request(
      'POST',
      environment.iSurveyAPIurl + Constants.processOrder + '?orderId=' + orderId
    );
  }

  async updateOrderFailedStatus(survyOrder) {
    console.log('Failed status errors',survyOrder.data.errors);
    // Fetch and parse latest order data
    this.selectedOrderFromS3 = JSON.parse(await this.amplify.getOrderData(survyOrder.orderNo) as string);
    if (survyOrder.data.errors) {
      this.selectedOrderFromS3.Status = 'Failed';
      this.selectedOrderFromS3.ErrorCodes = [];
      survyOrder.data.errors.forEach((errorObj) => {
        this.selectedOrderFromS3.ErrorCodes.push(errorObj.code);
      });
      await this.localStorageService.saveOrderJSONToLocal(this.selectedOrderFromS3);
    }
  }

  async lockSelectedOrder(order):Promise<boolean>{
    return new Promise(async (resolve, reject) => {
     try{
      if (await this.veriskAuth.hasTokenExpired()) {
        await this.veriskAuth.updateTokens();
        await this.amplify.handleAmplifyStorageTokenRefresh();
      }

      if(order.Stage==Constants.stageField && order.Status==Constants.reWorkStatus){
        return resolve(true);
      }

     this.get(order.OrderAmplifyId).subscribe(async (selectedOrder) => {
       let notLockFlag: boolean = false;
      const amplifyOrder = SurveyOrder.copyOf(selectedOrder, (updated) => {
         const orderModel = updated as any;
         notLockFlag = !orderModel?.data['Lock'] && !this.isCustomerServiceUser && !Constants.inValidLockingStatus.includes(orderModel.status.toLowerCase());
         notLockFlag ? orderModel.status = LockOrUnlockEnum.INPINPOINT : orderModel.status = orderModel.status;
         orderModel.data['Lock'] = notLockFlag ? true : false;
         order.IsLocked = orderModel.data['Lock'];
       });
       if(notLockFlag && !this.amplify.isNetWorkConnection)
       {
         order.IsLocked=false;
         this.loadingService.dismissLoading();
         this.utilService.showAlert('', '', Constants.unlockedAndOfflineErrorMsg)
         resolve(false);
       }
       else if (notLockFlag) {    
  
         this.lockOrder(selectedOrder.orderId, 'lock').then(async () => {
           await this.amplifySaveService.saveData(amplifyOrder);
           this.lockOrUnlockButtonSubject.next({ selectedOrderId: selectedOrder.orderId });
           return resolve(true);
         });
       }else{
         return resolve(true);
       }
     });  
   } catch{
     return reject(false);
   }
   });
   }

  async orderAndriskStatusChangeSync(Order): Promise<any>{
    this.queueService = this.injector.get(QueueService);
    
    return new Promise(async (resolve) => {
      // if(Order.RiskList.length>0){
      // await this.riskReportsStatusUpdate(Order);
      // }
      await this.localStorageService.saveOrderJSONToLocal(Order);
      this.queueService.onFileStatusChange(false,ConfigModel.Order,Order.OrderIdFullOrderNumber,null,null,null,true,SyncStatus.NotSynced,null);
    let syncQueue = [{
      type: QueueTypes.S3Json,
      model: ConfigModel.Order,
      identifier: Order.OrderIdFullOrderNumber,
      required: true,
    },{
      type: QueueTypes.RiskReport,
      model: ConfigModel.Order,
      identifier: Order.OrderIdFullOrderNumber,
      required: true,
    }];
    this.queueService.startQueueProcess(syncQueue).subscribe(
      (_) => {
        resolve(true);
      });
    });

  }

  // steps followed to update risk report 
  // In order to avoid more memory usage we choosen to foreach instead of promise.all
  /*
  1. opening risk report
  2. updating risk report status
  3. saving to local
  4. if all risk reports are updated then resolving the promise to go ahead for s3 sync
  */
  private riskReportsStatusUpdate(Order){
    return new Promise((resolve) => {
    Order.RiskList.forEach(async (risk, index) => {
      let folderPath = `${ConfigModel.Order}/${Order.OrderIdFullOrderNumber}/${AssetType.RiskReport}/${risk.ReportId}/${risk.ReportId}.json`;
       let riskReport = await this.localStorageService.readLocalFile(folderPath)
        let parsedRiskReport: any = JSON.parse(riskReport)
        parsedRiskReport.RiskReport.Status = Constants.completeStatus;
        await this.localStorageService.saveFormOrRiskReportToLocal(parsedRiskReport,ConfigModel.Order,Order.OrderIdFullOrderNumber,AssetType.RiskReport,parsedRiskReport.RiskReport.ReportIdentifier,false);
        index + 1 === Order.RiskList.length ? resolve(true):'';
    });
  });
  }

  public async saveOrderJson(order){
    await this.localStorageService.saveOrderJSONToLocal(order);
  }

  refreshComments(orderId): Promise<any> {
    return this.httpService.request('GET', this.environment.iSurveyAPIurl + Constants.refreshComments + '?orderId=' + orderId);
  }
  
  async deleteOrderAndGroup(order) {
    await this.deleteOrder(order.id);
    //await this.s3Sync.deleteLocalAsset(`Order/${order.orderNo}`);
    /*let localFilePath = `${'Order'}/${order.orderNo}` ;
      await this.localStorageService.checkDirExist(localFilePath).then(async (folderExist) => {
          if(folderExist)
            await this.s3Sync.clearLocalDataDirectory(localFilePath);
       });
       */
    await this.groupService.deletegroup(order.id);
  }
  
}
