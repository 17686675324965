import { Component, TrackByFunction } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from './app.config';
import { Location } from '@angular/common';
import { AppService } from './services/app-service/app.service';
import { Constants } from './services/util-service/constants';
import { Tab } from './interfaces/tabs';
import { Platform, NavController, ModalController } from '@ionic/angular';
import { AuthActions, AuthService, IAuthAction } from 'ionic-appauth';
import { AuthService as VeriskAuth } from './services/auth-service/auth.service';
import { environment } from 'src/environments/environment';
import { debounceTime } from 'rxjs/operators';
import { LoadingService } from './services/loading-service/loading.service';
import { StreetViewService } from './services/google-maps-services/street-view.service';
import { Network } from '@ionic-native/network/ngx';
import { AmplifyService } from './services/amplify/amplify.service';
import { StreetViewComponent } from './components/streetview/street-view.component';
import { FridService } from './services/frid-service/frid.service';
import { App } from '@capacitor/app';
import { ENV, Environment } from 'src/app/interfaces/env.interface';
import { Inject } from '@angular/core';
import { Analytics, AWSKinesisProvider } from 'aws-amplify';
import aws_exports from '../aws-exports';
import { UtilService } from './services/util-service/util.service';
import { FormlyErrorService } from './services/formly-builder/formly-error.service';
import { QueueService } from './services/queue/queue.service';
import { LocalNotificationsService } from './services/local-notifications/local-notifications.service';
import { AxiosInterceptorService } from './auth/axios-interceptor/axios-interceptor.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public showTab = true;
  public appTabs: Array<Tab> = Constants.appTabs;
  selectedItemLabel = 'Home';
  isStreetViewVisible: boolean;

  constructor(
    @Inject(ENV) private environment: Environment,
    public config: AppConfig,
    private router: Router,
    public location: Location,
    private network: Network,
    public appService: AppService,
    private auth: AuthService,
    private amplify: AmplifyService,
    private platform: Platform,
    private loadingService: LoadingService,
    private streetViewService: StreetViewService,
    private navCtrl: NavController,
    private fridService: FridService,
    public modalController: ModalController,
    private veriskAuth: VeriskAuth,
    private utilService: UtilService,
    private errorService: FormlyErrorService,
    private queueService: QueueService,
    private localNotificationsService: LocalNotificationsService,
    private axiosInterceptorService: AxiosInterceptorService, // Do not delete,The interceptor service is initialized here
  ) {
    this.setAmplifyAnalyticsConfiguration();
    this.initializeApp();
  }
  setAmplifyAnalyticsConfiguration() {
    Analytics.addPluggable(new AWSKinesisProvider());
    Analytics.configure({
      AWSKinesis: {
        region: aws_exports.aws_project_region,
      },
    });

    Analytics.autoTrack('session', {
      enable: true,
      attributes: { type: 'SPA', streamName: this.environment.analyticStreamName },
    });
    Analytics.autoTrack('pageView', {
      enable: true,
      attributes: { type: 'SPA', streamName: this.environment.analyticStreamName },
    });
    let analyticsLog = { name: 'analytics intializing' };
    this.utilService.addAnalyticsLog(analyticsLog);

    Analytics.enable();
  }

  initializeApp() {
    this.localNotificationsService.askNotificationPermission();
    this.platform.ready().then(async () => {
      await this.auth.revokeTokens();
      await this.auth.init();
      this.appService.getSignOutObservable().subscribe(() => {
        this.signOut();
      });
      this.handleNetwork();
    });
    if (this.platform.is('capacitor')) {
      App.addListener('appStateChange', async (state) => {
        if (state.isActive) {
          if (this.network.type !== 'none') {
            console.log('app came from back ground to fore ground');
            await this.veriskAuth.hasTokenExpired();
            await this.veriskAuth.updateTokens();
            this.queueService.trySyncing();
          }
        } else {
          this.queueService.initialSyncDone = false;
        }
      });
    } else {
      this.queueService.trySyncing();
    }
  }

  private handleNetwork() {
    this.network.onConnect().subscribe(() => {
      this.amplify.setInternetConnection(true);
    });

    this.network.onDisconnect().subscribe(() => {
      this.amplify.setInternetConnection(false);
    });
    //Upon app initialization, verify if the user is offline.
    if (this.network.type == 'none') {
      this.amplify.setInternetConnection(false);
    }
  }
  public async menuItem(event, tab) {
    this.errorService.closePreviousToaster();
    if (this.amplify.dbDeleted) {
      let frId: string = await this.fridService.getToken();
      this.amplify.DataStoreInit(frId);
      this.amplify.dbDeleted = false;
    }
    this.selectedItemLabel = tab.label;
  }
  ngOnInit() {
    // Tabs won't show in my order pages
    this.router.events.subscribe((val) => {
      this.appService.showTabs(this.location.path().indexOf('/my-orders/') === -1);
    });

    this.appService.getHiddenTabsSubject().subscribe((res) => {
      this.showTab = res;
    });

    this.streetViewService.currentSV.subscribe(async (msg) => {
      this.isStreetViewVisible = msg;
      if (this.isStreetViewVisible) {
        this.openModal();
      } else {
        const topOverlay = await this.modalController.getTop();
        if (topOverlay) {
          await this.modalController.dismiss();
        }
      }
    });
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: StreetViewComponent,
      breakpoints: [1],
      initialBreakpoint: 1,
      cssClass: 'modal-fullscreen',
    });
    modal.present();
  }

  public async signOut() {
    try {
      await this.loadingService.showLoading('Signing out...');
      await this.auth.signOut();
      if (this.platform.is('capacitor')) {
        const sub = this.auth.events$.pipe(debounceTime(500)).subscribe(async (action: IAuthAction) => {
          if (action.action === AuthActions.SignOutSuccess) {
            this.navCtrl.navigateRoot('logout');
          }
          if (action.action === AuthActions.SignOutSuccess || action.action === AuthActions.SignOutFailed) {
            this.loadingService.dismissLoading();
            sub.unsubscribe();
          }
        });
      }
    } catch (error) {
      this.loadingService.dismissLoading();
      console.log('signout error-----', error);
    }
  }

  private signIn() {
    const { audience } = environment;
    this.auth.signIn({ audience }).catch((error) => console.error(`Sign in error: ${error}`));
  }

  /*trackBy using this func */
  public tabUrl: TrackByFunction<Tab> = (index, tab: Tab) => tab.url;
}
