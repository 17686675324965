import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class BuildingHazardsSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'buildingHazards',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Building Hazards',
        isCollapsable: true,
        isCollapsed: false,
      },
      fieldGroup: [
        {
          key: 'buildingHazardArray',
          type: 'occupant-building-hazards',
          fieldArray: {
            className: 'occupant-hazards-fields',
            wrappers:['error-info-wrapper'],
            fieldGroup: [
              {
                type: 'search-bar',
                key: 'scheduleNumber',
                props: {
                  label: 'Hazard Schedule #',
                  maxlength: 10,
                  minlength: 10,
                  type: 'number',
                  inputmode: 'tel',
                  jsonPath: 'hazard-codes',
                  initialValue: "7",
                  required:true,
                },
                hooks:{
                  onInit:(field: FormlyFieldConfig)=>{
                        field?.formControl?.valueChanges?.subscribe((value)=>{
                          field.formControl.markAllAsTouched();
                        });
                  }
                },
                validators: {
                  scheduleNumberLengthCheck: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const schedNo = control.value;
                      const description = field.form.get('description');
                      if (schedNo && schedNo.length === 10) {
                        try {
                          const scheduleService = this.formlyBuilderService.scheduleService;
                          const desc = scheduleService.getHazardScheduleDescription(schedNo);
                          const type = desc?.occupantDescription;
                          if (!type || type === '') {
                            description.setValue('');
                            return false;
                          } else {
                            setTimeout(() => {
                              description.setValue(type);
                            }, 100);
                          }
                        } catch (error) {
                          console.error('validScheduleNo ' + ' #' + schedNo + ' ' + error);
                          return false;
                        }
                        return true;
                      } else if (schedNo && schedNo.length < 10) {
                        description.setValue('');
                        return false;
                      } else {
                        return true;
                      }
                    },
                    message: 'Invalid schedule number',
                  },
                  cookingHazardsScheduleNo: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const scheduleNo = control.value ? control.value : '';
                      if (scheduleNo !== '' && scheduleNo.startsWith('734')) {
                        return false;
                      } else {
                        return true;
                      }
                    },
                    message: 'Cooking hazards cannot be applied at the building level',
                  },
                },
              },
              {
                key: 'description',
                type: 'simple-input',
                className: 'building-hazards-description-field',
                props: {
                  label: 'Description',
                  readonly: true,
                },
              },
              {
                key: 'comment',
                type: 'simple-input',
                className: 'building-hazards-comment-field',
                props: {
                  label: 'Comments',
                  required: true,
                  disabled: false,
                  maxLength: 650,
                },
              },
              {
                key: 'reportIdentifier',
              },
              {
                key: 'reportHazardIdentifier',
              },
            ],
          },
          validators: {
            checkduplicateScheduleNo: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const formValues = control?.value;
                // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                if (formValues.length > 1) {
                  //remove undefined
                  const scheduleNumbers = formValues?.filter((item) => {
                    if (item.scheduleNumber) {
                      return item;
                    }
                  });

                  //get all schedule numbers
                  const allScheduleNumber = scheduleNumbers.map((item) => item.scheduleNumber);

                  //remove duplicate
                  const uniqueScheduleNumber = new Set(allScheduleNumber);
                  const isDuplicate = uniqueScheduleNumber.size !== allScheduleNumber.length;
                  return !isDuplicate;
                } else {
                  return true;
                }
              },
              message: 'Hazards cannot be duplicated within Building Hazards',
            },
          },
        },
      ],
      hooks: {
        onInit: (field?: FormlyFieldConfig) =>{
          field?.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
          debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
                // if (field.formControl.valid) {
                //updating model to removing undefined item and schedule numbers
                // field.model.buildingHazardArray = field.model.buildingHazardArray.filter((item)=>
                // { if(item && 'scheduleNumber' in item && item.scheduleNumber && item.scheduleNumber !== '') {return item;}});
                const formlyService = field.options.formState.service;
                field.options.formState.service.statusLineVisibility = false;
                formlyService.riskReport.updateSectionData(SectionEnum.FR_OCCUPANTS_BUILDING_HAZARDS, false);
                // }
              }
            });
          },
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
      },
    });
  }
}
