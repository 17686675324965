import { Inject, Injectable } from '@angular/core';
import { AlertController, AlertOptions, IonContent, Platform } from '@ionic/angular';
import { Constants, RequestConsoleLogs } from './constants';
import { LoggerService } from './log-service';
import { DebugConsoleLog } from '../../interfaces/debug-console';
import { Analytics } from 'aws-amplify';
import { ENV, Environment } from 'src/app/interfaces/env.interface';
import { LogTimeService } from './log-time.service';
import { FridService } from '../frid-service/frid.service';
import { VeriskLog } from './verisk-log';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface AlertCustomOptions {
  duration?: number;
  noButtons?: boolean;
  cssClass?: string;
}

@Injectable({ providedIn: 'root' })
export class UtilService {
  isCurrentLocationClicked: boolean = false;
  isZoomInOut: boolean = false;
  isValidateComplete: boolean = false;
  isFromInprogress: boolean = false;
  isSpellCheck: boolean = true;
  isAutoComplete: string = "off";
  isAutoCorrect: string = "off";

  constructor(
    private alertCtrl: AlertController,
    private loggerService: LoggerService,
    private logTimeService: LogTimeService,
    private fridService: FridService,
    @Inject(ENV) private environment: Environment,
    private platform: Platform
  ) {}

  static logger(key?: string, value?: string) {
    console.log(key, value);
  }

  /**
   * Shows alert
   * @param title
   * @param subTitle
   * @param message
   * @param [callback]
   */
  async showAlert(title, subTitle, message, callback?: any, otherParams?: AlertCustomOptions) {
    const alertOptions: AlertOptions = {
      header: title,
      subHeader: subTitle,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          role: 'confirm',
          handler: () => {
            if (callback) {
              callback(1);
            }
          },
        },
      ],
    };

    if (otherParams) {
      alertOptions.cssClass = otherParams.cssClass;
      if (otherParams.noButtons) {
        delete alertOptions.buttons;
      }
    }
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
    if (otherParams && otherParams.duration) {
      setTimeout(async () => {
        await alert.dismiss();
      }, otherParams.duration);
    }
    await alert.onWillDismiss();
  }

  /**
   * Shows confirmation popup
   * @param title
   * @param msg
   * @param callback
   * @param [buttonText]
   */
  async showConfirmationPopup(title, msg, callback, buttonText: any = ['Yes', 'No']) {
    const alert = await this.alertCtrl.create({
      header: title,
      cssClass: 'confirmation-popup',
      message: msg,
      backdropDismiss: false,
      buttons: [
        {
          text: buttonText[1],
          role: 'cancel',
          handler: () => {
            callback(0);
          },
        },
        {
          text: buttonText[0],
          role: 'confirm',
          handler: () => {
            callback(1);
          },
        },
      ],
    });

    await alert.present();
  }

  async showAlertWithConfirmation(title, msg, callback, buttonText: any = ['Okay']) {
    const alert = await this.alertCtrl.create({
      id: 'showAlertWithConf',
      header: title,
      cssClass: 'confirmation-popup',
      message: msg,
      buttons: [
        {
          text: buttonText[0],
          role: 'confirm',
          handler: () => {
            callback(1);
          },
        },
      ],
      backdropDismiss: false
    });

    await alert.present();
  }

  async showAlertWithHeader(title, callback) {
    const alert = await this.alertCtrl.create({
      header: title,
    });

    await alert.present();
    setTimeout(
      () =>
        alert.dismiss().then(() => {
          callback(1);
        }),
      2000
    );
  }

  async warningAlert(title, msg, callback) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            callback(0);
          },
        },
        {
          text: 'Remove',
          role: 'confirm',
          handler: () => {
            callback(1);
          },
        },
      ],
    });
    await alert.present();
  }

  isTestUser(): boolean {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let userId = userInfo.email;
    if (Constants.testUser.includes(userId)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Logs with timestamp to the Debug Console
   *
   * @param key Area of the App where log was written
   * @param value Information to log
   * @returns false if an error occurred
   */
  async addTextConsoleLog(key, value): Promise<boolean> {
    try {
      if (this.loggerService.isLogEnabled()) {
        const logObj: DebugConsoleLog = new DebugConsoleLog();
        logObj.logTime = this.logTimeService.getTime();
        logObj.reqTime = logObj.logTime;
        //logObj.resTime = logObj.logTime + 1; // Simulate a 1 ms call // commented as we are not showing response time for non http logs
        logObj.reqLogLabel = `${key} - ${value}`;
        RequestConsoleLogs.push(logObj);
      }
    } catch (error) {
      return false;
    }
    return true;
  }

  addAnalyticsLog(log) {
    let currentTime = this.logTimeService.getTime();
    Analytics.record(
      {
        data: { name: log, timeStamp: currentTime },
        partitionKey: '',
        streamName: this.environment.analyticStreamName,
      },
      'AWSKinesis'
    );
  }

  async addLog(log: VeriskLog) {
    try {
      const frid = await this.fridService.getToken();
      log.frid = frid;
    } catch (e) {
      log.frid = null;
    }
    if (log.level === 'ERROR') log.developerConsole = true;
    const an = log.analytics;
    delete log.analytics;
    const dc = log.developerConsole;
    delete log.developerConsole;
    const bc = log.browserConsole;
    delete log.browserConsole;

    if (an) {
      let currentTime = this.logTimeService.getTime();
      Analytics.record(
        {
          data: { name: log, timeStamp: currentTime },
          streamName: this.environment.analyticStreamName,
          partitionKey: '',
        },
        'AWSKinesis'
      );
    }
    if (dc) {
      this.addTextConsoleLog(log.message, JSON.stringify(log));
    }
    if (bc) {
      console[log.level.toLowerCase()](log);
    }
  }
  openInNewWindow(url: string) {
    window.open(url, '_blank');
  }
  parseData(data) {
    if (typeof data === 'string') {
      return JSON.parse(data);
    } else {
      const parsedObject = { ...data };
      for (const key in parsedObject) {
        if (typeof parsedObject[key] === 'string') {
          try {
            parsedObject[key] = JSON.parse(parsedObject[key]);
          } catch (error) {}
        }
      }
      return parsedObject;
    }
  }
  formatDate(date: string) {
    let formattedDate: string = '';
    if (date) {
      if (moment(date.split('T')[0]).isSame(moment('1753-01-01'))) {
        formattedDate = '';
      } else {
        formattedDate = moment(date).format('MM/DD/YY');
      }
    }
    return formattedDate;
  }
  //Format risk id in 'xx-xxxx-xxxxxx' format
  formatRiskID(value: string) {
    let strings = [];
    strings.push(value.substring(0, 2));
    if (value.substring(2, 6) !== '') {
      strings.push(value.substring(2, 6));
    }
    if (value.substring(6, 12) != '') {
      strings.push(value.substring(6, 12));
    }
    return strings.join('-');
  }

  getExtensionFromFileName(filename): string {
    return filename.split('.').pop()
  }
  getFileNameWithoutExtension(fileName: string) : string{
    return fileName.slice(0, fileName.lastIndexOf("."));
  }

  formatPhoneNumber( phoneNumber: string ): string{
    if (phoneNumber.length >= 10) {
        
      phoneNumber = 
      '(' +
      phoneNumber.slice(0, 3) +
      ') ' +
      phoneNumber.slice(3, 6) +
      '-' +
      phoneNumber.slice(6, 10);
      return phoneNumber;

    }else{
      return phoneNumber;
    }
  }

  /**
   * Formatting phone number as (area)-exchange-no
   * @return {string}.
   */
  public phoneFormat(areaCode?, exchange?, number?): string {
    var phoneNumber;
    if (
      areaCode && exchange && number
    ) {
      phoneNumber = '(' + areaCode + ') ' + exchange + '-' + number;
      return phoneNumber;
    } else return '';
  }

   /**
   * Formatting zipcode as PostalcodeFive-PostalCodeFour
   * @return {string}.
   */
   public zipcodeFormat(postalCodeFive?, postalCodeFour?): string {
    var zipCode;
    if (
      postalCodeFive && postalCodeFour
    ) {
      zipCode = postalCodeFive + '-' + postalCodeFour;
    } 
    else if( 
      postalCodeFive && (postalCodeFour === null || postalCodeFour === '')
    ) {
      zipCode = postalCodeFive;
    } else 
    {
      zipCode = '';
    }
    return zipCode;
  }
  
  /**
   * Common method to generate identifier
   * @returns uuid
   */
  uuid() {
    // TODO: Move to utils file
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  convertDocumentToBlob(documentData: string, dataType: string): Blob {
    var byteString = atob(documentData);
    let mimeType;
    if (dataType === 'csv') {
      mimeType = 'text/csv';
    } else if (dataType === 'text') {
      mimeType = 'text/plain';
    } else {
      mimeType = `application/${dataType}`;
    }

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeType });
  }

  handlePhoneNumberChange($event, formControl, areaCode, exchange, number) {
    const phoneNumber = $event.target.value;
    var save = true;
    if (phoneNumber !== null && phoneNumber !== undefined) {
      const digits = phoneNumber.match(/\d+/g);
  
      if (digits !== null) {
        const rawPhoneNumber = digits.join('');
        areaCode = rawPhoneNumber.slice(0, 3);
        exchange = rawPhoneNumber.slice(3, 6);
        number = rawPhoneNumber.slice(6, 10);
  
        formControl.setValue(this.formatPhoneNumber(rawPhoneNumber));
        
      } else {
        // Handle the case where there are no digits in the input
        save = false;
      }
    } else {
      // Handle the case where phoneNumber is null or undefined
      save = false;
    }

    return {areaCode, exchange, number, save};
  }

  handleZipCodeChange($event, formControl, zip5, zip4) {
    const zipCode = $event.target.value;
    var save = true;
    if (zipCode !== null && zipCode !== undefined) {
      const digits = zipCode.match(/\d+/g);
  
      if (digits !== null) {
        const rawZipCode = digits.join('');
        zip5 = rawZipCode.slice(0, 5);
        zip4 = rawZipCode.slice(5, 9);
  
        formControl.setValue(this.zipcodeFormat(zip5, zip4));
        
      } else {
        // Handle the case where there are no digits in the input
        save = false;
      }
    } else {
      // Handle the case where phoneNumber is null or undefined
      save = false;
    }
    return {zip5, zip4, save};
  }

  convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    // Is this a "real" file? In other words, is this an instance of the original `File` class (not the one overriden by cordova-plugin-file).
    // If so, then we need to use the "real" FileReader (not the one overriden by cordova-plugin-file).
    if (blob instanceof Blob) {
      const realFileReader = (fileReader as any)._realReader;
      if (realFileReader) {
        fileReader = realFileReader;
      }
    }
    fileReader.onerror = reject;
    fileReader.onload = () => {
        resolve(fileReader.result);
    };
    fileReader.readAsDataURL(blob);
  });

  public dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }


  compareDate(date) {
    if(date > moment()) {
      return Constants.isDateInFuture;
    } else if(date < moment()) {
      return Constants.isDateInPast;
    } else {
      return;
    }
  }
  
  replaceCharacter(text: string, pattern: string, replacement: string) {
    return text.replace(new RegExp(pattern, 'g'), '' + replacement + '');
  }

  //Set content height & padding bottom when keyboard open on input focus from ion-modal (add/edit sheets)
  setContentHeight(content: IonContent,ngUnsubscribe: Subject<void>){
    this.platform.keyboardDidShow.pipe(takeUntil(ngUnsubscribe)).subscribe((ev) => {
      const {keyboardHeight} =ev;
      content.getScrollElement().then((scrolledElement) => {
        scrolledElement.style.height = '100%';
        scrolledElement.style.paddingBottom=keyboardHeight +100 +'px';

      });
    });

      this.platform.keyboardDidHide.pipe(takeUntil(ngUnsubscribe)).subscribe(() => {
        content.getScrollElement().then((scrolledElement) => {
          scrolledElement.style.height = 'auto';
          scrolledElement.style.paddingBottom='20px';
        });
     });
  }

  splitDescription(desc) {
    let hyphen = desc.includes(' - ');
      const FormCode = !hyphen ? desc.split(' – ')[0] : desc.split(' - ')[0];
      const FormName = !hyphen ? desc.split(' – ')[1] : desc.split(' - ')[1];
      let obj = {
        code: FormCode,
        name: FormName
      }
      return obj;
  }

  // To convert given date to ISOString
  convertDateToISOStringFormat(date) {
    try {
      if (date) {
        const dateISOString = new Date(date).toISOString();
        return dateISOString;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  launchNavigation(selectedOrder) {
    const geocoords = selectedOrder.PropertyLocationPoint.Longitude + ',' + selectedOrder.PropertyLocationPoint.Latitude;
    let wazeUrl = 'https://www.waze.com/ul?ll=' + geocoords + '&navigate=yes&zoom=17';
    if (this.platform.is('ios')) {
      window.open(wazeUrl, '_system');
      return;
    }

    if (this.platform.is('desktop')) {
      window.open(wazeUrl);
      return;
    }
  }

  /**
   * @param value Check whether value is null or undefined or empty string
   */
  public isCheckNull(value): boolean {
    if (value === null || value === '' || value === undefined || value === 'null') {
      return false;
    }
    else {
      return true;
    }
  }  
}
