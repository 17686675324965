/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/dot-notation */
import { FormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { SupplySourceSectionModel } from './supply-source.model';
import { numericInputValidator } from 'src/app/validators/formly-builder/numeric-input.validator';
import { WaterSupplyService } from 'src/app/services/formly-builder/full-risk/sprinkler/water-supply.service';
import { SystemDesignSectionModel } from './hydraulic-system-design.model';
import { DesignDataSectionModel } from './hydraulic-design-data.model';
import { FrictionLossSupplySourceSectionModel } from './friction-loss-supply-source.model';
import { WaterSupplyHydraulicSpocSectionModel } from './water-supply-hydraulic-spoc.model';
import { PipeScheduleSectionModel } from './pipe-schedule.model';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/services/util-service/constants';
import { Subject } from 'rxjs';

export class WaterSupplySectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe1 = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService, private waterSupplyService: WaterSupplyService) {
    super({
      key: 'asgrWaterSupply',
      type: 'rf-sheet-section',
      props: {
        title: 'Sprinkler Report',
        subTitle: 'Manage Water Supply',
        label: 'Water Supply',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        isApplied: false,
        actionButtons: [
          {
            section: 'waterSupply',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService',
          },
        ],
        sheetButtons: [
          {
            section: 'waterSupply',
            action: 'onClose',
            label: 'Close',
            service: 'waterSupplyService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'waterSupply',
            action: 'onApply',
            label: 'Apply',
            service: 'waterSupplyService',
            className: 'apply-btn large-btn',
            hasConfirmationPopup: true,
          },
        ],
      },
      hooks:{
        onInit: (field: FormlyFieldConfig) =>{          
          let exclusions:string[] = ['isBuildingHeightSameAsDesignAreaHeight',
                                      'isHydraulicWaterDemandLocationBor', 
                                      'sectionalizedVsSecondary',
                                      'supplySourceTypeSs1',
                                      'isSupervisedPressureTankSs1',
                                      'reportId',
                                      'buildingCombustibilityClass',
                                      'hydraulicApplyDensityConversion',
                                      'isRackStorageObstruction',
                                      'v1WaterSupplyHydraulics',
                                      'v2WaterSupplyHydraulics',
                                      'v1WaterSupplyPipeSchedules',
                                      'v2WaterSupplyPipeSchedules'];
          //Checking Add or Edit Button to Display based on hasValue and Existing Risk
          if(formlyBuilderService.hasValues(field.model,true,exclusions) 
              || formlyBuilderService.isExistingRisk()){            
            field.props.actionButtons[0].label = 'Edit'
            field.formControl?.markAllAsTouched();
          }
          //Subscribing to Apply Button Click and Save event to S3.
          formlyBuilderService.waterSupplyService.SaveToS3Event.pipe(takeUntil(this.ngUnsubscribe1),
          distinctUntilChanged())
          .subscribe(()=>{
            if(field?.props?.isApplied){
              field.props.actionButtons[0].label = 'Edit'
              field.formControl.markAllAsTouched();
            }
          });        
        },
        onDestroy: () => {
          this.ngUnsubscribe1.next();
          this.ngUnsubscribe1.complete();
        }
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
          field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
      },
      fieldGroup: [
        {
          type: 'rf-subsection-menu',
          fieldGroup: [
            {
              id: SectionEnum.FR_S_WS_WATERSUPPLY.toString(),
              props: {
                label: 'Water Supply',
                linkId: SectionEnum.FR_S_WATER_SUPPLY,
              },
              //water supply section
              fieldGroup: [
                {
                  key: 'evalutionOfWaterSupply',
                  wrappers: [ 'simple-section-wrapper' ],
                  fieldGroupClassName: 'water-supply-evolution-container',
                  props: {
                    label: 'Evaluation of Water Supplies',
                    linkId: SectionEnum.FR_SR_WS_EVALUATION,
                    isCollapsable: true,
                    isCollapsed: false,
                    className: 'padding-bottom',
                    shallowErrors: false,
                    checkTouchedControl:true,
                  },
                  fieldGroup: [
                    {
                      key: 'hasAdequateMaintenance',
                      type: 'rf-yes-no',
                      className: 'yes-no-styling',
                      props: {
                        label: 'Adequate Maintenance of Vacant or Idle Buildings',
                        isNAOption: true,
                        isChecked: true,
                        isCardEnabled: true,
                        rightAlign: true,
                      },
                    },
                    {
                      key: 'buildingHeightInFeet',
                      type: 'simple-input',
                      props: {
                        label: 'Building Height',
                        type: 'number',
                        unit: 'ft.',
                        isCardEnabled: true,
                        isHorizontalOrientation: true,
                        rightAlign: true,
                        required: true,
                      },
                      modelOptions: {
                        updateOn: 'blur',
                      },
                    },
                    {
                      key: 'isBuildingHeightSameAsDesignAreaHeight',
                      type: 'rf-yes-no',
                      className: 'yes-no-styling',
                      defaultValue: 'true',
                      props: {
                        label: 'Is the Building Height the same as the Design Area Height?',
                        isCardEnabled: true,
                        rightAlign: true,
                      },
                    },
                    {
                      key: 'topLineSprinklerHeightInFeet',
                      type: 'simple-input',
                      props: {
                        label: 'Design Area Height',
                        type: 'number',
                        unit: 'ft.',
                        isCardEnabled: true,
                        isHorizontalOrientation: true,
                        rightAlign: true,
                        isDisabled: true,
                      },
                      validators: {
                        designAreaValidation: {
                          expression: (control: FormControl, field: FormlyFieldConfig) => {
                            let designAreaheight;
                            let buildingHeight;
                            if (control?.value !== null && field?.model?.buildingHeightInFeet !== null) {
                              if (control.value !== undefined && field.model?.buildingHeightInFeet !== undefined) {
                                if (typeof control.value === 'string') {
                                  designAreaheight = parseInt(control?.value);
                                  buildingHeight = parseInt(field.model?.buildingHeightInFeet);
                                } else {
                                  designAreaheight = control?.value;
                                  buildingHeight = field.model?.buildingHeightInFeet;
                                }
                                if (designAreaheight <= buildingHeight) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }
                              return true;
                            } else {
                              return true;
                            }
                          },
                          message: 'Design Area Height cannot be greater than Building Height',
                        },
                      },
                      expressions: {
                        hide: (field: FormlyFieldConfig) => {
                          if (field.form?.value?.isBuildingHeightSameAsDesignAreaHeight?.toString() === 'true') {
                            field.formControl?.setValue(null);
                            return true;
                          } else {
                            return false;
                          }
                        },
                      },
                      modelOptions: {
                        updateOn: 'blur',
                      },
                    },
                  ],
                },
                SupplySourceSectionModel.build(formlyBuilderService).fields,
                {
                  key: 'firePumpEvaluation',
                  fieldGroup: [
                    {
                      wrappers: [ 'simple-section-wrapper' ],
                      props: {
                        label: 'Fire Pump Evaluation',
                        linkId: SectionEnum.FR_SR_WS_FIRE_PUMP,
                        isCollapsable: true,
                        isCollapsed: false,
                        className: 'padding-bottom',
                      },
                      fieldGroupClassName: 'fire-pump-evaluation-container water-supply-evolution-container',
                      fieldGroup: [
                        {
                          key: 'soleSourceDependency',
                          type: 'rf-radio',
                          className: 'sole-source-dependency',
                          defaultValue: '',
                          props: {
                            label: 'Type of Pump',
                            isHorizontalOrientation: true,
                            slot: 'start',
                            isCardEnabled: true,
                            required: false,
                            isDisabled: false,
                            options: formlyBuilderService?.waterSupplyService?.getSoleSourceDependencyLookupData(false)
                          },
                          expressions: {
                            'props.options': (field: FormlyFieldConfig) => formlyBuilderService?.waterSupplyService?.disableSoleSourceDependency()
                          }                        
                        },
                        {
                          key: 'percentProtectedAreaDependentOnPump',
                          type: 'simple-input',
                          props: {
                            label: '% of area protected by fire pump supply',
                            type: 'number',
                            isCardEnabled: true,
                            isHorizontalOrientation: true,
                            rightAlign: true,
                            unit: '%',
                            min: 1,
                            max: 100,
                            maxLength: 3,
                          }
                        },
                        {
                          key: 'isSupervisedPump',
                          type: 'rf-yes-no',
                          className: 'yes-no-styling',
                          props: {
                            label: 'Supervised Pump',
                            isCardEnabled: true,
                            isNAOption: true,
                            isChecked: true,
                            rightAlign: true,
                            required: false,
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) =>
                              field.options?.formState?.service?.waterSupplyService?.requiredCheckOfPumps(field)
                          }
                        },
                        {
                          key: 'isTwoPowerSourcePump',
                          type: 'rf-yes-no',
                          className: 'yes-no-styling',
                          props: {
                            label: 'Two Power Supplies',
                            isCardEnabled: true,
                            isNAOption: true,
                            isChecked: true,
                            rightAlign: true,
                            required: false,
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) =>
                              field.options?.formState?.service?.waterSupplyService?.requiredCheckOfPumps(field)
                          }
                        },
                        {
                          key: 'hasImproperPumpMaintenance',
                          type: 'rf-yes-no',
                          className: 'yes-no-styling',
                          props: {
                            label: 'Improper Pump Maintenance',
                            isCardEnabled: true,
                            isNAOption: true,
                            isChecked: true,
                            rightAlign: true,
                          },
                        },
                        {
                          key: 'isEvaluatedThroughBypassOnly',
                          type: 'custom-checkbox',
                          props: {
                            label: 'Fire Pump Evaluated Through Bypass',
                            isCardEnabled: true,
                            labelFirst: true,
                            rightAlign: true,
                            isDisabled: false,
                          },
                          expressions: {
                            'props.isDisabled': (field: FormlyFieldConfig) => field.form.value.soleSourceDependency,
                          },
                          hooks: {
                            onInit: (field) => {
                              field?.formControl?.valueChanges.subscribe(value => {
                                formlyBuilderService?.waterSupplyService?.calculateSoleSourceDependency(field);
                              });
                            }
                          },
                        },
                        {
                          key: 'isFirePumpInBothWaterSupplies',
                          type: 'custom-checkbox',
                          props: {
                            label: 'Fire Pump in Both Water Supplies',
                            isCardEnabled: true,
                            labelFirst: true,
                            rightAlign: true,
                          },
                        },
                        { key: 'nonBypassPumpForSupplySource' },
                        { key: 'bypassPumpForSupplySource' },
                        // Fire Pump Deficiencies
                        {
                          fieldGroup: [
                            { template: '<div class="ws-title-style">Fire Pump Deficiencies</div>' },
                            {
                              key: 'isNaturalGasPump',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Natural Gas',
                                isCardEnabled: true,
                                labelFirst: true,
                                rightAlign: true,
                              },
                              validators: {
                                validation: [
                                  {
                                    name: 'mutual-exclusive-validator',
                                    options: {
                                      compareWith: 'isGasolinePump',
                                      msg: 'Only one non-standard fuel source can apply',
                                      value: true,
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              key: 'isGasolinePump',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Gasoline',
                                isCardEnabled: true,
                                labelFirst: true,
                                rightAlign: true,
                                showErrorMsg: false,
                              },
                              validators: {
                                validation: [
                                  {
                                    name: 'mutual-exclusive-validator',
                                    options: {
                                      compareWith: 'isNaturalGasPump',
                                      msg: 'Only one non-standard fuel source can apply',
                                      value: true,
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              key: 'isUndependablePumpElectricSupply',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Undependable Electric Supply',
                                isCardEnabled: true,
                                labelFirst: true,
                                rightAlign: true,
                              },
                            },
                            {
                              key: 'isNonStandardInstallationPump',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Non Standard Installation',
                                isCardEnabled: true,
                                labelFirst: true,
                                rightAlign: true,
                              },
                            },
                            {
                              key: 'isUnlistedPump',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Unlisted Pump',
                                isCardEnabled: true,
                                labelFirst: true,
                                rightAlign: true,
                              },
                            },
                            {
                              key: 'isNonStandardAuxilliaryEquipment',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Non Standard Auxiliary Equipment',
                                isCardEnabled: true,
                                labelFirst: true,
                                rightAlign: true,
                              },
                            },
                            {
                              key: 'isUnsatisfactorySuction',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Unsatisfactory Suction',
                                isCardEnabled: true,
                                labelFirst: true,
                                rightAlign: true,
                              },
                            }
                          ],
                          expressions: {
                            hide: (field: FormlyFieldConfig) => {
                              const model = field.parent?.parent?.form?.value;
                              const isSupplySoucre2Hidden =
                                field?.parent?.parent?.parent?.formControl[ 'controls' ]?.supplySource?._fields[ 0 ]?.props?.hideSupplySource2;
                              if (model) {
                                return !((model?.supplySource?.ss1?.supplySourceTypeSs1 === 'Booster Pump' ||
                                  model?.supplySource?.ss1?.supplySourceTypeSs1 === 'Sole Source Pump' ||
                                  model?.supplySource?.ss2?.supplySourceTypeSs2 === 'Booster Pump' ||
                                  model?.supplySource?.ss2?.supplySourceTypeSs2 === 'Sole Source Pump') ||
                                  ((!model?.supplySource?.ss1?.supplySourceTypeSs1 ||
                                    !isSupplySoucre2Hidden && !model?.supplySource?.ss2?.supplySourceTypeSs2) &&
                                    model?.firePumpEvaluation?.percentProtectedAreaDependentOnPump));
                              } else {
                                return true;
                              }
                            },
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  key: 'secondarySupply',
                  wrappers: [ 'simple-section-wrapper' ],
                  props: {
                    label: 'Secondary Supply',
                    linkId: SectionEnum.FR_SR_WS_SECONDARY_SUPPLY,
                    isCollapsable: true,
                    isCollapsed: false,
                    className: 'padding-bottom ',
                  },
                  fieldGroup: [
                    {
                      key: 'sectionalizedVsSecondary',
                      type: 'rf-radio',
                      className: 'secSupplyRadio',
                      defaultValue: '',
                      props: {
                        isHorizontalOrientation: false,
                        slot: 'end',
                        options: [
                          { label: 'Sectionalized City Supply', value: 'C', disabled: false },
                          { label: 'Non - Automatic Pump, not over 15% def', value: 'S', disabled: false },
                          { label: 'N/A', value: '', disabled: false },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: SectionEnum.FR_S_WS_HYDRAULIC.toString(),
              props: { label: 'Hydraulic', linkId: SectionEnum.FR_S_WS_HYDRAULIC },
              expressions: {
                'props.isDisabled': (field: FormlyFieldConfig) => !formlyBuilderService?.waterSupplyService?.checkHydraulic(),
                // hide: (field: FormlyFieldConfig) => {
                //   if(!formlyBuilderService?.waterSupplyService?.checkHydraulic()){
                //       return true;
                //   }
                //   return false;
                // } 
              },
              // hydralic section
              fieldGroup: [
                WaterSupplyHydraulicSpocSectionModel.build(formlyBuilderService).fields,
                {
                  key: 'isWaterSupplyNotEvaluatedComment',
                },
                {
                  key: 'isWaterSupplyNotEvaluated',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  props: {
                    label: 'Water Supply Not Evaluated',
                    isHorizontalOrientation: true,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'isWaterSupplyNotEvaluatedComment',
                  },
                },
                {
                  key: 'hasAdequatePressureOnMainDrainTest',
                  wrappers: [ 'question-wrapper' ],
                  type: 'rf-yes-no',
                  props: {
                    label:
                      'Does the Main Drain Test provide adequate results to ensure at least 20% of the required water supply is available?',
                    rightAlign: true,
                    firstColSize: 8,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      if (
                        !field?.model?.hydraulicDesignDensity ||
                        !field?.model?.hydraulicRemoteArea ||
                        !field?.model?.hydraulicRemoteArea ||
                        !field?.model?.hydraulicWaterDemandPressure
                      ) {
                        if (field?.model?.isWaterSupplyNotEvaluated) {
                          return false;
                        } else {
                          return true;
                        }
                      } else {
                        return true;
                      }
                    },
                  },
                },
                SystemDesignSectionModel.build(formlyBuilderService).fields,
                DesignDataSectionModel.build(formlyBuilderService).fields,
                {
                  key: 'hydraulicDensityConversion',
                  wrappers: [ 'simple-section-wrapper' ],
                  props: {
                    label: 'Density Conversion',
                    linkId: SectionEnum.FR_S_WS_HY_DENSITY_CONVERSION,
                    isCollapsable: true,
                    isCollapsed: false,
                    toggleAction: true,
                    shallowErrors: false,
                    checkTouchedControl: true,
                    className: 'padding-bottom',
                  },
                  fieldGroup: [
                    {
                      key: 'hydraulicApplyDensityConversion',
                      defaultValue: false,
                      props: {
                        required: true,
                      },
                    },
                    {
                      className: 'hydraulic-density-container',
                      fieldGroup: [
                        {
                          key: 'hydraulicNewDesignDensity',
                          type: 'simple-input',
                          resetOnHide: true,
                          className: 'hydraulic-density-input',
                          props: {
                            label: 'New Design Density',
                            isDecimal: true,
                            isCardEnabled: true,
                            isHorizontalOrientation: true,
                            rightAlign: true,
                            required: true,
                            unit: 'GPM/sq.ft.',
                            type: 'decimal',
                            modelOptions: {
                              updateOn: 'blur'
                            },
                            change: (field: FieldTypeConfig) =>
                              formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) =>
                              field?.model?.hydraulicApplyDensityConversion,
                          },
                        },
                        {
                          key: 'hydraulicNewDesignArea',
                          type: 'simple-input',
                          resetOnHide: true,
                          className: 'hydraulic-density-input',
                          props: {
                            label: 'New Remote Area',
                            isCardEnabled: true,
                            isHorizontalOrientation: true,
                            rightAlign: true,
                            required: true,
                            type: 'number',
                            unit: 'sq.ft.',
                            keypress: (field, event) => numericInputValidator(field, event),
                          },

                          expressions: {
                            'props.required': (field: FormlyFieldConfig) =>
                              field?.model?.hydraulicApplyDensityConversion,
                          },
                        },
                        {
                          key: 'hydraulicNewHoseDemand',
                          type: 'simple-select',
                          resetOnHide: true,
                          className: 'hydraulic-density-select select-full-width',
                          props: {
                            label: 'New Hose Stream',
                            isHorizontalOrientation: true,
                            isCardEnabled: true,
                            required: true,
                            isAsteriskNotRequired: true,
                            options: [
                              { value: 100, label: '100 gpm' },
                              { value: 250, label: '250 gpm' },
                              { value: 500, label: '500 gpm' },
                            ],
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) =>
                              field?.model?.hydraulicApplyDensityConversion,
                          },
                          hooks: {
                            onInit: (field) => {
                              const options = [
                                { value: 100, label: '100 gpm' },
                                { value: 250, label: '250 gpm' },
                                { value: 500, label: '500 gpm' },
                              ];
                              if (field?.formControl?.value && field?.formControl?.value !== null) {
                                const result = options.some(
                                  (option) => option.value === parseInt(field?.formControl?.value)
                                );
                                if (!result) {
                                  field?.formControl?.setValue(null);
                                }
                              }
                            },
                          },
                        },
                        {
                          key: 'hydraulicNewWaterSupplyDuration',
                          type: 'simple-select',
                          resetOnHide: true,
                          className: 'hydraulic-density-select select-full-width',
                          props: {
                            label: 'New Supply Duration',
                            isHorizontalOrientation: true,
                            isCardEnabled: true,
                            required: true,
                            isAsteriskNotRequired: true,
                            options: [
                              { value: 30, label: '30 minutes' },
                              { value: 60, label: '60 minutes' },
                              { value: 90, label: '90 minutes' },
                              { value: 120, label: '120 minutes' },
                            ],
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) =>
                              field?.model?.hydraulicApplyDensityConversion,
                          },
                          hooks: {
                            onInit: (field) => {
                              const options = [
                                { value: 30, label: '30 minutes' },
                                { value: 60, label: '60 minutes' },
                                { value: 90, label: '90 minutes' },
                                { value: 120, label: '120 minutes' },
                              ];
                              if (field?.formControl?.value && field?.formControl?.value !== null) {
                                const result = options.some(
                                  (option) => option.value === parseInt(field?.formControl?.value)
                                );
                                if (!result) {
                                  field?.formControl?.setValue(null);
                                }
                              }
                            },
                          },
                        },
                      ],
                      expressions: {
                        hide: (field: FormlyFieldConfig) => {
                          if (field?.form?.value?.hydraulicApplyDensityConversion?.toString() === 'false') {
                            return true;
                          } else {
                            return false;
                          }
                        },
                      },
                    },
                  ],
                },

                {
                  key: 'hydraulicRackStorageObstruction',
                  wrappers: [ 'simple-section-wrapper' ],
                  fieldGroupClassName: 'hydraulic-rack-storage-container',
                  className: 'toggle-margin',
                  props: {
                    label: 'Rack Storage Deficiencies',
                    linkId: SectionEnum.FR_S_WS_HYDRAULIC,
                    isCollapsable: true,
                    isCollapsed: false,
                    toggleAction: true,
                    shallowErrors: false,
                    checkTouchedControl: true,
                    className: 'padding-bottom ',
                  },
                  fieldGroup: [
                    {
                      key: 'isRackStorageObstruction',
                      defaultValue: false,
                      hooks: {
                        onInit: (field) => {
                          field?.formControl?.valueChanges.subscribe(value => {
                            if (value !== null && value !== undefined && value) {
                              formlyBuilderService.formlyWrapperService.scrollToBottom(SectionEnum.FR_S_WS_HYDRAULIC);
                            }
                          });
                        }
                      },
                    },
                    {
                      key: 'rackStorageObstruction',
                      fieldGroup: [
                        {
                          key: 'levelsMissingSprinklers',
                          type: 'simple-input',
                          resetOnHide: true,
                          props: {
                            label: 'Number of Levels Missing Sprinklers',
                            isCardEnabled: true,
                            isHorizontalOrientation: true,
                            type: 'number',
                            rightAlign: true,
                            required: false,
                            keypress: (field, event) => numericInputValidator(field, event),
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) =>
                              field?.parent?.parent?.model?.isRackStorageObstruction,
                          },
                        },
                        {
                          key: 'hasConsecutiveMissingLevels',
                          type: 'custom-checkbox',
                          resetOnHide: true,
                          className: 'l-m-left',
                          props: {
                            label: 'Two or More Consecutive Levels Missing Sprinklers',
                            className: 'padding-bottom',
                            labelFirst: true,
                            isCardEnabled: true,
                            rightAlign: true,
                            isDisabled: false,
                          },
                          expressions: {
                            'props.isDisabled': (field: FormlyFieldConfig) => {
                              if (
                                field?.form?.value?.levelsMissingSprinklers === null ||
                                field?.form?.value?.levelsMissingSprinklers === undefined ||
                                field?.form?.value?.levelsMissingSprinklers < 2
                              ) {
                                field.formControl?.setValue(null);
                                return true;
                              } else {
                                return false;
                              }
                            },
                          },
                        },
                        {
                          key: 'isDoubleRowStorage',
                          type: 'custom-checkbox',
                          resetOnHide: true,
                          className: 'l-m-left',
                          props: {
                            label: 'Double Row Without Minimum Transverse Flue Space',
                            labelFirst: true,
                            isCardEnabled: true,
                            rightAlign: true,
                          },
                        },
                      ],
                      expressions: {
                        hide: (field: FormlyFieldConfig) => 
                          !field?.form?.value?.isRackStorageObstruction,
                      },
                    },
                  ],
                },
              ],
            },
            PipeScheduleSectionModel.build(formlyBuilderService).fields,
            {
              id: SectionEnum.FR_S_WS_FRICTION_LOSS?.toString(),
              props: { label: 'Friction Loss', linkId: SectionEnum.FR_S_WS_FRICTION_LOSS },
              // Friction loss sections
              key: 'asgrWaterSupplyFrictionLoss',
              fieldGroup: [
                {
                  key: 'supplySource1',
                  type: 'fr-common-custom-grid-view',
                  className: 'friction-loss-grid-view-container',
                  wrappers: [ 'simple-section-wrapper' ],
                  props: {
                    label: 'Supply Source 1',
                    isCollapsable: true,
                    isCollapsed: true,
                    hasShadow: false,
                    actionButtons: [ { section: 'supplySource1', action: 'add', label: 'Add', isDisabled: false } ],
                    gridSection: 'supplySource1',
                    parentKey: null,
                    isEdit: true,
                    isDelete: true,
                    showOnlyRecentOne: false,
                    isAddReportIdentifier: true,
                    isAddIdentifier: true,
                    identifier: 'supplySource1Id',
                    className: 'padding-bottom',
                    gridColConfig: [
                      { label: 'Pipe', propertyPath: 'pipeNumber', className: 'common-grid-view-width-60' },
                      {
                        label: 'Diameter',
                        propertyPath: 'pipeInsideDiameter',
                        className: 'common-grid-view-width-100',
                        formatByGivenMethod: true,
                        action: 'getNominalDiameterValue',
                        service: 'frictionLossService',
                      },
                      {
                        label: 'Fitting and Valve',
                        propertyPath: 'allFittingValve',
                        className: 'common-grid-view-width-195',
                        formatByGivenMethod: true,
                        action: 'getFittingValveValue',
                        service: 'frictionLossService',
                      },
                    ],
                  },
                  expressions: {
                    'props.isCollapsed': (field: FormlyFieldConfig) => {
                      const collapsed = field?.formControl?.value
                        ? (field?.formControl?.value?.length > 0 ? false : true)
                        : true;
                      return collapsed;
                    },
                  },

                  fieldArray: {
                    props: {
                      title: 'Sprinkler Report',
                      subTitle: 'Friction Loss',
                      sheetButtons: [
                        {
                          section: 'frictionLossSection',
                          action: 'onClose',
                          label: 'Close',
                          service: 'frictionLossService',
                          className: 'close-btn medium-btn',
                          passInitialModel: true,
                        },
                        {
                          section: 'frictionLossSection',
                          action: 'onApply',
                          label: 'Apply',
                          service: 'frictionLossService',
                          className: 'apply-btn large-btn',
                          hasConfirmationPopup: true,
                        },
                      ],
                    },
                    // fieldGroup:FrictionLossSupplySourceSectionModel.build(formlyBuilderService).fields,
                    fieldGroup: [ FrictionLossSupplySourceSectionModel.build(formlyBuilderService).fields ],
                  },
                },
                {
                  key: 'supplySource2',
                  type: 'fr-common-custom-grid-view',
                  className: 'friction-loss-grid-view-container',
                  wrappers: [ 'simple-section-wrapper' ],
                  props: {
                    label: 'Supply Source 2',
                    isCollapsable: true,
                    isCollapsed: true,
                    hasShadow: false,
                    actionButtons: [ { section: 'supplySource2', action: 'add', label: 'Add', isDisabled: false } ],
                    gridSection: 'supplySource2',
                    parentKey: null,
                    isEdit: true,
                    isDelete: true,
                    showOnlyRecentOne: false,
                    isAddReportIdentifier: true,
                    isAddIdentifier: true,
                    identifier: 'supplySource2Id',
                    className: 'padding-bottom',
                    gridColConfig: [
                      { label: 'Pipe', propertyPath: 'pipeNumber', className: 'common-grid-view-width-60' },
                      {
                        label: 'Diameter',
                        propertyPath: 'pipeInsideDiameter',
                        className: 'common-grid-view-width-100',
                        formatByGivenMethod: true,
                        action: 'getNominalDiameterValue',
                        service: 'frictionLossService',
                      },
                      {
                        label: 'Fitting and Valve',
                        propertyPath: 'allFittingValve',
                        className: 'common-grid-view-width-195',
                        formatByGivenMethod: true,
                        action: 'getFittingValveValue',
                        service: 'frictionLossService',
                      },
                    ],
                  },
                  expressions: {
                    'props.isCollapsed': (field: FormlyFieldConfig) => {
                      const collapsed = field?.formControl?.value
                        ? field?.formControl?.value?.length > 0
                          ? false
                          : true
                        : true;
                      return collapsed;
                    },
                    'props.actionButtons[0].isDisabled': (field: FormlyFieldConfig) =>
                      //const isSupplySource2 = field?.parent?.form?.controls['supplySource'].controls.ss2;
                      formlyBuilderService.frictionLossService.checkWaterSupplySource2(field),
                  },
                  fieldArray: {
                    props: {
                      title: 'Sprinkler Report',
                      subTitle: 'Friction Loss',
                      sheetButtons: [
                        {
                          section: 'frictionLossSection',
                          action: 'onClose',
                          label: 'Close',
                          service: 'frictionLossService',
                          className: 'close-btn medium-btn',
                          passInitialModel: true,
                        },
                        {
                          section: 'frictionLossSection',
                          action: 'onApply',
                          label: 'Apply',
                          service: 'frictionLossService',
                          className: 'apply-btn large-btn',
                          hasConfirmationPopup: true,
                        },
                      ],
                    },

                    fieldGroup: [ FrictionLossSupplySourceSectionModel.build(formlyBuilderService).fields ],
                  },
                },
              ],
            },
            {
              key: 'buildingCombustibilityClass'
            }          
          ],
        },
      ],
    });
  }
}
