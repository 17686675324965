/* eslint-disable @typescript-eslint/dot-notation */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class WindLossSectionModel extends FormlySectionModelAbstract {
  constructor() {
    super({
      key: 'windLossHistories',
      type: 'fr-common-custom-grid-view',
      className: 'wind-loss-history-container',
      wrappers: [ 'simple-section-wrapper' ],
      props: {

        // Props for fr-common-custom-grid-view
        isSwipeActionNeeded: false,
        label: 'Wind Loss History',
        isCollapsable: true,
        isCollapsed: false,
        actionButtons: [ { section: 'windLossSection', action: 'openArraySheetAdd', label: 'Add', isDisabled: false } ],
        gridSection: 'windLossHistories',
        gridColConfig: [
          { label: 'Date of Loss', propertyPath: 'windLossHistoryDateTime', isDate: true, className: 'common-grid-view-width-120', dateFormat: 'MM/dd/YYYY' },
          { label: 'Description', propertyPath: 'description', size: 9, className: 'dummy-class' },
        ],

        // Props for edit sheet
        title: 'Wind Report',
        subTitle: 'Wind Loss History',
        isAddIdentifier: true,
        identifierKey: 'windLossHistoryId',
        reportIdentifierKey: 'reportId',
        reportName: 'WindReport',
        isCloneFnRequired: true,
        sheetButtons: [
          {
            section: 'windLossHistories',
            action: 'onClose',
            label: 'Close',
            service: 'windLossService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'windLossHistories',
            action: 'onApply',
            label: 'Apply',
            service: 'windLossService',
            className: 'apply-btn large-btn',
            role: 'apply',
          },
        ],
      },
      expressions: {
        'props.actionButtons[0].label': (field: FormlyFieldConfig) =>
          field?.model?.length ? 'Edit' : Constants.btnText,
        'props.actionButtons[0].action': (field: FormlyFieldConfig) =>
          field?.model?.length ? 'openArraySheetEdit' : 'openArraySheetAdd',
        hide: (field: FormlyFieldConfig) => {
          // Hide the whole section if WR is disabled
          const disabledField = field?.options?.formState?.isMainSectionDisabled?.filter(val => val.sectionId === SectionEnum.FR_WIND);
          if (disabledField.length === 1) {
            return true;
          }
          else if (disabledField.length === 0) {
            return false;
          }
        }
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.options.formState.service.windLossService.collapseField(field);
        }
      },
      fieldGroupClassName: 'wind-loss-test',
      fieldArray: {
        fieldGroupClassName: 'formly-flex-container wind-loss-history-container',
        wrappers: [ 'error-info-wrapper' ],
        fieldGroup: [
          {
            key: 'windLossHistoryDateTime',
            type: 'rf-date-picker',
            className: 'wind-loss-history-date',
            props: {
              displayFormat: 'MM/dd/yy',
              required: true,
              datePickerId: 'windLossHistoryDateTime',
              isAsteriskNotRequired: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                if (field?.parent[ 'index' ]) {
                  field.props.datePickerId = 'windLossHistoryDateTime' + (field?.parent[ 'index' ] + 1);
                }
              },
            },
            validators: {
              validation: [ { name: 'future-date-validator', options: { msg: 'Cannot be future date.' } } ],
            },
          },
          {
            key: 'description',
            type: 'rf-text-area',
            className: 'wind-loss-description',
            props: {
              isAsteriskNotRequired: true,
              label: 'Description',
              required: true,
              rows: 6,
              maxLength: 1000
            },
          },
        ],
      },
    });
  }
}
