import { FormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';

export class DesignDataSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'hydraulicDataDesign',
      type: 'fr-common-custom-grid-view',
      wrappers: ['simple-section-wrapper'],
      className: 'hydraulic-data-design-container',
      resetOnHide: true,
      props: {
        label: 'Hydraulic Design Data',
        isCollapsable: true,
        isCollapsed: false,
        shallowErrors: true,
        // checkTouchedControl: true,
        actionButtons: [
          { section: 'hydraulicDataDesign', action: 'openArraySheetAdd', label: 'Add', isDisabled: false },
        ],
        gridSection: 'hydraulicDataDesign',
        gridColConfig: [
          { label: 'System', propertyPath: 'systemNumber', className: 'common-grid-view-width-80' },
          { label: 'Design', propertyPath: 'designStandard', className: 'common-grid-view-width-145 grid-margin'},
          { label: 'Description', propertyPath: 'areaDescription', className: 'common-grid-view-width-auto' },
        ],
        isAddIdentifier: true,
        identifierKey: 'hydraulicDataDesign',
        reportIdentifierKey: 'reportId',
        reportName: 'sprinklerReport',
        title: 'Sprinkler Report',
        subTitle: 'Hydraulic Data Design',
        isSubTitleNeededInsideSheet: false,
        isCloneFnRequired: true,

        sheetButtons: [
          {
            section: 'hydraulicDataDesign',
            action: 'onClose',
            label: 'Close',
            service: 'dataDesignTableService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'hydraulicDataDesign',
            action: 'onApply',
            label: 'Apply',
            service: 'dataDesignTableService',
            className: 'apply-btn large-btn',
            role: 'apply',
          },
        ],
      },
      fieldGroupClassName: 'hydraulic-data-design-container',
      expressions: {
        'props.actionButtons[0].label': (field: FormlyFieldConfig) =>field?.model?.length ? 'Edit' : Constants.btnText,
        'props.actionButtons[0].action': (field: FormlyFieldConfig) =>field?.model?.length ? 'openArraySheetEdit' : 'openArraySheetAdd',
      },     
      fieldArray: {
        fieldGroupClassName: 'data-design-field-container',
        wrappers: ['error-info-wrapper'],
        resetOnHide: true,
        fieldGroup: [
          {
            key: 'systemNumber',
            name: 'System',
            type: 'simple-input',
            className: 'system-number-input',
            defaultValue: null,
            resetOnHide: true,
            props: {
              label: 'System',
              required: true,
              isAsteriskNotRequired: true,
              maxLength: 3,
            },
            validators: {
              checkLength: {
                expression: (control: FormControl, field: FormlyFieldConfig) => {
                  if (control?.value && control?.value !== null && control?.value !== '') {
                    if (control?.value.length > 3) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                  return true;
                },
               
                message: 'Limits to three characters only',
              },
            },
          },
          {
            key: 'designStandard',
            type: 'simple-select',
            className: 'design-mt',
            resetOnHide: true,
            props: {
              label: 'Design',
              required: false,
              isAsteriskNotRequired: true,
              options: [
                { value: 'Density Area', label: 'Density Area' },
                { value: 'Room Design', label: 'Room Design' },
                { value: 'CMDA', label: 'CMDA' },
                { value: 'CMSA', label: 'CMSA' },
                { value: 'ESFR', label: 'ESFR' },
              ],
            },     
            validators: {
              checkExistingDesign: {
                expression: (control: FormControl, field: FormlyFieldConfig) => {
                  const options= [
                    { value: 'Density Area', label: 'Density Area' },
                    { value: 'Room Design', label: 'Room Design' },
                    { value: 'CMDA', label: 'CMDA' },
                    { value: 'CMSA', label: 'CMSA' },
                    { value: 'ESFR', label: 'ESFR' },
                  ];
                  const isHydraulicEntryPresent = formlyBuilderService.waterSupplyService.checkHydraulic();
                  if(isHydraulicEntryPresent){
                  const result = options.some((option) => option.value === control?.value);
                  if (!result) {
                    return false;
                  }else{
                    return true;
                  }
                }else{
                  return true;
                }             
                },
               
                message: 'Design Required',
              },
            },
          },
          {
            key: 'areaDescription',
            name: 'Description',
            type: 'simple-input',
            className: 'desc-input',
            defaultValue: null,
            focus: true,
            resetOnHide: true,
            props: {
              label: 'Description',
              required: false,
              isAsteriskNotRequired: true,
              showError: false,
            },
            expressions: {
              'props.required': (field: FormlyFieldConfig) =>(field?.form?.get('designStandard')?.value === 'ESFR' ||
                  field?.form?.get('designStandard')?.value === 'CMSA' || field?.form?.get('designStandard')?.value === 'Room Design') ? true: false,
            },
          },           
          {
            key: 'areaSquareFeet',
            name: 'area',
            type: 'simple-input',
            className: 'area-input',
            defaultValue: null,
            resetOnHide: true,
            props: {
              label: 'Area Sq. Ft.',
              type: 'number',
              unit: 'sq.ft.',
              isAsteriskNotRequired: true,
            },                            
          },
          {
            key: 'areaValidator'
          },
          {
            key: 'designDensity',
            name: 'density',
            type: 'simple-input',
            className: 'medium-width',
            defaultValue: null,
            resetOnHide: true,
            props: {
              label: 'Density',
              required: false,                           
              max: 99999,
              maxLength: 7,
              minNumeric: 0.01,
              isAsteriskNotRequired: true,
              readonly: false,
              type: 'decimal', 
              isDecimal:true,         
              modelOptions: {
                updateOn: 'blur'
              },
              change: (field: FieldTypeConfig) =>
                formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")                       
            },
            expressions: {           
                  'props.readonly': (field: FormlyFieldConfig) =>{
                    if(field?.form?.get('designStandard')?.value === 'ESFR' || field?.form?.get('designStandard')?.value === 'CMSA' || field?.form?.get('designStandard')?.value === 'Room Design')
                      {                            
                       field.props.required = false;
                        return true;
                     } else {
                       field.props.required = true;
                       return false;                    
                     }
                      
                 },            
            },
          },
          {
            key: 'designArea',
            name: 'designArea',
            type: 'simple-input',
            className: 'medium-width',
            defaultValue: null,
            resetOnHide: true,
            props: {
              label: 'Design Area',
              required: false,
              type: 'decimal',
              maxLength: 7,
              isAsteriskNotRequired: true,
              readonly: false,
              isDecimal:true,         
              modelOptions: {
                updateOn: 'blur'
              },
              change: (field: FieldTypeConfig) =>
                formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
            },
            expressions: {           
              'props.readonly': (field: FormlyFieldConfig) =>{
                if(field?.form?.get('designStandard')?.value === 'ESFR' || field?.form?.get('designStandard')?.value === 'CMSA' || field?.form?.get('designStandard')?.value === 'Room Design')
                  {                                                                     
                    field.props.required = false;
                    return true;
                 } else {
                   field.props.required = true;
                   return false;                    
                 }
                  
             },             
            },
          },
          {
            key: 'waterDemandGpm',
            name: 'demandFlow',
            type: 'simple-input',
            className: 'medium-width',
            defaultValue: null,
            resetOnHide: true,
            props: {
              label: 'Demand Flow',
              isSectionLabelNormal: true,
              isAsteriskNotRequired: true,
              required: true,
              type: 'decimal',
              unit: 'GPM',
              min: 0,
              max: 99999,
              maxLength: 7,
              isDecimal:true,         
              modelOptions: {
                updateOn: 'blur'
              },
              change: (field: FieldTypeConfig) =>
                formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
            },
          },
          {
            key: 'waterDemandPsi',
            name: 'demandPsi',
            type: 'simple-input',
            className: 'medium-width',
            defaultValue: null,
            resetOnHide: true,
            props: {
              label: 'Demand PSI',
              required: true,
              type: 'decimal',
              maxLength: 7,
              isAsteriskNotRequired: true,
              unit: 'PSI',
              isDecimal:true,         
              modelOptions: {
                updateOn: 'blur'
              },
              change: (field: FieldTypeConfig) =>
                formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
            },
          },
          {
            key: 'reportId',
            defaultValue: null,
          },          
        ],
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            if (field.parent.props.actionButtons[0].label === 'Add') {
              field.formControl['controls']?.designDensity?.setValue(
                field.parent?.parent?.model?.hydraulicSystemDesign?.hydraulicDesignDensity
              );
              field.formControl['controls']?.designArea?.setValue(
                field.parent?.parent?.model?.hydraulicSystemDesign?.hydraulicRemoteArea
              );
              field.formControl['controls']?.waterDemandGpm?.setValue(
                field.parent?.parent?.model?.hydraulicSystemDesign?.hydraulicWaterDemandFlow
              );
              field.formControl['controls']?.waterDemandPsi?.setValue(
                field.parent?.parent?.model?.hydraulicSystemDesign?.hydraulicWaterDemandPressure
              );
            }
          },
        },       
      },
      validators: {
            checkSpoc: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const formlyService = field?.options?.formState?.service;
                const totalSpocArea= formlyService.waterSupplyService.getSpocArea(formlyService);
                const totalDesignArea= formlyService.waterSupplyService.calculateArea(field);               
                if(totalDesignArea > totalSpocArea){
                  if (field?.fieldGroup?.length) {
                    field.fieldGroup.forEach((item)=>{
                      item?.formControl?.get('areaSquareFeet')?.setErrors({ 'area-validator': {  message: 'Area cannot be greater than the building area.' } })
                    })     
                  }                               
                }else{
                  if (field?.fieldGroup?.length) {
                    field.fieldGroup.forEach((item)=>{
                      // field?.formControl?.get('areaSquareFeet').markAsUntouched();
                      item?.formControl?.get('areaSquareFeet')?.setErrors(null);
                      
                    })   
                  }               
                }
                return true;
              },             
            },
            duplicateValidation: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const isDuplicate = this.formlyBuilderService.dataDesignTableService.hasDuplicateWithDifferentFields(field);
                if (isDuplicate) {
                  return false;
                } else {
                  return true;
                }
              },
              message: 'Duplicate entries should not be made, either remove or edit the duplicate entries'
            }
          },
    });   
  }
}
