import { DebugConsoleLog } from 'src/app/interfaces/debug-console';

export class Constants {
  public static CustomerAssetsFilesAllowd = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  public static ParentPages = [
    'summary',
    'forms',
    'recommendations',
    'photo-management',
    'asset-mgmt',
    'customer-requirements',
    'map',
  ];
  public static CustomerAssetsAttachTypes: string[] = [
    'General',
    'BVS Valuation',
    'Custom Recommendations',
    'Ausum Forms',
  ];
  public static BackendTypeError = "Unexpected backend error";
  public static UndefinedError = "UNDEF";
  public static UndefinedErrorMessage = "Undefined Error";
  public static InternalAssetsAttachTypes: string[] = ['General'];
  public static RiskAttachTypes: string[] = [ 'General' ];  
  public static assetsValidFileExtension: string[] = ['doc', 'docx', 'pdf'];
  public static riskAttachmentValidFileExtension: string[] = ['doc', 'docx', 'pdf', 'jpeg', 'jpg', 'ax2', 'esx', 'csv'];  
  public static dateFormat = 'MM/dd/yyyy';
  public static daysCountFormat = 'YYYY-MM-DD';
  public static tabHome = '../../../assets/icon/tab-home.svg';
  public static riskFormDataSyncDelay = 500;
  public static appTabs = [
    { url: 'home', label: 'Home', icon: 'tab-home', badge: 0, permissionRequired: false },
    { url: 'my-orders', label: 'My Orders', icon: 'tab-my-orders', badge: 0, permissionRequired: false },
    {
      url: 'exterior-survey',
      label: 'Exterior Survey',
      icon: 'tab-exterior-survey',
      badge: 0,
      permissionRequired: false,
    },
    { url: 'more', label: 'More', icon: 'tab-menu', badge: 0, permissionRequired: false },
    //{ url: 'ordertest-example', label: 'order-test', icon: '../../../assets/icon/tab-menu.svg', badge: 0 },
  ];
  public static dueDateName = 'Due Date';
  public static apptDateName = 'Appointment Date';
  public static maxOrderGroup = 50;
  public static completionCode: string[] = [
    '50 - Completed',
    '91 - Service Not Provided',
    '93 - Included With Another Service',
    '96 - Service Guarantee Follow-Up',
    '97 - Duplicate Order',
    '98 - Existing Loss Cost',
  ];

  public static orderQueue = 'ORDER_QUEUE';

  public static riskFormSwipeIconDetails = [
    { styleName: 'addIcon', iconPath: 'assets/icon/recommendation.png', className: 'background-grey' },
    { styleName: 'editIcon', iconPath: 'assets/icon/create.png', className: 'background-blue' },
    { styleName: 'commentIcon', iconPath: 'assets/icon/comment.png', className: 'background-bright-blue' },
    { styleName: 'cloneIcon', iconPath: 'assets/icon/comment-swiper-icon.png', className: 'background-blue' },
  ];

  public static readonly floorsAndRoofsConstructionAssembly = [
    { constructionType: 'Fire Resistive', levelType: 'Both', constructionAssembly: 'Reinforced Concrete' },
    { constructionType: 'Fire Resistive', levelType: 'Both', constructionAssembly: 'Protected Steel' },
    { constructionType: 'Fire Resistive', levelType: 'Both', constructionAssembly: 'Precast Concrete' },
    { constructionType: 'Fire Resistive', levelType: 'Both', constructionAssembly: 'Other Fire Resistive Assemblies' },
    {
      constructionType: 'Non-combustible/ Slow burn',
      levelType: 'ROOF',
      constructionAssembly: 'Metal Panels on Steel Supports',
    },
    {
      constructionType: 'Non-combustible/ Slow burn',
      levelType: 'ROOF',
      constructionAssembly: 'Built-up w/o gravel on Metal Deck on Steel Supports',
    },
    {
      constructionType: 'Non-combustible/ Slow burn',
      levelType: 'ROOF',
      constructionAssembly: 'Built-up w/gravel on Metal Deck on Steel Supports',
    },
    {
      constructionType: 'Non-combustible/ Slow burn',
      levelType: 'ROOF',
      constructionAssembly: 'Membrane on Metal Deck on Steel Supports',
    },
    {
      constructionType: 'Non-combustible/ Slow burn',
      levelType: 'FLOO',
      constructionAssembly: 'NC Decking on Steel Supports',
    },
    {
      constructionType: 'Non-combustible/ Slow burn',
      levelType: 'Both',
      constructionAssembly: 'Concrete on Steel Supports',
    },
    {
      constructionType: 'Non-combustible/ Slow burn',
      levelType: 'Both',
      constructionAssembly: 'Other Non-Combustible or Slow Burn Assemblies',
    },
    { constructionType: 'Combustible', levelType: 'ROOF', constructionAssembly: 'Wood Iron Clad (WIC)' },
    { constructionType: 'Combustible', levelType: 'ROOF', constructionAssembly: 'Built Up Roof on Wood Supports' },
    {
      constructionType: 'Combustible',
      levelType: 'ROOF',
      constructionAssembly: 'Shingles on Wood Decking on Wood Supports',
    },
    {
      constructionType: 'Combustible',
      levelType: 'ROOF',
      constructionAssembly: 'Clay Tile on Wood Decking on Wood Supports',
    },
    {
      constructionType: 'Combustible',
      levelType: 'ROOF',
      constructionAssembly: 'Slate on Wood Decking on Wood Supports',
    },
    {
      constructionType: 'Combustible',
      levelType: 'ROOF',
      constructionAssembly: 'Standing Seam Metal on Wood Supports',
    },
    { constructionType: 'Combustible', levelType: 'ROOF', constructionAssembly: 'Wood Shingle/Shake on Wood Supports' },
    { constructionType: 'Combustible', levelType: 'ROOF', constructionAssembly: 'Foam Sandwich Panels' },
    { constructionType: 'Combustible', levelType: 'ROOF', constructionAssembly: 'Structural Insulated Panels (SIPS)' },
    { constructionType: 'Combustible', levelType: 'ROOF', constructionAssembly: 'Fabric over structural frame' },
    {
      constructionType: 'Combustible',
      levelType: 'ROOF',
      constructionAssembly: 'Metal Panels on Steel Supports w/interior comb',
    },
    {
      constructionType: 'Combustible',
      levelType: 'ROOF',
      constructionAssembly: 'Metal Panels on Steel Supports w/foam insul.',
    },
    { constructionType: 'Combustible', levelType: 'Both', constructionAssembly: 'Comb Assemblies w/foam insul.' },
    { constructionType: 'Combustible', levelType: 'Both', constructionAssembly: 'Non-Comb Assemblies w/foam insul.' },
    { constructionType: 'Combustible', levelType: 'Both', constructionAssembly: 'Mass Timber' },
    { constructionType: 'Combustible', levelType: 'Both', constructionAssembly: 'Other Combustible Assemblies' },
    { constructionType: 'Combustible', levelType: 'FLOO', constructionAssembly: 'Comb decking on Wood Supports' },
    { constructionType: 'Combustible', levelType: 'FLOO', constructionAssembly: 'NC decking on Wood Supports' },
    { constructionType: 'Combustible', levelType: 'FLOO', constructionAssembly: 'Comb Decking on Metal Supports' },
  ];

  public static timeIn24HoursFormat(time) {
    let hours = time.substring(0, 2);
    const min = time.substring(3, 5);
    const amOrpm = time.split(' ')[1];
    if (amOrpm.toLowerCase() == 'pm' && hours != '12') {
      hours = 12 + Number(hours);
    }
    if (amOrpm.toLowerCase() == 'am' && hours == '12') {
      hours = 12 - Number(hours);
      hours = '0' + hours;
    }
    return hours + ':' + min;
  }

  public static timeIn12HoursFormat(time24) {
    let ts = time24;
    const H = +ts.substr(0, 2);
    let h = H % 12 || '12';
    h = +h < 10 ? '0' + h : h; // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? ' AM' : ' PM';
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }

  public static imageButtons = [
    { text: 'Camera', role: 'camera' },
    { text: 'Photo Library', role: 'photos' },
    { text: 'Cancel', role: 'cancel' },
  ];

  public static Events = {
    releaseTargetSuccess: 'RELEASE_TARGET_SUCCESS',
  };

  public static loginUserDetails: any;
  public static localTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
  public static phoneFormat = /^\(\d{3}\) \d{3}-\d{4}$/;
  public static emailFormat = /^[a-zA-Z0-9\-._!#$%&*+]{1,}@[a-zA-Z0-9\-_]{1,}\.[a-zA-Z]{1,}$/;
  public static zipcodeFormat = /^\d{5}(?:-\d{4})?$/;
  public static removeTarget = '/targets/remove';
  //public static getTargets = '/targets/orchestrator-search';
  public static getTargets = '/targets/target-risk-search'; //new api call to get risk data
  public static completeTarget = '/targets/complete';
  public static saveAppointment = '/Orders/save-appointment';
  public static saveInsured = '/Orders/save-insured';
  public static saveRequestor = '/Orders/save-requestor';
  public static saveProducer = '/Orders/save-producer';
  public static saveOrderInfo = '/Orders/save-order-info';
  public static saveInternalComments = '/Orders/save-internal-comments';
  public static savePropertyLocation = '/Orders/save-property-location';
  public static pdbValidator = '/Orders/validate-participation';
  public static getCrDocumentName = '/CustomerRequirement/document-name';
  public static saveCrDocumentAsPdfToS3 = '/CustomerRequirement/pdf';
  public static getL360Link = '/lc360/get-link';
  public static getCoreLogicValuationLink = '/corelogic/get-quicklink';
  public static getFederatedCoreLogicValuationLink = '/corelogic/get-federatedquicklink';
  public static validateCompleteTarget = '/targets/validate-complete';
  public static updateTargetStatusApi = '/targets/start-survey';
  public static lockOrderApi = '/orders/lock';
  public static getCurrencyRiskMetaData = '/risks/currency-info/';
  public static getCoverageRiskMetaData = '/targets/orchestrator-target-details/';
  public static getPerformanceSummary = '/icp/fr-performance-summary';
  public static getGeoCode = '/bins/geocode';
  public static releaseTarget = '/targets/release-target';
  public static IN_PROGRESS = 'InProgress';
  public static SUBMITTED = 'Submitted';
  public static CURRENCY_UPDATE = 'CurrencyUpdate';
  public static Coverage = 'Coverage';
  public static devicePortraitOrientation = 'portrait-primary';
  public static devicePortraitSecOrientation = 'portrait-secondary';
  public static deviceLandscapeOrientation = 'landscape-primary';
  public static deviceLandscapeSecOrientation = 'landscape-secondary';
  public static startSurveyLabel = 'Start Survey';
  public static resumeSurveyLabel = 'Resume Survey';
  public static deleteSuccessMsg = 'Target has been removed';
  public static delete500Err = 'An error has occurred. Please try again later.';
  public static removeTargetTitle = 'Reason for Removing Target?';
  public static deleteRiskTitle = `Are you sure you want to \n Delete Risk from Prometrix?\n If "YES", select reason below`;
  public static deletedRiskOnClickMsg =
    'This Risk has been marked for deletion and cannot be changed. If the Risk was deleted in error and changes are required, the Risk can be restored';
  public static nonTrackingDistance = 200;
  public static sprinkleredRiskCode = 'SPK_RSK';
  public static networkError =
    'You currently have no cellular service. Please use your WiFi or get into cellular range.';
  public static targetComplete = 'Are you sure you want to complete the target?';
  public static targetUpdated = 'Target was successfully updated';
  public static onNoWifiMsg =
    'You currently have no cellular service. Please use your WIFI or get into cellular range.';
  public static onTargetCompleteOn500Err = 'An error has occurred. Please try again later.';
  public static onTargetCompleteOn400Err =
    'This target is already in progress with another user. The target will be removed.';
  public static targetReleased = 'Target Successfully Released';
  public static OKTAPortalURL = 'https://verisk.okta.com/app/UserHome';
  public static ScopesManualURL =
    'https://verisk.sharepoint.com/sites/Compass/CommercialProperty/SCOPES_ONLINE/SCOPES/start_page.aspx#t=start_page_1.aspx ';
  public static FRManualURL =
    'https://verisk.sharepoint.com/sites/compass/CommercialProperty/FPRG/Production/index.aspx#t=01-Chapter_1-_Introduction_05.05.2021.aspx';
  public static yammerURL =
    'https://web.yammer.com/main/org/verisk.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI1MDg2MjAyIn0/new';
  public static ideasForImprovementURL =
    'https://forms.office.com/Pages/ResponsePage.aspx?id=H9wHO-ci4UusZqiL81UCIvyAJm2uFdNEn0n1nDlWg3hUM0xVOTFZMzRFQldDQVFNU0JMN1hLTlpXOC4u';
  public static technicalSupportURL =
    'https://teams.microsoft.com/l/entity/d4e9dfca-523a-4284-8a11-cc58ef115cb3/conversations';
  public static removeImageMsg = 'Are you sure you want to remove or replace this photo?';
  public static debugStorageKey = 'IS_CONSOLE_ENABLED';
  public static testUser = ['ISurveyTestUser2@test.com', 'ISurveyAccpUser@test.com'];
  public static pleaseWaitMessage = 'Please wait...';
  public static deleteOccupant = 'Are you sure you want to Delete this Occupant?';
  public static deleteFullriskOccupant = 'Please confirm whether you would like to delete this occupant';
  public static RISK_REPORT_WAIT_TIME = 8000;
  public static addTargetMode = 'Would you like to add a new target? Adjust the target for accurate placement.';
  public static NOT_AVAILABLE = 'Not Available';
  public static FILE_NOT_FOUND = 'File Not Found';
  public static reverseGeoCode = '/scrubber/address/reversegeocode';
  public static addressAutocomplete = '/scrubber/autocomplete';
  public static addressScrub = '/scrubber/address/scrub';
  public static addressParseOnly = '/scrubber/address/parseonly';
  public static calculateAPI = '/risks/calculate-full-risk';
  public static validateCompleteOrder = '/orders/validate-complete';
  public static completeOrder = '/orders/complete';
  public static fieldRework = '/orders/field-rework';
  public static processOrder = '/orders/qc-process';
  public static failureStatus = 'Failure';
  public static reWorkStatus = 'Rework';
  public static stageField = 'Field';
  public static completeMsg = 'Are you sure this order is complete and ready for delivery to the customer?';
  public static submittedMsg ='Order has been submitted';
  public static completeStatus: string = 'Complete';
  public static skipErrorAPIS = [
    Constants.completeTarget,
    Constants.saveAppointment,
    Constants.getCrDocumentName,
    Constants.saveCrDocumentAsPdfToS3,
    Constants.validateCompleteOrder,
    Constants.completeOrder,
    Constants.fieldRework,
    Constants.processOrder
  ];
  public static skipErrorCodes = ['TARGET_NOTFOUND'];
  public static getValuationID = '/valuation360/valuation-Id';
  public static getValuationLoginInfo = '/valuation360/login-info';
  public static generate360pdf = '/valuation360/generate-360pdf';
  public static deniedLocation =
    'Location Permission was previously denied. If you want to use Location services you will need to grant permission from the system settings page. Would you like to open the settings page now?';
  public static deniedCamera =
    'Camera Permission was previously denied. If you want to use Camera features you will need to grant permission from the system settings page. Would you like to open the settings page now?';
  public static removeSprinkler =
    'The Sprinkler Report and all associated entries will be removed from this risk form. Please Confirm.';
  public static removeWindReport =
    'The Wind Report and all associated entries will be removed from this risk form. Please Confirm.';
  public static Invalid_Template = 'Form Not Found';
  public static Invalid_Template_Dev = 'Form Not Found. Showing sample local form.';
  public static No_Network_Error_Message =
    'A network connection is not available. Please connect to a cell or wifi network and try again. ';
  public static User_Not_Found = "User can't be found";
  
  // Constants used for Form status
  public static New_Status = 'New';
  public static In_Progress_Status = 'InProgress';
  public static Completed_Status = 'Completed';
  public static Complete_Status = 'Complete';
  public static Validation_Issues_Status = 'ValidationIssues';
  public static Deleted_Status = 'Deleted';
  public static Add_Existing_Status = 'AddExisting';

  public static Completed_State = '3';
  public static New_State = '0';

  // Constants used for UW Form Model
  public static SystemServiceType = 'System';
  public static isDateInFuture = 'isDateInFuture';
  public static isDateInPast = 'isDateInPast';

  // Constants used for Risk Form
  public static readonly ShowBackendErrorCode = false;
  public static readonly Risk = {
    isMoreRFTestPageEnabled: true, // Enable access to risk form testing page from More tab
    debounceButtonDelay: 500,
  };
  public static btnText = 'Add'; //for all btn text label.
  public static sheetconfirmationMsg = 'The entries here have one or more items that will need to be addressed.';
  // Feature to allow disabling the Wind Report WR in Risk Form
  // Set to false to ALWAYS SHOW THE WIND REPORT
  public static isWindReportDisablingEnabled = true;

  public static RiskFeatures = new Map<string, boolean>([['isProMetrixV2Enabled', false]]);
  public static syncErrorMsg =
    'A sync failure has occurred. Please try again. If you continue to receive this message, please contact Help Desk.';
  public static addExistingRisk = '/orders/add-risk';
  public static riskPurged = 'This Risk has been purged and cannot be added to the order';
  public static s3UploadFail =
    'A sync error has occurred. Please try again. If this error continues, please contact Help Desk.';
  public static fileAttachmentFoundOnLocal =
    'The Attachment has not been downloaded for offline use. Please make sure you have a network connection and try again.';  
  public static unlockText = 'Unlock';
  public static lockText = 'Lock';
  public static InUse = 'In Use';
  public static New = 'New';
  public static lockConfirmationmsg = 'Your Order is Successfully Locked';
  public static unLockConfirmationmsg = 'Your Order is Successfully Unlocked';
  public static localSaveFail = 'Your Order is not successfully saved locally';
  public static inValidRisk = 'This Risk ID is not valid';
  public static inValidState = 'This risk is outside the state associated with this order and cannot be added';
  public static invalidBuildingName = 'Invalid entry in Building Name';
  public static invalidEntryValidator = 'Invalid entry';
  public static newBuildingDescription = 'New Risk';
  public static warning = 'Warning';
  public static warningMsgForRemove =
    'One or more of the photos in this batch is in the customer report or attached to a risk. Select "Remove" to proceed.';
  public static photosSelectOption = [
    { text: 'All Photos', role: 'all' },
    { text: 'Customer ', role: 'customer' },
    { text: 'Risk', role: 'risk' },
  ];
  public static noDataForTarget = 'No Data Found for Target';
  public static unlockedAndOfflineErrorMsg = 'The order needs to be locked before changes can be made. Locking can only happen while online. Return online before attempting changes.';
  public static onlyIntegersAllowed = 'Only integers allowed';
  public static multipleValidationsFailed = 'There are one or more validations in this section.  Please review. ';

  // Photos
  public static frontPhoto = 'FRNT';
  public static rearPhoto = 'REAR';
  public static roofPhoto = 'ROOF';
  public static emptyPhoto = '';
  public static frontPhotoIndex = 0;
  public static rearPhotoIndex = 1;
  public static roofPhotoIndex = 2;
  public static descriptionMaxLength = 112;
  public static fridMismatch = 'Order was assigned to some other FR.';
  public static noOrderFound = 'No order was found';
  public static saveFailed = 'Save failed';
  public static photoGroup = 'photo';
  public static removeRisk = 'Are you sure you want to remove this risk?';
  public static customerRequirementNotFoundOnServer =
    "The Customer Requirement for this customer was not found. Please contact the customer's Strategic Account Manager (SAM) if known or your manager if not known.";
  public static customerRequirementNotFoundOnLocal =
    'The Customer Requirement for this order has not been downloaded for offline use. Please make sure you have a network connection and try again.';
  public static customerRequirementInternalError =
    'An error has occurred and the Customer Requirement cannot be displayed. Please contact the Help Desk.';
    public static notAvailableUWForms =
    'Underwriting Forms are not available at this time.';
  
  //riskform autosave interval frequency
  public static riskFormAutoSaveFrequency = 30000;

  public static removeExposure = 'Are you sure you want to remove this exposure?';
  public static removeService =
    'Warning! Removing this service will also remove any associated forms and their progress.';
    public static removeForm =
    'Warning: You are deleting this form. Any progress will be lost.';
  public static AusumSurveyFormTitle = 'Ausum Survey Forms';
  public static LC360FormTitle = 'LC360';
  public static InspectionID = 'InspectionID';

  //Recommendation priority group
  public static priorityGroupKeys: string[] = ['High', 'Medium', 'Low'];
  public static priorityGroupValues: string[] = ['Critical', 'Important', 'Advisory'];
  public static recommendationPriorityText: string =
    "When filling out values, it’s best to follow the hierarchy of importance, 'Highest' being the most severe, 'Low' to the opposite.";
  public static pdfGenErrorMsg: string =
    'An error occurred while saving the 360Value report. Please try again. If the problem persists, contact the Help Desk. ';
  public static threeSixtyStructureType: string[] = ['Residential', 'Commercial', 'Agricultural'];
  public static threeSixtyService: string[] = ['0203', '0284'];
  public static coreLogicServiceCodes: string[] = ['0201', '0281', '0285', '0286'];
  // 'reassigned', 'cancelled',completed - Need to confirm
  public static inValidStatus: Array<string> =  ['reassigned','cancelled','complete','closed'];
  public static inValidLockingStatus: Array<string> = ['failure', 'rework','complete']
  public static changeThreeSixtyStructure: string =
    'Changing the structure type will cause you to lose any form changes made for this structure thus far. Do you want to make this change?';

// Constants used for Windform Notifications
public static eligibleWindform ='<b>Enhanced Wind Eligible &nbsp; </b>  Building is eligible for Enhanced Wind Rating. The Wind Report screens have been enabled in this Risk Form. Complete them accordingly.';
public static ineligibleWindform ='<b>Enhanced Wind Ineligible &nbsp; </b>   Wind Report screens have been disabled from this Risk Form.';
public static offlineTokenExpired = `You've been offline for over 24 hours, causing your access token to expire. Please reconnect online to refresh it.`;

//  Request data mail id section II Evaluation
public static requestMail = 'NPC-section2@verisk.com';

public static offlinePerformanceErr ='Performance information for additional quarters is not available while offline';

// Total area warning
public static areaSmallOrLargeError = 'The area entered is <500 sq ft or >= 1,000,000 sq ft. Do you wish to continue?';

public static refreshComments = '/orders/refresh-comments';
public static showErrorSheet = 'Reason For Failure';
public static showValidationSheet = 'Validations Errors Requiring Attention';
public static orderSyncedMsg = 'Order is synced from the server.'
}

export const FormType = {
  PackForm: 'PackForm',
  AusumForm: 'AusumForm',
  Lc360Form: 'Lc360Form',
  ThreeSixtyValuation: 'ThreeSixtyValuation',
  CoreLogicValuation: 'CoreLogicValuation',
};

export const RequestConsoleLogs: DebugConsoleLog[] = [];

export const ErrorMessagesByResponse = [
  // <array of error objects>
  { Code: 'FRD_REQUIRED', message: 'FRId is a required field' },
  { Code: 'ORCHID_REQUIRED', message: 'OrchId is a required field' },
  { Code: 'AMPLIFYID_REQUIRED', message: 'Amplify ID is a required field' },
  { Code: 'TARGET_INPROGRESS', message: 'Target has been started by another user' },
  { Code: 'TARGET_DELETEREASON_REQ', message: 'Delete Reason is Required.' },
  { Code: 'ORCH_UPDATESTATUS_FAILED', message: 'Orchestrator Update Failed' },
  { Code: 'TARGET_INVALID_STATE', message: 'Target status should be Inprogress' },
  { Code: 'TARGET_INVALID_FRID', message: 'Target status cannot be changed as it is InProgress with another FR' },
  { Code: 'TARGET_NOTFOUND', message: 'TargetId does not exist.' },
  { Code: 'TARGET_DELETEREASON_INVALID', message: 'Invalid DeleteReasonCode' },
  { Code: 'TARGET_ID_REQ', message: 'TargetID is Required' },
  { Code: 'RISK_NOT_FOUND', message: 'Risk does not exist' },
  { Code: 'BAD_REQUEST', message: 'Request is invalid' },
  { Code: 'AMPLIFYID_NOTFOUND', message: 'Amplify ID does not exist' },
  {
    Code: 'TARGET_SUP_PURGE',
    message: 'The risk for this target has been purged or suppressed. The target will be removed.',
  },
  { Code: 'AMPLIFY_UPDATESTATUS_FAILED', message: 'Amplify update status failed' },
  { Code: 'TARGET_REMOVESTATUS_FAILED', message: 'Target remove status failed' },
  { Code: 'SPK_RSK', message: 'This risk is Sprinklered. The target will be removed.' },
  { Code: 'S3FILE_NOTFOUND', message: Constants.syncErrorMsg },
  { Code: 'PHOTOS_NOTFOUND', message: Constants.syncErrorMsg },
  { Code: 'RISK_PURGED', message: Constants.riskPurged },
  { Code: 'S3_UPLOAD_FAILURE', message: Constants.s3UploadFail },
  { Code: 'FRID_MISMATCHED', message: Constants.fridMismatch },
  { Code: 'SAVE_FAILED', message: Constants.saveFailed },
  { Code: 'ORDER_NOTFOUND', message: Constants.noOrderFound },
  { Code: 'PDFGEN_ERROR', message: Constants.pdfGenErrorMsg },
  { Code: 'S3FILE_NOTFOUND', message: 'S3 File not found'},
  { Code: 'AMPLIFYID_NOTFOUND', message: 'Amplify Id not found'},
  { Code: 'RISKREPORT_NOT_FOUND', message: 'Risk Report was not found in S3 for order {orderNumber}'},
  { Code: 'ATTACHMENT_NOT_FOUND', message: 'Attachment was not found in S3 for order {orderNumber}'},
  { Code: 'PHOTO_NOT_FOUND', message: 'A photo was not found in S3 for order {orderNumber}'},
  { Code: 'CUSTOMERASSETS_NOT_FOUND', message: 'Customer Asset was not found in S3 for order {orderNumber}'},
  { Code: 'PACKFORM_NOT_FOUND', message:'A form was not found in S3 for order {orderNumber}'},
  { Code: 'FD_SAVE_FAILURE', message:'Saving order to FlightDeck failed'},
  { Code: 'FD_PROCESS_FAILURE', message:'A FlightDeck failure occurred processing the order to the next stage'},
  { Code: 'FAILURE', message:'Risk Reports on order {orderNumber} failed'},
  { Code: 'S3FILE_UPLOADFAILED', message:'{orderNumber} Re-upload failed'},
  { Code: 'INTERNAL_EXCEPTION', message:'An error occurred while processing this order'},
];


export const VALIDATION_MESSAGES = {
  APPOINTMENT_REQUIRED: 'An appointment date is required',
  RISK_FORMS_NOT_COMPLETED: 'One or more risk forms are not completed',
  UW_FORMS_NOT_COMPLETED: 'One or more underwriting forms are not completed',
  PHOTOS_NOT_COMPLETED: 'Photos are not completed',
  RECOMMENDATIONS_NOT_COMPLETED: 'One or more recommendations are not completed',
  ORDER_NOT_LOCKED: 'The order must be locked before order continuing to billing'
};

export const VALIDATION_FOR_BILLING = {
  INTERVIEWEE_REQUIRED: 'Interviewee is required',
  TITLE_REQUIRED: 'Interviewee title is required',
  PROPERTYOWNER_REQUIRED: 'Property owner is required',
  LOSS_OR_CLAIMS: 'A response is required for the insured having any losses or claims',
  LOSS_OR_CLAIMS_DESCRIPTION: 'A description is required for the insured having any losses or claims',
  AS_CREDIT: 'A response is required for the buildings qualification for AS credit',
  SEND_TO_CUSTOMER: 'A response is required to indicate if the risk should be delivered to the customer',
  TIMESPENT: 'A completion code and time spent is required',
};

