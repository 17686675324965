import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class FloorsAndRoofsBgiiData extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'bg2Data',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'BGII Data',
        isCollapsable: true,
        isCollapsed: true,
      },
      fieldGroupClassName: 'bgII-container',
      fieldGroup: [ 
        {
        fieldGroupClassName: 'wind-uplift',
        fieldGroup: [
          {
            key: 'isWindUplift90',
            type: 'custom-checkbox',  
            className: 'checkbox-full-width row-gap',
            props: {
              label: 'BGII wind uplift 90',
              isHorizontalOrientation: true,
              labelFirst: true
            },
          },
          {
            key: 'skylightsRecognition',
            type: 'rf-radio',
            defaultValue: 'NONE',
            className: 'skylight-style',
            props: {
              isHorizontalOrientation: true,
              slot: 'start',
              isCardEnabled: true,
              isLabelRight: true,
              options: [
                { label: 'No Skylights', value: 'NONE', disabled: false },
                { label: 'Skylights Recognized', value: 'RCGN', disabled: false },
                { label: 'Skylights Unrecognized', value: 'UNRC', disabled: false },
              ],
            },
            expressions: {
              'props.required': (field?: FormlyFieldConfig) => field?.form?.get('isWindUplift90')?.value ? true : false,
            },
            hooks: {
              onInit: (field?: FormlyFieldConfig) => {
                if (field.form?.get('isWindUplift90').value) {
                  field.hide = false;
                } else {
                  field.hide = true;
                }
                field.form?.get('isWindUplift90').valueChanges.subscribe(src => {
                  if (src) {
                    field.hide = false;
                    field?.form?.get('skylightsRecognition')?.setValue('NONE');
                  } else {
                    field?.form?.get('skylightsRecognition')?.setValue(null);
                    field.hide = true;
                  }
                })
              }
            },
          },
        ]
      },
      {
        key: 'isCC3',
        type: 'custom-checkbox',  
        className: 'checkbox-full-width card-view row-gap',
        props: {
          label: 'BGII CC3',
          isHorizontalOrientation: true,
          labelFirst: true
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            const constructionClass = field?.parent?.form?.value?.buildingInformation?.constructionClass;
            if (Number(constructionClass) === 1) {
              return false
            } else {
              return true
            }
          }
        },
      },
      {
        key: 'isCC4',
        type: 'custom-checkbox',  
        className: 'checkbox-full-width card-view row-gap',
        props: {
          label: 'BGII CC4',
          isHorizontalOrientation: true,
          labelFirst: true
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            const constructionClass = field?.parent?.form?.value?.buildingInformation?.constructionClass;
            if (Number(constructionClass) === 2) {
              return false
            } else {
              return true
            }
          }
        },
      },
      {
        key: 'isMixedLowHighRise',
        type: 'custom-checkbox',  
        className: 'checkbox-full-width card-view row-gap',
        props: {
          label: 'Mixed low / high rise',
          isHorizontalOrientation: true,
          labelFirst: true,
          isDisabled: false,
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            const floorArr = field?.parent?.form?.value?.constructionEntries?.floorsAndRoofsLevels?.filter(floor => Number(floor?.levelStart) > 5 || Number(floor?.levelStop) > 5
            )
            if (floorArr?.length>0) {
              return false
            } else {
              return true
            }
          },
          'props.isDisabled': (field: FormlyFieldConfig) => field?.parent?.form?.value?.buildingInformation?.aboveGradeFloors <= 5,
        },
      },
      {
        key: 'is22gaRoof',
        type: 'custom-checkbox',  
        className: 'checkbox-full-width card-view row-gap',
        props: {
          label: '22 gauge Roof',
          isHorizontalOrientation: true,
          labelFirst: true
        },
        
      },
      {
        key: 'isSteelLight',
      },
      {
        key: 'isSteelOtherThanLight',
      },
      {
        key: 'steelType',
        type: 'rf-radio',
        className: 'radio-label-width row-gap',
        props: {
          label: 'Steel type',
          isHorizontalOrientation: true,
          slot: 'start',
          isCardEnabled: true,
          isLabelRight: true,
          required: false,
          options: [
            { label: 'Steel Light', value: 'SL', key: 'isSteelLight' },
            { label: 'Steel other than light', value: 'SOTL', key: 'isSteelOtherThanLight' },
          ],
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            const floorArr = field?.parent?.form?.value?.constructionEntries?.floorsAndRoofsLevels?.filter(floor => floor?.bgiitype === 'STEE' || floor?.constructionTypeCode === 'NCSB'
            )
            if (floorArr?.length>0) {
              return false
            } else {
              return true
            }
          },
          'props.required': (field?: FormlyFieldConfig) => field?.parent?.form?.value?.constructionEntries?.floorsAndRoofsLevels?.some(floor => floor?.bgiitype === 'STEE' || floor?.constructionTypeCode === 'NCSB')
        },
        hooks: {
          onInit: (field?: FormlyFieldConfig) => {
            field.formControl?.valueChanges.subscribe(src => {
              if (src === 'SL') {
                field?.form?.get('isSteelLight')?.setValue(true);
                field?.form?.get('isSteelOtherThanLight')?.setValue(false);
              } else {
                field?.form?.get('isSteelLight')?.setValue(false);
                field?.form?.get('isSteelOtherThanLight')?.setValue(true);
              }
            })
          }
        }
      }
      ],
      hooks: {
        // onInit: (field?: FormlyFieldConfig) =>
        //   field?.formControl?.valueChanges.pipe(
        //     distinctUntilChanged(),
        //     tap((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_FLOORS_ROOFS_BGII_DATA, value, true))
        //   )
        onInit: (field: FormlyFieldConfig) => {
          field.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
          debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl?.pristine) {
              formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_FLOORS_ROOFS_BGII_DATA, value, true);
              }
            });
        },
        onDestroy: () => {
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
        }
      },
    });
}
}
