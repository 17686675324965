import { AbstractControl, FormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { numericInputValidator } from 'src/app/validators/formly-builder/numeric-input.validator';
import { ERROR_MSG_REQUIRED } from 'src/app/services/formly-builder/constants/general.constants';

export class MainDrainEstimationSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      type: 'main-drain-estimation',
      props: {
        buttonLabel: 'Calculate',
      },
      fieldGroup: [
        {
          props: {
            title: 'Sprinkler Report',
            subTitle: 'Manage Water Supply',
            sheetButtons: [
              {
                section: 'mainDrainEstimation',
                action: 'onCloseMainDrainEstimation',
                label: 'Close',
                service: 'mainDrainEstimationService',
                className: 'close-btn medium-btn',
                passInitialModel: true
              },
              {
                section: 'mainDrainEstimation',
                action: 'onApplyMainDrainEstimation',
                label: 'Apply',
                service: 'mainDrainEstimationService',
                className: 'apply-btn large-btn'
              }
            ],
          },
          fieldGroup: [
            {
              key: 'mainDrainEstimation',
              className: 'main-drain-estimation-container',
              wrappers: ['simple-section-wrapper', 'error-info-wrapper'],
              props: {
                showError: false,
                isShallowTitleRow: true,
                // hasTitleRow: false
                // label: 'Main Drain Estimation',
                // isCollapsable: false,
                // isCollapsed: false,
                // checkTouchedControl: true,
              },
              fieldGroup: [
                {
                  key: 'mainDrainEstimationTestDate',
                  type: 'rf-date-picker',
                  props: {
                    label: 'Main Drain Test Date',
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    displayFormat: 'MM/dd/yy',
                    datePickerId: 'mainDrainEstimationTestDate',
                    // required: true,
                    isAsteriskNotRequired: true
                  },
                  validators: {
                    validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }]
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => {
                      const active = field?.parent?.parent?.props?.active;
                      return field?.parent?.parent?.props?.active;
                    }
                  }
                },
                { template: '<div class="ws-title-style">Pressure Gauge Information</div>' },
                {
                  key: 'lowerGaugeStaticPressure',
                  type: 'simple-input',
                  props: {
                    label: 'Lower Gauge Static Pressure',
                    type: 'number',
                    // required: true,
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    unit: 'PSI',
                    maxLength: 10,
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  }                  
                },
                {
                  key: 'lowerGaugeResidualPressure',
                  type: 'simple-input',
                  props: {
                    label: 'Lower Gauge Residual Pressure',
                    type: 'number',
                    // required: true,
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    unit: 'PSI',
                    maxLength: 10,
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  }                  
                },
                {
                  key: 'isLowerGaugeOnTheMainDrainLine',
                  type: 'rf-yes-no',
                  defaultValue: null,
                  className: '',
                  props: {
                    label: 'Is lower gauge on the main drain line?',
                    isCardEnabled: true,
                    rightAlign: true,
                    //  required: true
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  }                  
                },
                { template: '<div class="ws-title-style">Elevation using Base of Riser as a reference</div>' },
                {
                  key: 'mainDrainConnectionElevation',
                  type: 'simple-input',
                  props: {
                    label: 'Main drain connection elevation',
                    // type: 'number',
                    // required: true,
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    unit: 'ft',
                    isDecimal: true,
                    minNumeric: 0.01,
                    maxNumeric: 200,
                    maxLength: 10,
                    type: 'decimal',
                    modelOptions: {
                      updateOn: 'blur'
                    },
                    change: (field: FieldTypeConfig) =>
                      formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2") 
                    // keypress: (field, event) => numericInputValidator(field, event)
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  },                  
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 0.01 and 200.'
                        },
                      },
                    ]
                  },
                },
                {
                  key: 'mainDrainDischargeOutElevation',
                  type: 'simple-input',
                  props: {
                    label: 'Main drain discharge elevation',
                    // type: 'number',
                    // required: true,
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    unit: 'ft',
                    isDecimal: true,
                    minNumeric: 0.01,
                    maxNumeric: 200,
                    maxLength: 10,
                    // keypress: (field, event) => numericInputValidator(field, event)
                    type: 'decimal',
                    modelOptions: {
                      updateOn: 'blur'
                    },
                    change: (field: FieldTypeConfig) =>
                      formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2") 
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 0.01 and 200'
                        },
                      },
                    ]
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  }                  
                },
                {
                  key: 'lowerGaugeElevation',
                  type: 'simple-input',
                  props: {
                    label: 'Lower gauge elevation',
                    // type: 'number',
                    // required: true,
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    unit: 'ft',
                    isDecimal: true,
                    // keypress: (field, event) => numericInputValidator(field, event),
                    minNumeric: 0.01,
                    maxNumeric: 200,
                    maxLength: 10,
                    type: 'decimal',
                    modelOptions: {
                      updateOn: 'blur'
                    },
                    change: (field: FieldTypeConfig) =>
                      formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2") 
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 0.01 and 200'
                        },
                      },
                    ]
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  }                  
                },
                { template: '<div class="ws-title-style">Pipe Sizes</div>' },
                {
                  key: 'diameterOfMainDrainLine',
                  type: 'simple-select',
                  className: 'main-drain-select-style',
                  props: {
                    label: 'Diameter of main drain line',
                    // required: true,
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    options: formlyBuilderService.mainDrainEstimationService.getMainDrainLineOptions(),
                    isAsteriskNotRequired: true,
                    unit: 'in.'
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  }                  
                },
                {
                  key: 'diameterOfRiser',
                  type: 'simple-select',
                  className: 'main-drain-select-style',
                  props: {
                    label: 'Diameter or riser pipe',
                    // required: true,
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    options: formlyBuilderService.mainDrainEstimationService.getRiserPipeOptions(),
                    isAsteriskNotRequired: true,
                    unit: 'in.'
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.active
                  }                  
                },
                { template: '<div class="ws-title-style">Main drain pipe & fittings (total number of fittings by type, and total length of pipe)</div>' },
                {
                  key: 'teeItemsInMainDrainLine',
                  type: 'simple-input',
                  props: {
                    label: 'Tee’s in main drain line (not including branching off riser)',
                    type: 'number',
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    minNumeric: 1,
                    maxNumeric: 20,
                    maxLength: 2
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 1 and 20. If none, leave blank'
                        },
                      },
                    ]
                  },
                },
                {
                  key: 'gateValvesInMainDrainLine',
                  type: 'simple-input',
                  props: {
                    label: 'Gate valves in main drain line',
                    type: 'number',
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    minNumeric: 1,
                    maxNumeric: 20,
                    maxLength: 2
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 1 and 20. If none, leave blank'
                        },
                      },
                    ]
                  },
                },
                {
                  key: 'angleValvesInMainDrainLine',
                  type: 'simple-input',
                  props: {
                    label: 'Angle valves in main drain line (flow turned 90º inside valve)',
                    type: 'number',
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    minNumeric: 1,
                    maxNumeric: 20,
                    maxLength: 2
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 1 and 20. If none, leave blank'
                        },
                      },
                    ]
                  },
                },
                {
                  key: 'NinetyElbowsInMainDrainLine',
                  type: 'simple-input',
                  props: {
                    label: '90º elbows in main drain line',
                    type: 'number',
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    minNumeric: 1,
                    maxNumeric: 20,
                    maxLength: 2
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 1 and 20. If none, leave blank'
                        },
                      },
                    ]
                  },
                },
                {
                  key: 'FourtyFiveElbowsInMainDrainLine',
                  type: 'simple-input',
                  props: {
                    label: '45º elbows in main drain line',
                    type: 'number',
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    minNumeric: 1,
                    maxNumeric: 20,
                    maxLength: 2
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 1 and 20. If none, leave blank'
                        },
                      },
                    ]
                  },
                },
                {
                  key: 'lengthOfMainDrainPiping',
                  type: 'simple-input',
                  props: {
                    label: 'Length of main drain piping (ft)',
                    type: 'number',
                    isCardEnabled: true,
                    isHorizontalOrientation: true,
                    rightAlign: true,
                    minNumeric: 1,
                    maxNumeric: 20,
                    maxLength: 2
                  },
                  validators: {
                    validation: [
                      {
                        name: 'numeric-min-max-validator',
                        options: {
                          msg: 'Must be a value between 1 and 20. If none, leave blank'
                        },
                      },
                    ]
                  },
                },
                { template: '<div class="ws-title-style">Calculation results effective at the base of the riser</div>' },
                {
                  type: 'summary-row',
                  fieldGroup: [
                    {
                      key: 'staticPressure',
                      defaultValue: undefined,
                      props: {
                        label: 'Static Pressure',
                      }
                    },
                    {
                      key: 'residualPressure',
                      defaultValue: undefined,
                      props: {
                        label: 'Residual Pressure',
                      }
                    },
                    {
                      key: 'flow',
                      defaultValue: undefined,
                      props: {
                        label: 'Flow'
                      },
                    }
                  ]
                },
                {
                  type: 'rf-image',
                  className: 'calculator-wrapper',
                  props: {
                    buttonLabel: 'Calculate!',
                    source: 'calculator.png',
                    action: (field: FormlyFieldConfig) => formlyBuilderService.mainDrainEstimationService.estimate(field)
                  }
                },
              ]              
            },
            {
              wrappers: ['simple-section-wrapper'],
              props: {
                showError: false,
                hasTitleRow: false
              },
              className: 'main-drain-estimation-riser-wrapper',
              template: '<div><div class="ws-title-style">Riser Diagram</div><img src="../../../../assets/img/riser.png"><div>'
            }
          ]          
        },
      ],
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          const ss1 = field?.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1;
          return !(ss1 === "Main Drain Estimation");
          // return false
        }
      }
    },
    );
  }
}
