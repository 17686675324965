/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { EnhancedWindDataModel } from 'src/app/models/formly-builder/formly/wind/enhanced-wind-data.model';
import { FormlyBuilderService } from '../formly-builder.service';
import { SectionEnum } from '../section.config';

@Injectable({
  providedIn: 'root',
})
export class EnhancedWindService extends SectionServiceAbstract {

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged: boolean = EnhancedWindDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const enhancedWindModel =
        EnhancedWindDataModel.to(model[ 'windReport' ].enhancedWindRatingEligibility, riskReportData?.ReportIdentifier, model);
      const windReportData = {
        ...riskReportData.WindReport,
        ...{
          EnhancedWindRatingEligibility: enhancedWindModel?.enhancedWindRatingEligibility,
          SharedWindCharacteristicsRisks: enhancedWindModel?.sharedWindCharacteristicsRisks
        }
      };
      return { ...riskReportData, ...{ WindReport: windReportData } };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], riskReport: any, isDataInS3Format: boolean, model: any) {
    return model;
  }
  async onApply(modal: ModalController, field: FormlyFieldConfig) {
    field.form.markAllAsTouched();
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_W_ENHANCED_WIND, false);
    const newModel = formlyService.windReportService.loadData(riskReport, riskReport, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    modal.dismiss();
  }
  onClose(modal: ModalController, field: FormlyFieldConfig, initialDataModel: any) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.windReportService.loadData(reportData, reportData, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    modal.dismiss();
  }
  initCheckboxes(field: FormlyFieldConfig, checkboxOptions: any[]) {
    try {
      // Get values from the options...
      const rootModel = field?.options?.formState?.service?.riskReport?.model;
      let checkboxGroupModel = rootModel;
      const branchArray = field?.props?.path.split('.');
      branchArray.forEach((branch) => {
        checkboxGroupModel = checkboxGroupModel[ branch ];
      });

      checkboxOptions.forEach((option) => {
        if (checkboxGroupModel) {
          if (checkboxGroupModel[ option[ 'checkboxKey' ] ] === true) {
            option[ 'isItemChecked' ] = true;
          }

          if (checkboxGroupModel[ option[ 'predominantKey' ] ] === true) {
            option[ 'isPredominant' ] = true;
          }
        }
      });
    } catch (ignore) { }

    // Load the options as props
    field.props.options = checkboxOptions;
  }
  public isRoofExposureHazardByOtherRisksClear(field: FormlyFieldConfig) {
    // Clear fields if Communication is toggled off
    field?.formControl?.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => value === false)).subscribe(value => {
        // Clear all communications values
        try {
          if (field?.model?.isRoofExposureHazardParapetFlashingCondition) {
            field.model.isRoofExposureHazardParapetFlashingCondition = false;
          }
          if (field?.model?.isRoofExposureHazardEquipmentUnsecured) {
            field.model.isRoofExposureHazardEquipmentUnsecured = false;
          }
          if (field?.model?.isRoofExposureHazardLightlyConstructedRoofOrPenthouse) {
            field.model.isRoofExposureHazardLightlyConstructedRoofOrPenthouse = false;
          }
          if (field?.model?.isRoofExposureHazardRoofCoverDegradation) {
            field.model.isRoofExposureHazardRoofCoverDegradation = false;
          }
          if (field?.model?.isRoofExposureHazardAntennasOrLights) {
            field.model.isRoofExposureHazardAntennasOrLights = false;
          }
        } catch (error) { console.error(error); }
      });
  }
  public isWallExposureHazardByOtherRisksClear(field: FormlyFieldConfig) {
    // Clear fields if Communication is toggled off
    field?.formControl?.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => value === false)).subscribe(value => {
        // Clear all communications values
        try {
          field.props.showError = true;
          if (field?.model?.isWallExposureHazardEIFSOnMasonry) {
            field.model.isWallExposureHazardEIFSOnMasonry = false;
          }
          if (field?.model?.isWallExposureHazardCommonCanopy) {
            field.model.isWallExposureHazardCommonCanopy = false;
          }
          if (field?.model?.isWallExposureHazardArchitecturalAppurtenances) {
            field.model.isWallExposureHazardArchitecturalAppurtenances = false;
          }
        } catch (error) { console.error(error); }
      });
  }
  public isPotentialExposureCreditApplicableClear(field: FormlyFieldConfig) {
    // Clear fields if Communication is toggled off
    field?.formControl?.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => value === false)).subscribe(value => {
        // Clear all communications values
        try {
          if (field?.model?.isPotentialExposureCreditCanopiesCircumventParapetedFireWalls) {
            field.model.isPotentialExposureCreditCanopiesCircumventParapetedFireWalls = false;
          }
          if (field?.model?.isPotentialExposureCreditOversizedPassageways) {
            field.model.isPotentialExposureCreditOversizedPassageways = false;
          }
          if (field?.model?.isPotentialExposureCreditLargeAtrium) {
            field.model.isPotentialExposureCreditLargeAtrium = false;
          }
          if (field?.model?.isPotentialExposureCreditAbovegroundGarage) {
            field.model.isPotentialExposureCreditAbovegroundGarage = false;
          }
          if (field?.model?.isPotentialExposureCreditUndergroundGarage) {
            field.model.isPotentialExposureCreditUndergroundGarage = false;
          }
        } catch (error) { console.error(error); }
      });
  }
  public isroofObservableDuringSurveyClear(field: FormlyFieldConfig) {
    // Clear fields if Communication is toggled off
    field?.formControl?.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => value === false)).subscribe(value => {
        // Clear all communications values
        try {
          if (field?.model?.isRoofObservablePhysically) {
            field.model.isRoofObservablePhysically = false;
          }
          if (field?.model?.isRoofObservableGradeLevel) {
            field.model.isRoofObservableGradeLevel = false;
          }
          if (field?.model?.isRoofObservableFromLadder) {
            field.model.isRoofObservableFromLadder = false;
          }
          if (field?.model?.isRoofObservableAdjacentVantagePoint) {
            field.model.isRoofObservableAdjacentVantagePoint = false;
          }
          if (field?.model?.isRoofObservableViewingDevice) {
            field.model.isRoofObservableViewingDevice = false;
          }
          if (field?.model?.isRoofObservableOther) {
            field.model.isRoofObservableOther = false;
          }
        } catch (ignore) { }
      });
  }

}
