import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserRolesService {
  private checkUserRole$ = new BehaviorSubject<string[]>([]);
  checkUserRoleObservable = this.checkUserRole$.asObservable();
  constructor(private http: HttpClient ) {}

  getUserPermissions() {
    return this.http.get<any>('assets/json/user-roles.json').toPromise();
  }

  async checkUserRoles(frId: string) {
    let shouldBeDisabled = true;
    const users = await this.getUserPermissions();

    if (users[frId]?.permissions) {
      console.log('in if');
      this.checkUserRole$.next(users[frId]?.permissions);
    } else {
      console.log('in else');
      this.checkUserRole$.next([]);
    }
  }
}
