import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Guid } from 'guid-typescript';
import { Constants } from 'src/app/services/util-service/constants';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { RiskAttachmentService } from 'src/app/services/formly-builder/risk-attachment.service';
import { Directory, Encoding, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { ImageModalPageComponent } from '../../templates/formly-section-photos/imagemodal/image-modal-page/image-modal-page.component';
import { PdfPreviewComponent } from '../pdf-preview/pdf-preview.component';
import { Share, ShareResult } from '@capacitor/share';
import { AttachmentDataModel } from 'src/app/models/formly-builder/formly/full-risk/attachment-data.model';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-risk-attachment',
  templateUrl: './risk-attachment.component.html',
  styleUrls: ['./risk-attachment.component.scss'],
})
export class RiskAttachmentComponent implements OnInit {
  @Input() fileData;
  @Input() field;
  uploadedFileData: any;
  selectedFileName: string;
  extension: string;
  selectedFileData: string;
  attachType: string = 'General';
  description: string = '';
  Id: number;
  isEdit: boolean = false;
  riskAttachmentType: string[] = Constants.RiskAttachTypes;
  isValidFileExtension: boolean = true;
  assetsType: string;
  isvalueChanged: boolean = true;
  isAttachmentAdded: boolean = true;
  pdfSrc: string;
  txtSrc: string;
  isDuplicate: boolean = false;
  sheetPreview: boolean = false;
  downloadUrl: string;
  public isUserDeveloper = false;
  public isSprinklerAttachment = false;

  constructor(
    private modalController: ModalController,
    private riskAttachmentService: RiskAttachmentService,
    public platform: Platform,
    public photoViewer: PhotoViewer,     
    public ordersService: OrdersService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.assetsType = this.fileData.assets;
    if (this.fileData.isEdit) {
      this.isEdit = true;
    }

    if (this.fileData.isSprinklerAttachment === true) {
      this.isSprinklerAttachment = true;
    }

    if (this.fileData?.selectedfiles?.assetData?.fileData) {
      // Return from gallery image picker
      this.selectedFileData = this.fileData.selectedfiles.assetData.fileData;
      this.selectedFileName = this.fileData.selectedfiles.assetData.fileName;
      this.attachType = this.fileData.selectedfiles.assetData.attachType;
      this.description = this.fileData.selectedfiles.assetData.description;
      this.Id = this.fileData.selectedfiles.Id;
    } else if (this.fileData?.selectedfiles?.files?.length) {
      // Return from File Picker
      this.selectedFileData = this.fileData.selectedfiles.files[0];
      this.selectedFileName = this.fileData.selectedfiles.files[0].name;
      this.description = this.selectedFileName;
      this.isvalueChanged = false;
      this.isAttachmentAdded = false;
    }
    
    this.isDuplicate = !this.isEdit && this.isFileExist() ? true : false;
    this.extension = this.selectedFileName?.split('.')?.pop()?.toLowerCase();    
    this.isValidFile(this.selectedFileName);

    // If PDF
    if (this.sheetPreview) {
      if (this.extension === 'pdf') {
        this.pdfSrc = RiskAttachmentService.base64ToArrayBuffer(this.selectedFileData);      
      } else if (this.extension === 'pdf' ||
        this.extension === 'txt' ||
        this.extension === 'csv') {
        this.txtSrc = this.selectedFileData;
      }
    }

    if (this.field?.options?.formState?.service) {
      this.isUserDeveloper = this.field.options.formState.service.isUserDeveloper;
    }    

    // Download file url
    const reportIdentifier = this.fileData.reportIdentifier;
    const selectedOrderNumber = this.fileData.orderNumber;    
    const path = `Order/${selectedOrderNumber}/riskreport/${reportIdentifier}/${this.getFolderName()}/${this.selectedFileName}`;
    Filesystem.getUri({ directory: Directory.Documents, path }).then((finalFileUri) => {
      let fileLink = Capacitor.convertFileSrc(finalFileUri.uri);
      this.downloadUrl = "file://" + path;
      this.downloadUrl = fileLink;
    });
  }

  private getFolderName() {
    if (this.isSprinklerAttachment) {
      return 'sprinklerattachment'
    }
    return 'riskreportattachments'
  }

  onSwipeDown(event) {
    this.onClose();
  }

  async onClose() {
    this.modalController.dismiss();
  }

  async addAttachment() {
    let result: any = await this.riskAttachmentService.pickFile();
    this.uploadedFileData = result.files[0];
    this.selectedFileName = this.uploadedFileData.name;
    this.isValidFile(this.selectedFileName);
    this.isAttachmentAdded = false;
  }

  async removeFile() {
    if (this.isAttachmentAdded) {
      if (this.field) {
        // Remove attachment
        const attachment = new AttachmentDataModel;
        attachment.reportIdentifier = this.fileData.reportIdentifier;
        attachment.reportAttachmentIdentifier = this.fileData.selectedfiles.Id;
        attachment.fileName = this.fileData.selectedfiles.assetData.fileName;
        attachment.attachmentDescription = null;
        const res = await this.riskAttachmentService.saveRiskAttachment(
          this.field,
          attachment,
          null,                     // data
          true,
          false);                    // isDelete
      }
    }
    this.selectedFileName = null;
    this.uploadedFileData = null;
    this.selectedFileData = null;

    this.onClose();
  }

  onApply() {
    let data = {
      attachData: {
        fileName: this.selectedFileName,
        attachType: this.attachType,
        fileDescription: this.description ? this.description : this.selectedFileName,
      },
      fileData: { data: this.selectedFileData ? this.selectedFileData : this.uploadedFileData },
      isEdit: this.isEdit,
      Id: this.Id ? this.Id : Guid.create().toString()
    };
    return this.modalController.dismiss(data);
  }

  isValid() {
    if (this.description.length && this.attachType && this.selectedFileName && this.isValidFileExtension && !this.isDuplicate) {
      return true;
    } else {
      return false;
    }
  }

  isValidFile(fileName) {
    let extension = fileName?.split('.')?.pop();
    this.isValidFileExtension = Constants.riskAttachmentValidFileExtension.find((element) => element === extension)
      ? true
      : false;
  }

  onInputChange(newValue: string) {
    this.isvalueChanged = false;
  }

  public isFileExist(): boolean {
    // Check duplicates
    const attachments = this.field?.options?.formState?.service?.riskReport?.model?.attachments;
    if (attachments) {
      const duplicates = attachments.filter((attachment) => attachment.fileName === this.selectedFileName);
      if (duplicates?.length) {
        return true;
      }
    }

    return false;
  }

  /**
   * openPreview: Expand the Photo to view in full screen
   *
   * @param img
   * @param type
   */
  async openPreview(fileData) {   
    let modal;
    const reportIdentifier = this.fileData.reportIdentifier;
    const selectedOrderNumber = this.fileData.orderNumber;
    const path = `Order/${selectedOrderNumber}/riskreport/${reportIdentifier}/${this.getFolderName()}/${this.selectedFileName}`;
    
    if (this.extension.toLowerCase() === 'jpeg' || this.extension.toLowerCase() === 'jpg') {
      // Show Image preview
      const img = "data:image/jpeg;base64," + fileData;
      modal = await this.modalCtrl.create({
        component: ImageModalPageComponent,
        cssClass: 'image-preview-modal',
        componentProps: {
          img,
        },
      });     
    } else if (this.extension.toLowerCase() === 'pdf' ||
      this.extension.toLowerCase() === 'doc' || this.extension.toLowerCase() === 'docx') {
      modal = await this.modalCtrl.create({
        component: PdfPreviewComponent,
        cssClass: 'image-preview-modal',
        componentProps: {
          path: path,
          data: fileData,
          extension: this.extension
        },
      });    
    } else if (this.extension.toLowerCase() === 'txt' ||
      this.extension.toLowerCase() === 'csv') {
      modal = await this.modalCtrl.create({
        component: PdfPreviewComponent,
        cssClass: 'image-preview-modal',
        componentProps: {
          path: path,
          data: atob(fileData),
          extension: this.extension
        },
      });
    }
    modal.present();      
  }   

  public async openShare($event) {
    const reportIdentifier = this.fileData.reportIdentifier;
    const selectedOrderNumber = this.fileData.orderNumber;

    // Original file in base64 format
    const path = `Order/${selectedOrderNumber}/riskreport/${reportIdentifier}/${this.getFolderName()}/${this.selectedFileName}`;

    // Get filesystem access
    const allowed = await this.getFilesystemAccess();
    if (allowed) {
      const finalFileUri = await Filesystem.getUri({ directory: Directory.Documents, path });
      const shareOptions = {
        title: this.selectedFileName,
        url: finalFileUri.uri
      };

      try {
        const res: ShareResult = await Share.share(shareOptions);
        console.debug("Attachment shared " + res.activityType);
      } catch (error) {
        console.error("Attachment share " + error.message);
      } 
    }     
  }

  async openDownload($event) {
    try {
      const allowed = await this.getFilesystemAccess();
      if (allowed) {       
        const reportIdentifier = this.fileData.reportIdentifier;
        const selectedOrderNumber = this.fileData.orderNumber;
        const path = `Order/${selectedOrderNumber}/riskreport/${reportIdentifier}/download/${this.selectedFileName}`;
        const finalFileUri = await Filesystem.getUri({ directory: Directory.Documents, path });        
        
        // Write without Encoding.UTF8 means it will be written as binary file
        // const res: WriteFileResult = await Filesystem.writeFile({
        //   path: path,
        //   data: this.fileData,
        //   directory: Directory.Documents,
        //   recursive: true,          
        //   // encoding: Encoding.UTF8,       // Don't specify encoding to save a binary file!
        // });
        
        Browser.open({ url: finalFileUri.uri });
        console.debug("Attachment downloaded " + finalFileUri.uri);
      }
    } catch (error) {
      console.error("Attachment download "+error.message);
    }
  }

  getFilesystemAccess(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const status = await Filesystem.checkPermissions()
      const state = status.publicStorage

      if (state === 'granted') {
        return resolve(true)
      } else if (state === 'denied') {
        // You make want to redirect to the main app settings.
      } else {
        Filesystem.requestPermissions()
      }
      return resolve(false)
    })
  }  
}
