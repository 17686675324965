import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import moment from 'moment';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FirePumpTestsSectionModel } from './fire-pump-tests.model';

export class FirePumpTestingSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'firePumpValveTesting',
      wrappers: ['simple-section-wrapper'],
      className: 'dry-pipe-valve-test-container fire-pump-testing-container',
      props: {
        className: 'padding-bottom-20 padding-right-20',
        label: 'Fire Pump Testing',
        isCollapsable: true,
        isCollapsed: true,
        hideFirePumpTestValve: false,
        checkTouchedControl: true,
        shallowErrors: true,
        isFromSystemTesting: true,
      },
      expressions: {
        'props.isCollapsed': (field: FormlyFieldConfig) => field?.props?.hideFirePumpTestValve ,
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => field?.props?.hideFirePumpTestValve
      },
      
      fieldGroup: [   
        {
          fieldGroup: [
            {
              key: 'firePumpTestDate',
              type: 'rf-date-picker',
              wrappers: ['question-wrapper'],
              className: 'date-width padding-right-0 dry-pipe-valve-testing dry-pipe-section',
              props: {
                label: 'Fire Pump Test Date',
                displayFormat: 'MM/dd/yy',
                datePickerId: 'firePumpTestDate',
                firstColSize: 8,
                required: false,
                isSectionLabelNormal: true,
                readonly:false,
                showError: false,
                isTitleDisableable:false,
                isAsteriskNotRequired: true,
              },
              expressions:{
                 'props.readonly': (field: FormlyFieldConfig) => {
                  const root = field?.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting;
                  const isEvaluatedThroughBypassOnly =
                    field.form.root.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
                  return (root?.hasFirePumpTest || isEvaluatedThroughBypassOnly) ? true : false;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  const root = field?.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting;
                  const isEvaluatedThroughBypassOnly =
                    field.form.root.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
                  return !(root?.hasFirePumpTest || isEvaluatedThroughBypassOnly);
                }
              },
               validators: {
                 validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }],
                compareWithSurveyDate: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      try {
                        let surveyDate = field?.form?.root?.get('survey')?.value?.surveyDate;
                        if (surveyDate && control.value) {
                          surveyDate = moment(surveyDate);
                          const testDate = moment(control.value);
                          const diff = testDate.diff(surveyDate, 'days');
                          return !(diff && diff > 0);
                        }
                      }
                      catch (e) {
                        console.log(e);
                      }
                      return true;
                    },
                    message: 'cannot be after survey date.'
                  }
              },
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  if(field.formControl?.value){
                    field?.form?.get('hasFirePumpTest')?.setValue(false);
                  // }else if(field?.form?.get('hasFirePumpTest')?.value === false){
                  //   formlyBuilderService.systemTestingService.checkFirePumpTestDate(field);
                  }
                }
              },
            },
            {
              key: 'firePumpDateAfterSurvey',
              type: 'rf-date-picker',
              wrappers: ['question-wrapper'],
              className: 'date-width padding-right-0 dry-pipe-valve-testing dry-pipe-section',
              resetOnHide: true,
              props: {
                label: 'Fire Pump Test After Survey Date',
                displayFormat: 'MM/dd/yy',
                datePickerId: 'firePumpDateAfterSurvey',
                isSectionLabelNormal: true,
                firstColSize: 8,
                required: false,
                showError: false,
                isAsteriskNotRequired: true,
              },
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  if(field.formControl?.value){
                    field?.form?.get('hasFirePumpTest')?.setValue(false);
                  }
                  field.formControl?.valueChanges?.subscribe((value)=> field?.form?.get('firePumpTestDate')?.updateValueAndValidity());
                }
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.parent?.parent?.parent?.model?.systemTesting?.hasScheduledAppliedDate,
                'props.required': (field: FormlyFieldConfig) => {
                  const asgrSprinklerSystemTest = field?.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest;
                  const hasScheduledAppliedDate = asgrSprinklerSystemTest?.systemTesting?.hasScheduledAppliedDate;
                  const hasFirePumpTest = asgrSprinklerSystemTest?.firePumpValveTesting?.hasFirePumpTest;
                  const firePumpTestDate = asgrSprinklerSystemTest?.firePumpValveTesting?.firePumpTestDate;
                  const isEvaluatedThroughBypassOnly =
                    field.form.root.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
                  return hasScheduledAppliedDate ? !(hasFirePumpTest || isEvaluatedThroughBypassOnly || firePumpTestDate ||
                    asgrSprinklerSystemTest.isFirePumpTestNotApplicable) : false;
                },
                'props.readonly': (field: FormlyFieldConfig) => {
                  const root = field?.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting;
                  const isEvaluatedThroughBypassOnly =
                    field.form.root.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
                  return (root?.hasFirePumpTest || isEvaluatedThroughBypassOnly) ? true : false;
                },
              },
              validators: {
                validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }],
              },
            },
            {
              key: 'hasFirePumpTest',
              type: 'custom-checkbox',
              wrappers: ['question-wrapper'],
              className: 'wrapper-title simple-checkbox dry-pipe-section',
              props: {
                label: 'No Fire Pump Test Available',
                isCardEnabled: false,
                labelFirst: true,
                rightAlign: true,
                showError: false,
                firstColSize: 8,
                isSectionLabelNormal: true,
                isLabelDisabled:true,
                isTitleDisableable:false,
                required: false,
                isAsteriskNotRequired: true,
              },
              expressions:{
                'props.isDisabled': (field: FormlyFieldConfig) => {
                  const root = field?.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting;
                  const isEvaluatedThroughBypassOnly =
                    field.form.root.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
                  return (root?.firePumpTestDate || root?.firePumpDateAfterSurvey || isEvaluatedThroughBypassOnly) ? true : false;
                },
                'props.required': (field: FormlyFieldConfig) => {
                  const root = field?.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting;
                  const isEvaluatedThroughBypassOnly =
                    field.form.root.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
                  return !(root?.firePumpTestDate || root?.firePumpDateAfterSurvey || isEvaluatedThroughBypassOnly) ? true : false;
                }
              }
            },
            FirePumpTestsSectionModel.build(formlyBuilderService).fields
          ],
          expressions: {
            hide: (field: FormlyFieldConfig) => formlyBuilderService.systemTestingService.checkFirePumpSystemEntry(field)
          },
          validators: {
            firePumpTestValidator: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                if((field.form?.value?.firePumpDateAfterSurvey || field.form?.value?.firePumpTestDate) && 
                  (!field.form?.value?.firePumpTests?.length) && !field.hide) {
                  field.form?.get('firePumpTests')?.setErrors({ 'fire-pump-test-validator': { message: 'If Fire Pump Test Date is entered, then a Fire Pump Test entry is required.' } });
                } else {
                  field.form?.get('firePumpTests')?.setErrors(null);
                }
                return true;
              },
            }
          }
        }
      ],
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          formlyBuilderService.systemTestingService.checkFirePumpSystemEntry(field);
        }
      },
    });
  }
}