/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable jsdoc/newline-after-description */
/* eslint-disable no-trailing-spaces */
import { Injectable, Injector } from '@angular/core';
import { Storage as AmplifyStorage, DataStore } from 'aws-amplify';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AddressAliasDataModel } from 'src/app/models/formly-builder/formly/address-alias-data.model';
import { WallsDataModel } from 'src/app/models/formly-builder/formly/walls-data.model';
import { OccupantsDataModel } from 'src/app/models/formly-builder/formly/occupants-data.model';
import { RiskReport, Target } from 'src/models';
import { FloorsAndRoofDataModel } from 'src/app/models/formly-builder/formly/floors-roof-data.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/util-service/util.service';
import { SectionConfig, SectionEnum } from './section.config';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { S3SyncService } from '../s3-sync-service/s3-sync.service';
import { Storage as IonicLocalStorage } from '@ionic/storage-angular';
import { AmplifyService } from 'src/app/services/amplify/amplify.service';
import { CapacitorHttpService } from 'src/app/auth/cap-http.service';
import { LngLatLike } from 'mapbox-gl';
import { Guid } from 'guid-typescript';
import { OrderModel } from 'src/app/models/order/order.model';
import {
  FloorsAndRoofModel,
  OccupantModel,
  ReportPointModel,
  S3RiskReportModel,
  WallModel,
} from 'src/app/models/risk-report/s3-risk-report.model';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { cloneDeep } from 'lodash';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { VeriskLog } from '../util-service/verisk-log';
import { SectionConfigInterface } from 'src/app/interfaces/formly-builder/section-config.interface';
import { FormlyErrorService } from './formly-error.service';
import { OrdersService } from '../orders/orders.service';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { Constants } from '../util-service/constants';
import { RiskUIStatus } from 'src/app/enums/formly-builder/risk-ui-status.enum';
import { SurveyDetailsDataModel } from 'src/app/models/formly-builder/formly/survey-details-data.model';

@Injectable({
  providedIn: 'root',
})
export class RiskReportService {
  private riskModel$;
  private riskModel = new BehaviorSubject<any>({});
  private riskReport$;
  private riskReport = new BehaviorSubject<any>({});
  public riskId: any = null;
  private isAcceptanceEnvironment = false;

  // S3 Sync
  private subscription$;
  public amplifyRiskReports: RiskReport[]; //Local DB RiskReport List
  public syncInProgess = false;
  public syncQueue;
  private dataType = 'RiskReport';

  // TODO: Remove. Only for Development and testing
  private cachedReportLength = 0;
  private cachedReportCurrentIndex = -1;
  private cachedReports: any[] = [];
  public test = false;
  public static riskStatus: RiskUIStatus;

  // Convert DataStore query Promise to Rxjs Observable
  constructor(
    private http: HttpClient,
    private injector: Injector,
    private amplify: AmplifyService,
    public s3Sync: S3SyncService,
    private ionicStorage: IonicLocalStorage,
    private utilService: UtilService,
    private capacitorHttpService: CapacitorHttpService,
    private localStorageService: LocalStorageService,
    private errorService: FormlyErrorService,
    private ordersService: OrdersService
  ) {
    this.initObservables();

    if (environment.iSurveyAPIurl.includes('accept')) {
      this.isAcceptanceEnvironment = true;
    }
  }

  private initObservables() {
    this.riskModel = new BehaviorSubject<any>({});
    this.riskReport = new BehaviorSubject<any>({});
    this.riskModel$ = this.riskModel.asObservable();
    this.riskReport$ = this.riskReport.asObservable();
  }

  public get model(): any {
    return this.riskModel.getValue();
  }

  public set model(val: any) {
    this.riskModel.next(val);
  }

  public get model$(): Observable<any> {
    return this.riskModel$;
  }

  public get report(): any {
    return this.riskReport.getValue();
  }

  public set report(val: any) {
    this.riskReport.next(val);
  }

  public get report$(): Observable<any> {
    return this.riskReport$;
  }

  public get riskUIStatus(): RiskUIStatus {
    return RiskReportService.riskStatus;
  }

  public set riskUIStatus(status: RiskUIStatus) {
    RiskReportService.riskStatus = status;
  }

  clearModel() {
    // this.riskReport.complete();
    // this.riskModel.complete();
    this.model = {};
    this.report = {};
  }

  cleanSubscriptions() {
    // Iterate through all sections and call the clean method
    for (const sectionData of SectionConfig) {
      // SectionConfig.forEach((sectionData) => {
      if (sectionData[ 1 ].service) {
        const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(
          sectionData[ 1 ].service
        );
        processInstance.clean();
      }
    }
  }

  initSubscriptions() {
    // Iterate through all sections and call the clean method
    for (const sectionData of SectionConfig) {
      // SectionConfig.forEach((sectionData) => {
      if (sectionData[ 1 ].service) {
        const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(
          sectionData[ 1 ].service
        );
        processInstance.initSubscription();
      }
    }
  }

  /**
   * If a risk id is provided, fetch the data from DB and show it.
   * Else, retrieve the latest target and fetch its data to show.
   *
   * @param riskId
   */
  async getModel(riskId: string) {
    if (riskId) {
      this.riskId = riskId;
      await this.fillModel(riskId);
    } else {
      const allTargets: any[] = await DataStore.query(Target);
      if (allTargets) {
        // Get the last value = our target
        const target = allTargets.pop();
        if (target && target.data && target.data.RiskId) {
          this.riskId = target.data.RiskId;
          await this.fillModel(target.data.RiskId);
        }
      }
    }
    return this.riskId;
  }

  /**
   * For a specified Risk id, fetch the Survey data
   *
   * @param riskId
   * @returns
   */
  async fillModel(riskId: string) {
    this.riskId = riskId;
    const riskReports$: Observable<RiskReport[]> = from(DataStore.query(RiskReport));

    riskReports$.subscribe(async (allReports: any[]) => {
      // Filter only records for the selected riskId
      const reports = allReports.filter((record) => record.riskId.toLowerCase().includes(riskId.toLowerCase()));
      if (!reports || reports.length === 0) {
        // No Data. Add to Constants
        // this.utilService.showAlert(Constants.onNoWifiMsg, '', '');
        this.utilService.showAlert(Constants.noDataForTarget, '', '');
      } else if (reports.length === 1) {
        // New S3 model
        let s3RiskData;
        const riskReport = reports[ 0 ] as RiskReport;
        try {
          s3RiskData = await this.s3Sync.loadLocalDataAsync(this.dataType, riskReport);
        } catch (e) {
          console.error('RiskReport - loadLocalDataAsync failed ' + e.toString());
          this.utilService.addTextConsoleLog('S3Sync', 'loadLocalDataAsync ' + e);
        }

        if (!s3RiskData) {
          // No data found: try to load from S3
          console.warn('RiskReport - LoadS3RiskReport instead');
          s3RiskData = await this.loadS3RiskReport(reports[ 0 ] as RiskReport);
          console.warn('RiskReport - Loaded S3 Risk Report');
          // Save Data to Local Storage
          if (s3RiskData) {
            try {
              this.s3Sync.updateLocalRiskReport(s3RiskData, riskReport);
            } catch (e) {
              console.error('RiskReport - updateLocalRiskReport failed ' + e.toString());
              this.utilService.addTextConsoleLog('S3Sync', 'updateLocalRiskReport ' + e);
            }
          }
        }
        if (s3RiskData) {
          this.processS3RiskReport(s3RiskData);
        }
      } else {
        // Amplify multi-record model
        this.processAmplifyRiskReport(reports);
      }
    });

    return this.riskModel$;
  }

  /**
   * Process Amplify Risk Report Records (multiple per riskId)
   *
   * @param reports
   */
  processAmplifyRiskReport(reports: any) {
    const reportsInfo = reports.filter((rep) => rep.dataType === 'Reports');
    const addresses = reports.filter((rep) => rep.dataType === 'Address');
    const walls = reports.filter((rep) => rep.dataType === 'Walls');
    const occupants = reports.filter((rep) => rep.dataType === 'Occupants'); // Occupant_Level
    const floorsAndRoof = reports.filter((rep) => rep.dataType === 'FloorsAndRoof');

    const amplifyData = new Map<SectionEnum, any>([
      [ SectionEnum.ADDRESS, addresses ],
      [ SectionEnum.OCCUPANTS, occupants ],
      [ SectionEnum.WALLS, walls ],
      [ SectionEnum.FLOORS_ROOF, floorsAndRoof ],
      [ SectionEnum.BUILDING, reportsInfo ],
      [ SectionEnum.BUILDING_BG, reportsInfo ],
    ]);

    // Parametrically read data for each configured section in SectionConfig
    SectionConfig.forEach((sectionData) => {
      if (sectionData.service) {
        const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(sectionData.service);
        const newModel = processInstance.loadData(
          amplifyData.get(sectionData.enum),
          reportsInfo.length > 0 ? reportsInfo[ 0 ] : null,
          false,
          this.model
        );
        if (newModel) {
          this.model = newModel;
        }
      }
    });
  }

  /**
   * Process S3 type Risk Report Record (single per riskId)
   */
  processS3RiskReport(s3RiskData: any, activeMode?: RiskModeEnum, isFromCalculateApi = false) {
    if (!s3RiskData) {
      return this.model;
    }
    const mode = typeof activeMode !== 'undefined' ? activeMode : RiskModeEnum.CURRENCY;
    this.riskId = s3RiskData.RiskId;
    this.report = s3RiskData;

    // Parametrically read data for each configured section in SectionConfig
    SectionConfig.forEach((sectionData) => {
      if (sectionData.order[ mode ] !== 0) {
        if (mode === RiskModeEnum.FULLRISK) {
          //load section data in parent level
          if (sectionData.service) {
            const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(
              sectionData.service
            );
            const prop =
              sectionData.property !== ''
                ? s3RiskData[ sectionData.property[ 0 ].toUpperCase() + sectionData.property?.slice(1) ]
                : s3RiskData;
            const newModel = processInstance.loadData(prop, s3RiskData, true, this.model);
            if (newModel) {
              this.model = newModel;
            }
          } else {
            // Load children instead
            if (sectionData.children) {
              // For top level Sprinkler Report and Wind Report, create empty key
              if (sectionData.property && sectionData.property !== '') {
                const topModelBranch = {};
                topModelBranch[ 'sectionData.property' ] = {};
                this.model = { ...this.model, ...topModelBranch };
              }

              sectionData.children.forEach((subSectionData) => {
                // For not updating the survey date from calc api
                if (!(isFromCalculateApi && subSectionData.enum === SectionEnum.SURVEY_DETAILS)) {
                  const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(
                    subSectionData.service
                  );
                  const prop = subSectionData.property !== '' ? s3RiskData[ subSectionData.property ] : s3RiskData;
                  const newModel = processInstance.loadData(prop, s3RiskData, true, this.model);
                  this.model = { ...this.model, ...newModel };
                }
              });
            }
          }
        } else {
          if (sectionData.service) {
            const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(
              sectionData.service
            );
            const prop = sectionData.property !== '' ? s3RiskData[ sectionData.property ] : s3RiskData;
            const newModel = processInstance.loadData(prop, s3RiskData, true, this.model);
            if (newModel) {
              this.model = newModel;
            }
          }
        }
      }
    });

    return this.model;
  }

  /**
   * Get array of Risk Reports from local cache for development
   *
   * @deprecated Only for development purposes
   * @param isUp
   */
  async getModelFromCache(isUp: boolean) {
    // TODO: Remove when not development. Load cache of locally stored Risk Reports
    if (this.cachedReportLength < 1) {
      this.http.get('./assets/json/risk/cached-risk-reports.json').subscribe(async (allReports: any[]) => {
        this.cachedReportLength = allReports.length;
        if (this.cachedReportLength === 0) {
          // No Data. Inform user...
          this.cachedReports = [];
        } else {
          this.cachedReports = allReports;

          this.getCacheFile(isUp);
        }
      });
    } else {
      this.getCacheFile(isUp);
    }
  }

  /**
   *
   * @param isUp
   */
  async getCacheFile(isUp: boolean) {
    if (this.cachedReportCurrentIndex === -1) {
      if (isUp) {
        this.cachedReportCurrentIndex = 0;
      } else {
        this.cachedReportCurrentIndex = this.cachedReportLength - 1;
      }
    } else if (this.cachedReportCurrentIndex >= this.cachedReportLength - 1) {
      this.cachedReportCurrentIndex = 0;
    } else if (this.cachedReportCurrentIndex < this.cachedReportLength - 1) {
      if (isUp) {
        this.cachedReportCurrentIndex++;
      } else {
        this.cachedReportCurrentIndex--;
        if (this.cachedReportCurrentIndex < 0) {
          this.cachedReportCurrentIndex = this.cachedReportLength - 1;
        }
      }
    }

    if (this.cachedReportCurrentIndex < this.cachedReportLength) {
      // 3. Process "S3 - Like" data stored in the local cache
      this.test = true;
      let s3RiskData = this.cachedReports[ this.cachedReportCurrentIndex ];
      s3RiskData = this.cleanRiskReportGUIDs(s3RiskData);
      // to check data from cache-risk-report passing   RiskModeEnum.FULLRISK as active mode
      this.processS3RiskReport(s3RiskData, RiskModeEnum.FULLRISK);
    }
  }

  /**
   * Clean GUIds for Risk Report
   */
  public cleanRiskReportGUIDs(s3RiskData, existingReportId?: string) {
    const currentRiskReport = s3RiskData;
    let reportIdentifier = Guid.create().toString();

    // If reportId already exists, use it
    reportIdentifier = existingReportId;

    currentRiskReport.ReportIdentifier = reportIdentifier;
    if (currentRiskReport.AdditionDates) {
      currentRiskReport.AdditionDates.forEach((item) => {
        item.AdditionDateIdentifier = Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
      });
    }
    if (currentRiskReport.InternalProtections) {
      currentRiskReport.InternalProtections.forEach((item) => {
        item.InternalProtectionIdentifier = item.InternalProtectionIdentifier ? item.InternalProtectionIdentifier : Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
      });
    }
    if (currentRiskReport.ReportAddresses) {
      currentRiskReport.ReportAddresses.forEach((item) => {
        item.ReportAddressesIdentifier = Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
      });
    }
    if (currentRiskReport.ReportRelatedDates) {
      currentRiskReport.ReportRelatedDates.forEach((item) => {
        item.ReportRelatedDateIdentifier = Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
      });
    }
    if (currentRiskReport.SecondaryConstructions) {
      currentRiskReport.SecondaryConstructions.forEach((item) => {
        item.SecondaryConstructionIdentifier = Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
      });
    }
    if (currentRiskReport.Comments) {
      currentRiskReport.Comments.forEach((item) => {
        item.CommentIdentifier = Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
      });
    }
    if (currentRiskReport.FloorsAndRoofs) {
      currentRiskReport.FloorsAndRoofs.forEach((item) => {
        item.FloorAndRoofIdentifier = Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
      });
    }
    if (currentRiskReport.Walls) {
      currentRiskReport.Walls.forEach((item) => {
        item.WallIdentifier = item.WallIdentifier ? item.WallIdentifier : Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;
        if (!item.ConstructionPercentage) {
          item.ConstructionPercentage = 33;          // Non Nullable
        }
      });
    }
    if (currentRiskReport.Occupants) {
      currentRiskReport.Occupants.forEach((item) => {
        item.OccupantIdentifier = Guid.create().toString();
        item.ReportIdentifier = reportIdentifier;

        if (item.OccupantLevels) {
          item.OccupantLevels.forEach((level) => {
            level.OccupantLevelIdentifier = Guid.create().toString();
            level.ReportIdentifier = reportIdentifier;
          });
        }
      });
    }
    return currentRiskReport;
  }

  getDataType(item) {
    if (item instanceof AddressAliasDataModel) {
      return 'Address';
    }
    if (item instanceof WallsDataModel) {
      return 'Walls';
    }
    if (item instanceof OccupantsDataModel) {
      return 'Occupants';
    }
    if (item instanceof FloorsAndRoofDataModel) {
      return 'FloorsAndRoof';
    }
    return '';
  }

  /**
   * Update Risk Report with data
   *
   * @param item
   */
  async updateData() {
    // 1. Parametrically write data for each configured section in SectionConfig to a new Risk Report
    for (const sectionData of SectionConfig) {
      // SectionConfig.forEach((sectionData) => {
      if (sectionData[ 1 ].service) {
        const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(
          sectionData[ 1 ].service
        );
        const s3RiskData = await processInstance.updateData(this.model, this.report);
        this.report = s3RiskData;
      }
    }
  }

  /**
   * Update Risk Report with specific section data
   *
   * @param section SectionEnum to update
   * @param test If true, just log the result
   * @param sectionEnum In single updateData method if we want to update only particular section details we can use this enum 
   *  (Ex) In case of generalInformation service there are mutilple sections inside same model, if we pass specific enum it will update only related datas 
   * @param serviceInstance if the section we want to update is not in the config file then we cannot get the service name from the config file 
   * in that case if we can pass the service name through this parameter
   */
  async updateSectionData(section: SectionEnum, test: boolean, sectionEnum?: SectionEnum, serviceInstance?: any,
    skipStatus: boolean = false) {
    // 1. Parametrically write data for the specified section in SectionConfig to a new Risk Report
    const sectionData = this.getSectionData(section);
    // 2. Since some model is not in the config we cannot get service instance from the config file
    // So passing the service instance as parameter
    const service = sectionData?.service ? sectionData.service : serviceInstance;
    if (service) {
      const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(service);

      // Warning: In one case executing the updateData has modified the this.model (?)
      const modelCopy = cloneDeep(this.model);
      const s3RiskData = await processInstance.updateData(modelCopy, this.report, sectionEnum);
      // Force the model to be preserved
      this.model = modelCopy;
      if (s3RiskData === undefined) {
        return;
      }
      if (!skipStatus) {
        if (this.riskUIStatus === RiskUIStatus.Completed || this.riskUIStatus === RiskUIStatus.New) {
          this.riskUIStatus = RiskUIStatus.InProgress;
        }
        s3RiskData.RiskUIStatus = this.riskUIStatus;
        s3RiskData.Status = this.getRiskReportStatus(this.riskUIStatus);
      }
      this.report = s3RiskData;
    }

    if (!test && !this.test) {
      try {
        const reportLocation = this.dataType;
        if (this.isFullRiskReport(this.report)) {
          this.saveFullRiskData(this.report?.SurveyDate);

        } else {
          // await this.s3Sync.updateLocalCopy(this.report, reportLocation, false);
          //Praveen - have to update with saveFormOrRiskReportToLocal method
          //await this.localStorageService.saveOrderJSONToLocal(this.report, this.dataType);
          const reportId = this.report.ReportIdentifier;
          console.log('this.report', this.report);
          this.localStorageService.saveFormOrRiskReportToLocal(
            this.report,
            ConfigModel.RiskReport,
            reportId,
            AssetType.RiskReport,
            reportId,
            false
          );
        }
      } catch (e) {
        console.error('RiskReport - updateLocalCopy failed ' + e.toString());
        this.utilService.addTextConsoleLog('S3Sync', 'updateLocalCopy ' + e);
      }
    } else {
      console.debug('New Updated Risk Report');
      if (!sectionData.property || sectionData.property === '') {
        console.debug(this.report);
      } else {
        console.debug(this.report[ sectionData.property ]);
      }
    }

    return this.report;
  }

  private getRiskReportStatus(riskUIStatus) {
    switch (riskUIStatus) {
      case RiskUIStatus.Completed:
        return Constants.Complete_Status;
      case RiskUIStatus.New:
        return Constants.New_Status;
      case RiskUIStatus.InProgress:
        return Constants.In_Progress_Status;
    }
    return Constants.In_Progress_Status;
  }

  // Save the risk details in the local memory
  public saveFullRiskData(surveyDate, isSync = false) {
    // Unflatten content when report location is order
    this.report.SurveyDate = surveyDate;
    if (this.report && this.report?.EvidenceOfFireSprinkler && this.report?.EvidenceOfFireSprinkler !== 'Y') {
      this.report.SprinklerReport = null;
    }
    if (this.report && this.report?.EvidenceOfFireSprinkler && this.report?.EvidenceOfFireSprinkler === 'N') {
      this.report.SprinklerTypeCodeValue = 'N';
    }
    // if (this.report?.WindReport && !(Object.keys(this.report?.WindReport).length > 1)) {
    //   this.report.WindReport = {};
    // }
    const s3RiskReport = this.unflattensFullRiskReport(this.report);

    // // Update Sprinkler Report CustomService to the same value as the Risk Report
    // if (s3RiskReport.SprinklerReport) {
    //   s3RiskReport.SprinklerReport.IsCustomService = s3RiskReport.RiskReport.IsCustomService;
    // }

    // Save to local storage and notify
    // const riskData = await this.updateOrderRiskReport(s3RiskReport, false);
    // // Inform to the sync
    const reportId = s3RiskReport.RiskReport.ReportIdentifier;
    console.log('this.report', s3RiskReport);
    // this.informSync(riskData, reportId);
    this.localStorageService.saveFormOrRiskReportToLocal(
      s3RiskReport,
      ConfigModel.Order,
      this.ordersService.selectedOrderFromS3.OrderIdFullOrderNumber,
      AssetType.RiskReport,
      reportId,
      isSync
    );
  }

  updateGeneralData(modelField, riskReportField, comparingSection) {
    if (!this.model[ modelField ] && !this.report[ riskReportField ]) {
      const newValue =
        this.report[ comparingSection ] && Object.keys(this.report[ comparingSection ]).length !== 0 ? 'Y' : 'N';
      console.log('a' + newValue);
      return newValue;
    } else {
      return this.report[ riskReportField ];
    }
  }

  /**
   *
   * @param section
   * @returns The Section Config Information for the main section / section searched
   */
  getSectionData(section: SectionEnum): SectionConfigInterface {
    let data = SectionConfig.get(section);
    if (data === undefined) {
      // Maybe search inside sections with children
      const unorderedSectionList = [ ...SectionConfig.entries() ];
      unorderedSectionList.forEach((mainSectionData) => {
        const mainSectionInfo = mainSectionData[ 1 ];
        if (mainSectionInfo.children) {
          mainSectionInfo.children.forEach((sectionInfo) => {
            if (sectionInfo.enum === section) {
              data = sectionInfo;
            }
          });
        }
      });
    }
    return data;
  }

  /**
   * Method that effectively retrieves a S3 stored json file
   *
   * @param report
   * @returns
   */
  async loadS3RiskReport(riskReport: RiskReport) {
    const rptId = riskReport[ 'rptId' ];
    let s3Prefix = 'isurvey';
    if (this.isAcceptanceEnvironment) {
      s3Prefix = 'isurvey-accept';
    }

    let s3Obj = {};
    try {
      const path = `${s3Prefix}/riskreport/${rptId}/${rptId}.json`;
      this.capacitorHttpService.addConsoleLog(true, { url: path });
      const storageResult = await AmplifyStorage.get(`${s3Prefix}/riskreport/${rptId}/${rptId}.json`, {
        download: true,
        cacheControl: 'no-cache',
      });
      const data = await new Response(storageResult.Body).text();
      this.capacitorHttpService.addConsoleLog(true, { url: path, status: '200', data: rptId });
      s3Obj = JSON.parse(data);
    } catch (error) {
      console.error(error);
      this.utilService.addTextConsoleLog('riskReport', 'loadS3RiskReport ' + error);
    }

    return s3Obj;
  }

  async removeExteriorPhotos(target) {
    const reports = await DataStore.query(RiskReport, (rpt) => rpt.riskId.eq(target.data[ 'RiskId' ]));
    if (reports && reports.length) {
      const _rptId = reports[ 0 ].rptId;
      let localRiskReport;
      try {
        localRiskReport = await this.s3Sync.loadlocalData('RiskReport', _rptId);
      } catch (error) {
        const localRiskReportFromServer = await this.s3Sync.retrieveFromServer('RiskReport', _rptId);
        localRiskReport = JSON.parse(localRiskReportFromServer.data);
      }
      localRiskReport.ReportPhotos = localRiskReport.ReportPhotos.filter(
        (photo) => ![ 'FRNT', 'REAR' ].includes(photo.ReportPhotoType)
      );
      const targetLoc = target.hasOwnProperty('surveyType')
        ? (target.data.LocationPoint.coordinates as LngLatLike)
        : (target.LocationPoint.coordinates as LngLatLike);

      localRiskReport.RooftopLocationPoint = {
        Latitude: targetLoc[ 1 ],
        Longitude: targetLoc[ 0 ],
      };
      await this.s3Sync.updateLocalCopy(localRiskReport, 'RiskReport');
    }
  }

  /**
   * @param riskReport
   * @param targetData
   */
  importTarget2RiskReport(riskReport: any, targetData: any) {
    let importData = null;

    // Address
    if (riskReport.ReportAddresses === null) {
      // Create at least the target address as Main Address
      const addressGuid = Guid.create();
      if (importData === null) {
        importData = {};
      }
      importData.ReportAddresses = [
        {
          ReportAddressIdentifier: addressGuid ? addressGuid.toString() : undefined,
          ReportIdentifier: targetData.ReportIdentifier ? targetData.ReportIdentifier : undefined,
          AddressSequence: 1,
          StreetName: targetData.StreetName ? targetData.StreetName : undefined,
          LowNumber: targetData.LowNumber ? targetData.LowNumber : undefined,
          HighNumber: targetData.HighNumber ? targetData.HighNumber : undefined,
          Prefix: targetData.Prefix ? targetData.Prefix : undefined,
          StreetType: targetData.StreetType ? targetData.StreetType : undefined,
          PostDirection: targetData.PostDirection ? targetData.PostDirection : undefined,
          City: targetData.City ? targetData.City : undefined,
          StateCode: targetData.StateCode ? targetData.StateCode : undefined,
          Zip: targetData.Zip ? targetData.Zip : undefined,
          Zip4: targetData.Zip4 ? targetData.Zip4 : undefined,
          County: targetData.County
            ? targetData.County?.replace(new RegExp('county', 'ig'), '')?.trim()
            : undefined,
          PreDirection: targetData.PreDirection ? targetData.PreDirection : undefined,
          AddressVerificationTypeCodeValue: 'M',
          // "PostalCity": "HADDONFIELD",
          // "FireProtectionArea": "HADDONFIELD FPSA",
          // "CommunityName": "HADDONFIELD",
          IsAlias: false,
        },
      ];
    }

    // BuildingName
    if (riskReport.BuildingDescription === null) {
      if (importData === null) {
        importData = {};
      }
      importData.BuildingDescription = targetData.BuildingDescription;
    }

    // LocationPoint
    if (riskReport.RooftopLocationPoint === null) {
      let lat = null;
      let lon = null;

      try {
        lon = targetData.LocationPoint.coordinates[ 0 ];
        lat = targetData.LocationPoint.coordinates[ 1 ];
      } catch (ignore) { }

      if (lat !== null && lon !== null) {
        if (importData === null) {
          importData = {};
        }
        importData.RooftopLocationPoint = {
          Latitude: lat ? lat : undefined,
          Longitude: lon ? lon : undefined,
        };
      }
    }

    // Walls at least empty, but not null
    if (riskReport.Walls === null) {
      if (importData === null) {
        importData = {};
      }
      importData.Walls = [];
    }

    // Floors and roof empty, but not null
    if (riskReport.FloorsAndRoofs === null) {
      if (importData === null) {
        importData = {};
      }
      importData.FloorsAndRoofs = [];
    }

    // Occupants empty, but not null
    if (riskReport.Occupants === null) {
      if (importData === null) {
        importData = {};
      }
      importData.Occupants = [];
    }

    return importData;
  }

  /**
   * @param riskReport
   * @param order
   */
  importOrder2RiskReport(s3RiskReport: any, order: OrderModel) {
    const importData: any = {};

    // We always will force the OrderIdFullOrderNumber, even if we already have a value in the Risk Report
    // if (!s3RiskReport.OrderId) {
    if (order.OrderIdFullOrderNumber) {
      importData.OrderIdFullOrderNumber = order.OrderIdFullOrderNumber;
    }

    // Contact Information
    if (!s3RiskReport.ContactName || s3RiskReport.ContactName.trim() === '') {
      importData.ContactName = order.SurveyContactName;
      importData.ContactPhone =
        order.SurveyContactPhone.AreaCode + order.SurveyContactPhone.Exchange + order.SurveyContactPhone.Number;
    }

    if (!s3RiskReport.EscortedByName || s3RiskReport.EscortedByName.trim() === '') {
      // importData.EscortedByName = order.ProducerContactName;
      // importData.EscortedByPhone = order.ProducerContactPhone.AreaCode + order.ProducerContactPhone.Exchange + order.ProducerContactPhone.Number;
    }

    // BuildingName
    if (!s3RiskReport.BuildingDescription) {
      // importData.BuildingDescription = order.InsuredNameDba;
      s3RiskReport.BuildingDescription = '';
    }

    // Remove 'New Risk' title that the iPad uses: it's against the Building Description story!
    if (s3RiskReport.BuildingDescription === Constants.newBuildingDescription) {
      s3RiskReport.BuildingDescription = '';
    }

    // Walls at least empty, but not null
    if (!s3RiskReport.Walls) {
      importData.Walls = [];
    }

    // Floors and roof empty, but not null
    if (!s3RiskReport.FloorsAndRoofs) {
      importData.FloorsAndRoofs = [];
    }

    // Occupants empty, but not null
    if (!s3RiskReport.Occupants) {
      importData.Occupants = [];
    }
    if ((s3RiskReport.SurveyDate === null || s3RiskReport.SurveyDate === undefined) &&
      (order?.AppointmentSet !== null || order?.AppointmentSet !== undefined)) {
      const appointmentSet = this.utilService.formatDate(order?.AppointmentSet);
      const ossdDate = s3RiskReport?.ReportRelatedDates?.find(src => src?.ReportDateTypeCodeValue === 'OSSD')?.ReportDateTime;
      const surveyDate = (order?.Status === 'Rework' || this.ordersService.isCustomerServiceUser) ? ossdDate :
        (appointmentSet?.length > 0 ? order?.AppointmentSet : null);
      s3RiskReport.SurveyDate = surveyDate;
      s3RiskReport.ReportRelatedDates = SurveyDetailsDataModel.setRelatedDates(surveyDate, s3RiskReport);
      if (s3RiskReport?.SprinklerReport !== null && Object.keys(s3RiskReport?.SprinklerReport).length > 1) {
        s3RiskReport.SprinklerReport.SurveyDate = surveyDate;
      }
      if (s3RiskReport?.WindReport !== null && Object.keys(s3RiskReport?.WindReport).length > 1) {
        s3RiskReport.WindReport.SurveyDate = surveyDate;
      }
    }

    if (order?.PrePopulatingInformations?.length) {
      order.PrePopulatingInformations.forEach((prePopulatingInformation) => {
        if (prePopulatingInformation.PrepopulatingInformationType === 'FlowTestLocation') {
          importData.FlowTestLocation = prePopulatingInformation.PrepopulatingInformationValue;
        }
      });
    }

    //This are the Public protection class properties that we are fetching from orders.
    if (order?.PpcInformation) {

      importData.PpcFpa = order.PpcInformation.FireProtectionArea;
      importData.PpcAtRisk = order.PpcInformation.PpcAtRisk;
      importData.CommercialTerritoryCode = order.PpcInformation.CommercialTerritoryCode;
      importData.AlternativePpc = order.PpcInformation.AlternativePpc;
      importData.PpcFireHydrant = order.PpcInformation.WaterSupplyType;

    }
    importData.GeographicRiskFactor = order.GeographicRiskFactor;
    if (importData?.GeographicRiskFactor && importData?.GeographicRiskFactor?.trim()?.toUpperCase() === 'LOW') {
      importData.WindReport = null;
      importData.IsWindReportDeleted = true;
    }

    // Changes asked for by the Backend team
    // Previously it was this.ordersService.QCUser
    if (this.ordersService.isCustomerServiceUser === true) {
      importData.IsCustomService = true;
    } else {
      importData.IsCustomService = false;
    }
    // Backend team instructs to send "A" instead of "SDAP"
    // importData.RiskTypeCodeValue = 'SDAP';
    importData.RiskTypeCodeValue = 'A';
    importData.Status = Constants.In_Progress_Status;

    // to set evidence of sprinkler if s3RiskReport has sprinkler report details
    // we are doing this inside data model now
    // if (s3RiskReport.EvidenceOfFireSprinkler === null && s3RiskReport?.SprinklerReport &&
    //   s3RiskReport?.SprinklerReport?.ReportId !== Guid.EMPTY && Object.keys(s3RiskReport?.SprinklerReport).length > 1) {
    //   s3RiskReport.EvidenceOfFireSprinkler = 'Y';
    // }

    // Correcting the ReportAddresses details with order details
    const address = [];
    s3RiskReport?.ReportAddresses?.forEach(addr => {
      addr.County = addr?.County?.length > 0 ? addr?.County : (order?.ValidatedCounty ? order?.ValidatedCounty?.replace(new RegExp('county', 'ig'), '')?.trim() : undefined);
      addr.PostalCity = addr?.PostalCity?.length > 0 ? addr?.PostalCity : (order?.ValidatedCity ? order?.ValidatedCity : undefined);
      addr.City = addr?.City?.length > 0 ? addr?.City : (order?.ValidatedCity ? order?.ValidatedCity : undefined);
      addr.StateCode = addr?.StateCode?.length > 0 ? addr?.StateCode : (order?.ValidatedStateAbbrev ? order?.ValidatedStateAbbrev : undefined);
      addr.Zip = addr?.Zip?.length > 0 ? addr?.Zip : (order?.ValidatedPostalCodeFive ? order?.ValidatedPostalCodeFive : undefined);
      addr.AddressVerificationTypeCodeValue = addr?.HighNumber?.trim()?.length > 0 ? null : addr?.AddressVerificationTypeCodeValue;
    });

    // Defaulting SkyLightsRoofRatingType value for risks
    if (s3RiskReport && s3RiskReport?.WindReport && s3RiskReport?.WindReport?.RoofEnvelope &&
      s3RiskReport?.WindReport?.RoofEnvelope?.HasSkyLightsPresentOnRoof === false) {
      s3RiskReport.WindReport.RoofEnvelope.SkyLightsRoofRatingType = 'NAPP';
    }

    this.setRiskDefaultValues(s3RiskReport, importData);
    return importData;
  }

  /**
   * Creates a new empty Risk Report
   * @returns New Risk Report with minimal needed fields
   */
  createNewRiskReport(): S3RiskReportModel {
    const reportIdentifier = Guid.create().toString();

    const newRiskReport: S3RiskReportModel = {
      ReportIdentifier: reportIdentifier,
      BuildingDescription: '',
      RooftopLocationPoint: new ReportPointModel(),
      IsSprinklerReportDeleted: false,
      IsWindReportDeleted: false,
      CreationCodeValue: '',
      UpdateCodeValue: '',
      IsHydrantsWithin1000Feet: '',
      EvidenceOfFireSprinkler: '',
      IsCombustibleMaterialAcrossPartyWall: false,
      IsYearBuiltFrOverride: false,
      IsYearBuiltUndetermined: false,
      HasSlowBurnMetalCompositeMaterialPanels: false,
      HasCombustibleMetalCompositeMaterialPanels: false,
      IsSprinklered: false,
      RoofTopCovering: '',

      TotalArea: 0,
      AboveGradeFloors: 0,

      Walls: new Array<WallModel>(),
      FloorsAndRoofs: new Array<FloorsAndRoofModel>(),
      Occupants: new Array<OccupantModel>(),
    };

    // TODO: Remove that!
    newRiskReport.Walls = [
      {
        WallIdentifier: Guid.create().toString(),
        ReportIdentifier: reportIdentifier,
        LineNumber: 1,
        Thickness: 36,
        WallClassification: 2,
        IsReinforcedConcrete: false,
        Damagibility: null,
        HourlyRating: null,
        IsNoncombustible: false,
        IsSlowBurning: false,
        IsMasonryVeneer: false,
        InsulationFlameSpread: 0,
        InsulationSmokeDevelopment: 0,
        IsUnlisted: false,
        CombustibilityClass: null,
        IsWoodIronClad: false,
        FireResistiveListType: null,
        IsFlameSpreadAbove200: false,
        HasAcceptableThermalBarrier: false,
        HasNote2: false,
        PaintFlameSpread: 0,
        PaintSmokeDevelopment: 0,
        ConstructionTypeCodeValue: 'M',
        Width: 17400,
        Height: 1,
        IsCombustible: false,
        CombustibilityRating: 'NONE',
        HasFoamPlasticInsulation: false,
        HasFireRetardantPaint: false,
        ScopesVersion: 1,
        HasMetalCompositeMaterial: false,
        MassTimberFireResistive: null,
        MassTimberProtection: null,
        ConstructionTypeCode: null,
        MetalCompositeMaterials: [],
      },
      {
        WallIdentifier: Guid.create().toString(),
        ReportIdentifier: reportIdentifier,
        LineNumber: 1,
        Thickness: 12,
        WallClassification: 3,
        IsReinforcedConcrete: false,
        Damagibility: null,
        HourlyRating: null,
        IsNoncombustible: false,
        IsSlowBurning: false,
        IsMasonryVeneer: false,
        InsulationFlameSpread: 0,
        InsulationSmokeDevelopment: 0,
        IsUnlisted: false,
        CombustibilityClass: null,
        IsWoodIronClad: false,
        FireResistiveListType: null,
        IsFlameSpreadAbove200: false,
        HasAcceptableThermalBarrier: false,
        HasNote2: false,
        PaintFlameSpread: 0,
        PaintSmokeDevelopment: 0,
        ConstructionTypeCodeValue: 'COMB',
        Width: 5400,
        Height: 1,
        IsCombustible: false,
        CombustibilityRating: 'NONE',
        HasFoamPlasticInsulation: false,
        HasFireRetardantPaint: false,
        ScopesVersion: 1,
        HasMetalCompositeMaterial: false,
        MassTimberFireResistive: null,
        MassTimberProtection: null,
        ConstructionTypeCode: null,
        MetalCompositeMaterials: [],
      },
    ];

    return newRiskReport;
  }

  /**
   *
   * @param s3RiskReport
   * @returns RiskModeEnum based on the presence of RiskId or Order
   */
  getRiskReportMode(s3RiskReport: any, order: OrderModel): RiskModeEnum {
    if (s3RiskReport[ 'RiskId' ] && !order) {
      return RiskModeEnum.CURRENCY;
    } else if (order) {
      return RiskModeEnum.FULLRISK;
    } else {
      return RiskModeEnum.COVERAGE;
    }
  }

  /**
   * Flattens the Full Risk Report into a Currency/Coverage compatible one:
   * - Moves the RiskReport branch to the root
   * - Leaves the SprinklerReport and WindReport branches "as is"
   * @param s3RiskReport flattened Risk Report
   */
  flattensFullRiskReport(s3FullRisk: any): any {
    // Don't do that if it's already 'flat'
    if (!s3FullRisk.RiskReport) {
      return s3FullRisk;
    }
    // this.riskUIStatus = s3FullRisk?.RiskUIStatus;
    const flatRiskReport = cloneDeep(s3FullRisk.RiskReport);
    try {
      // Clone SR
      flatRiskReport.SprinklerReport = cloneDeep(s3FullRisk.SprinklerReport);
      if (!flatRiskReport.SprinklerReport) {
        flatRiskReport.SprinklerReport = {};
      }

      // Clone WR
      flatRiskReport.WindReport = cloneDeep(s3FullRisk.WindReport);
      if (!flatRiskReport.WindReport) {
        flatRiskReport.WindReport = {};
      }
    } catch (error) {
      console.error('Error flattening Full RiskReport to standard RiskReport: ' + error.message);
    }
    return flatRiskReport;
  }

  /**
   * Unflattens the Full Risk Report into a FullRisk compatible one:
   * - Moves all the root content to the RiskReport branch except the
   *   SprinklerReport and WindReport branches
   * @param s3RiskReport unflattened Risk Report
   */
  public unflattensFullRiskReport(s3FullRisk: any): any {
    const fullRiskReport = {
      RiskReport: null,
      SprinklerReport: null,
      WindReport: null,
      RiskUIStatus: null,
      NumberOfPendingErrors: null
    };

    try {
      const tempRiskReport = cloneDeep(s3FullRisk);
      delete tempRiskReport.SprinklerReport;
      delete tempRiskReport.WindReport;
      // Update the Risk Report Status and Errors - AFTER UNFLATTENING!
      fullRiskReport.RiskUIStatus = this.riskUIStatus;
      fullRiskReport.NumberOfPendingErrors = this.errorService.numberOfErrors;
      delete tempRiskReport.RiskUIStatus;
      fullRiskReport.RiskReport = tempRiskReport;
      if (fullRiskReport?.RiskReport) {
        fullRiskReport.RiskReport.Status = this.getRiskReportStatus(this.riskUIStatus);
      }

      fullRiskReport.SprinklerReport = cloneDeep(s3FullRisk.SprinklerReport);
      if (!fullRiskReport.SprinklerReport || Object.keys(fullRiskReport.SprinklerReport).length <= 1) {
        fullRiskReport.SprinklerReport = null;
      } else {
        fullRiskReport.SprinklerReport.Status = fullRiskReport.RiskReport.Status;
      }

      fullRiskReport.WindReport = cloneDeep(s3FullRisk.WindReport);
      if (!fullRiskReport.WindReport || Object.keys(fullRiskReport.WindReport).length <= 2) {
        fullRiskReport.WindReport = null;
      }
    } catch (error) {
      console.error('Error unflattening Full RiskReport to standard RiskReport: ' + error.message);
    }

    return fullRiskReport;
  }

  private isFullRiskReport(s3riskReport) {
    if (s3riskReport.SprinklerReport !== undefined) {
      return true;
    }
    return false;
  }

  /**
   * Saves the risk report to disk (this will launch a S3 sync)
   *
   * @deprecated Maybe. Check if the there's an updated method in the iPad localStorage service
   * @param data
   * @param firstSave
   * @returns
   */
  private updateOrderRiskReport(data: any, firstSave: boolean = false): Promise<string> {
    return new Promise((resolve, reject) => {
      const reportId: string = data.RiskReport[ 'ReportIdentifier' ];
      const orderNumber = data.RiskReport[ 'OrderIdFullOrderNumber' ];
      const rfo: any = {};
      rfo.directory = Directory.Documents;
      // For RR Curr/Cov: RiskReport/guid-report-id/guid-report-id.json
      // For Order RR   : Order/FL2211000007/riskreport/guid-report/guid-report.json
      rfo.path = `Order/${orderNumber}/riskreport/${reportId}/${reportId}.json`;
      rfo.encoding = Encoding.UTF8;
      rfo.recursive = false;
      rfo.data = JSON.stringify(data);
      Filesystem.writeFile(rfo).then(
        () => {
          const veriskLog = new VeriskLog(
            'Updating local copy',
            'INFO',
            this.updateOrderRiskReport.name,
            S3SyncService.name,
            reportId
          );
          this.utilService.addLog(veriskLog);
          resolve(data);
        },
        (e) => {
          const veriskLog = new VeriskLog(
            'Updating local copy failed',
            'ERROR',
            this.updateOrderRiskReport.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          reject('LOCAL SAVE FAILED [' + e.message + ']');
        }
      );
    });
  }

  informSync(data, reportId) {
    this.localStorageService.saveFormOrRiskReportToLocal(
      data,
      ConfigModel.Order,
      this.ordersService.selectedOrderFromS3.OrderIdFullOrderNumber,
      AssetType.RiskReport,
      reportId,
      false
    );
  }

  /**
   * When cloned we need to treat the cloned wall panel & column as existing risk data so cleaning the data in this method
   */
  public updateClonedWallsPanelBoolean(orgRisk) {
    if ((orgRisk.RiskReport.PercentGlassPanelArea !== null && orgRisk.RiskReport.PercentGlassPanelArea > 0) ||
      (orgRisk.RiskReport.PercentCombustiblePanelArea !== null && orgRisk.RiskReport.PercentCombustiblePanelArea > 0)
      || (orgRisk.RiskReport.PercentSlowBurnPanelArea !== null && orgRisk.RiskReport.PercentSlowBurnPanelArea > 0)) {
      return true;
    } else {
      return false;
    }
  }

  public updateClonedWallsColumnBoolean(orgRisk) {
    if (orgRisk.RiskReport?.PercentUnprotectedMetalColumnArea !== null &&
      orgRisk.RiskReport?.PercentUnprotectedMetalColumnArea !== '' && Number(orgRisk.RiskReport?.PercentUnprotectedMetalColumnArea) > 0) {
      return true;
    } else {
      return false;
    }
  }

  public setRiskDefaultValues(s3RiskReport, importData) {
    if (s3RiskReport) {
      if (!s3RiskReport.RiskId) { s3RiskReport.RiskId = ''; }
      if (!s3RiskReport.ColumnType) { s3RiskReport.ColumnType = ' '; }
      if (!s3RiskReport.HasCombustibleColumns) { s3RiskReport.HasCombustibleColumns = false; }
      if (!s3RiskReport.HasCombustiblePanels) { s3RiskReport.HasCombustiblePanels = false; }
      if (!s3RiskReport.HasGlassPanels) { s3RiskReport.HasGlassPanels = false; }
      if (!s3RiskReport.HasMultipleFireDivisions) { s3RiskReport.HasMultipleFireDivisions = false; }
      if (!s3RiskReport.HasNonCombustiblePanels) { s3RiskReport.HasNonCombustiblePanels = false; }
      if (!s3RiskReport.HasSlowBurnPanels) { s3RiskReport.HasSlowBurnPanels = false; }
      if (!s3RiskReport.HasUnprotectedMetalColumns) { s3RiskReport.HasUnprotectedMetalColumns = false; }
      if (!s3RiskReport.InaccessibleAreaComment) { s3RiskReport.InaccessibleAreaComment = null; }
      if (!s3RiskReport.Is22gaRoof) { s3RiskReport.Is22gaRoof = false; }
      if (!s3RiskReport.IsCc3) { s3RiskReport.IsCc3 = false; }
      if (!s3RiskReport.IsCc4) { s3RiskReport.IsCc4 = false; }
      if (!s3RiskReport.IsConstructionClassOverride) { s3RiskReport.IsConstructionClassOverride = false; }
      if (!s3RiskReport.IsFloorPercentLightFrameConstructionNotAvailable) { s3RiskReport.IsFloorPercentLightFrameConstructionNotAvailable = false; }
      if (!s3RiskReport.IsMixedLowHighRise) { s3RiskReport.IsMixedLowHighRise = false; }
      if (!s3RiskReport.IsReinforcedConcrete) { s3RiskReport.IsReinforcedConcrete = false; }
      if (!s3RiskReport.IsRoofPercentLightFrameConstructionNotAvailable) { s3RiskReport.IsRoofPercentLightFrameConstructionNotAvailable = false; }
      if (!s3RiskReport.IsSteelLight) { s3RiskReport.IsSteelLight = false; }
      if (!s3RiskReport.IsSteelOtherThanLight) { s3RiskReport.IsSteelOtherThanLight = false; }
      if (!s3RiskReport.IsWindUplift90) { s3RiskReport.IsWindUplift90 = false; }
      if (!s3RiskReport.PanelConstructionType) { s3RiskReport.PanelConstructionType = '  '; }
      if (!s3RiskReport.IsCombustibleMaterialAcrossPartyWall) { s3RiskReport.IsCombustibleMaterialAcrossPartyWall = false; }
      if (!s3RiskReport.HasSlowBurnMetalCompositeMaterialPanels) { s3RiskReport.HasSlowBurnMetalCompositeMaterialPanels = false; }
      if (!s3RiskReport.HasCombustibleMetalCompositeMaterialPanels) { s3RiskReport.HasCombustibleMetalCompositeMaterialPanels = false; }
      if (!s3RiskReport.NumberOfFloors) { s3RiskReport.NumberOfFloors = 0; }
      // if(!s3RiskReport.IsOpenSided) s3RiskReport.IsOpenSided = false;
      // Setting IsBasicGroup2SymbolOverride field value
      const mainAddress = s3RiskReport?.ReportAddresses?.filter(src => !src?.IsAlias);
      const stateCode = mainAddress[ 0 ]?.StateCode?.trim();
      if (mainAddress?.length > 0 && (stateCode === 'NY' || stateCode === 'NX' || stateCode === 'PR')) {
        importData.IsBasicGroup2SymbolOverride = true;
      } else {
        importData.IsBasicGroup2SymbolOverride = false;
      }
      s3RiskReport.ColumnType = s3RiskReport.ColumnType.replaceAll('\u0000', ' ');
      s3RiskReport.PanelConstructionType = s3RiskReport.PanelConstructionType.replaceAll('\u0000', ' ');
      if (s3RiskReport.ColumnType === ' ') {
        s3RiskReport.ColumnWallThickness = null;
        s3RiskReport.PercentUnprotectedMetalColumnArea = null;
      }
      if (s3RiskReport.PanelConstructionType === '  ') { s3RiskReport.PercentGlassPanelArea = null; }
    }
  }
}
