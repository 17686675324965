import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Guid } from 'guid-typescript';
import { FULL_RISK_TARGET } from '../constants/general.constants';

@Injectable({
  providedIn: 'root'
})
export class FullRiskGeneralService {

  constructor() { }

  /**
   *
   * @method enableMainSection Will enable mainSection Option from the dropdown in full risk landing page
   * @param {FormlyFieldConfig} field
   * @param {number} sectionId
   */
  enableMainSection(field: FormlyFieldConfig, sectionId: number) {
    const index = field.options.formState.isMainSectionDisabled.findIndex(section => section.sectionId === sectionId);
    if (index !== -1) { field.options.formState.isMainSectionDisabled.splice(index, 1) };
  }

  /**
   *
   * @method disableMainSection Will disable mainSection Option from the dropdown in full risk landing page
   * @param {FormlyFieldConfig} field
   * @param {number} sectionId
   */
  disableMainSection(field: FormlyFieldConfig, sectionId: number) {
    const index = field.options.formState.isMainSectionDisabled.findIndex(section => section.sectionId === sectionId);
    if (index === -1) {
      field.options.formState.isMainSectionDisabled.push({sectionId});
    }
  }

  /**
   *  No selected target ==> Full Risk
   *  - Quick solution: Simulate a target for now
   *  - TODO: Remove target dependencies in Risk Form (mainly Photos/Rooftop sections). Order: SurveyOrder
   */
  simulateTarget(order) {
    return {
      OrchtargetId: Guid.create().toString(),
      status: 'InProgress',
      surveyType: FULL_RISK_TARGET,
      data: {
        LocationPoint: {
          coordinate: [order.PropertyLocationPoint?.Longitude,
          order.PropertyLocationPoint?.Latitude],
          type: 'Point',
        }
      }
    };
  }
}
