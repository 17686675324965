import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';

export class FirePumpTestsSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'firePumpTests',
      type: 'fr-common-custom-grid-view',
      className: 'fire-pump-test-section-container',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Fire Pump Test',
        isCollapsable: true,
        isCollapsed: false,
        hasShadow: false,
        // eslint-disable-next-line max-len
        actionButtons: [{ section: 'firePumpTests', action: 'add', label: 'Add', isDisabled: false }],
        gridSection: 'firePumpTests',
        parentKey: null,
        isEdit: true,
        isDelete: false,
        showOnlyRecentOne: false,
        isAddReportIdentifier: true,
        isAddIdentifier: true,
        identifier: 'firePumpTestId',
        gridColConfig: [
          { label: 'Date Tested', propertyPath: 'testDate',isDate: true, isObject: true, objectName: 'firePumpTestInput', className: 'common-grid-view-width-115' },
          { label: 'Static', propertyPath: 'dischargePsi', isArray: true, arrayName: 'firePumpTestResults', index: 0, className: 'common-grid-view-width-100',unit:'PSI' },
          { label: 'Residual', propertyPath: 'dischargePsi', isArray: true, arrayName: 'firePumpTestResults', index: 1, className: 'common-grid-view-width-100', unit:'PSI' },
          { label: 'Flow', propertyPath: 'totalDischargeGpm', isArray: true, arrayName: 'firePumpTestResults', index: 1, className: 'common-grid-view-width-100', unit:'GPM' }
        ]
      },
      fieldArray: {
        props: {
          title: 'Sprinkler Report',
          subTitle: 'Fire Pump Test Input',
          sheetButtons: [
            {
              section: 'firePumpTest',
              action: 'onCloseFirePumpTest',
              label: 'Close',
              service: 'firePumpTestsService',
              className: 'close-btn medium-btn',
              passInitialModel: true,
            },
            {
              section: 'firePumpTest',
              action: 'onApplyFirePumpTest',
              label: 'Apply',
              service: 'firePumpTestsService',
              className: 'apply-btn large-btn',
              hasConfirmationPopup: true
            }
          ],
        },
        fieldGroup: [
          {
            key: 'firePumpTestInput',
            className: 'fire-pump-test-container',
            wrappers: ['simple-section-wrapper','error-info-wrapper'],
            props: {
              label: 'Edit Fire Pump Test',
              className: 'padding-bottom',
              showError: false
            },
            fieldGroup: [
              {
                key: 'testDate',
                type:'rf-date-picker',
                props: {
                  label: 'Date Tested',
                  isCardEnabled: true,
                  isHorizontalOrientation: true,
                  rightAlign: true,
                  datePickerId: 'testDate',
                  displayFormat: 'MM/dd/yy'
                },
                validators: {
                  futureDateValidator: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const isDisabled = formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent);
                      if (!isDisabled || !control.value) {
                        return true;
                      } else {
                        return new Date(control.value) < new Date()
                      }
                    },
                    message: 'Cannot be future date.'
                  },
                }
              },
              {
                key: 'firePump',
                wrappers: ['question-wrapper'],
                className: 'fire-pump-style',
                props: {
                  label: 'Fire Pump',
                  firstColSize: 1.8,
                  isHorizontalOrientation: true,
                  hasShadow: true,
                  isSectionLabelNormal: true,
                  showError: false
                },
                fieldGroupClassName: 'card-view no-box-shadow no-padding',
                fieldGroup: [
                  {
                    key: 'pumpRatingGpm',
                    type: 'simple-input',
                    props: {
                      label: 'Rated Flow',
                      unit: 'GPM',
                      isHorizontalOrientation: true,
                      type: 'number',
                      inputmode: 'tel',
                      isAsteriskNotRequired: true
                    },
                    expressions: {
                      'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent?.parent),
                    }
                  },
                  {
                    key: 'pumpPsi',
                    type: 'simple-input',
                    props: {
                      label: 'Rated PSI',
                      unit: 'PSI',
                      isHorizontalOrientation: true,
                      type: 'number',
                      inputmode: 'tel',
                      isAsteriskNotRequired: true
                    },
                    expressions: {
                      'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent?.parent),
                    }
                  },
                ],
              },
              {
                key: 'pumpMake',
                type: 'simple-input',
                className: 'fire-pump-input-width',
                props: {
                  label: 'Make',
                  isCardEnabled: true,
                  isHorizontalOrientation: true,
                  rightAlign: true,
                  maxLength: 100,
                  isAsteriskNotRequired: true
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent),
                }
              },
              {
                key: 'pumpType',
                type: 'simple-select',
                className: 'select-full-width pump-type-style',
                props: {
                  label: 'Type',
                  isCardEnabled: true,
                  isHorizontalOrientation: true,
                  options: formlyBuilderService.firePumpTestsService.getTypesOptions(),
                  isAsteriskNotRequired: true
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent),
                }
              },             
              {
                key: 'pumpPower',
                type: 'rf-radio',
                className: 'pump-power-style',
                props: {
                  label: 'Power',
                  isHorizontalOrientation: true,
                  slot: 'start',
                  isCardEnabled: true,
                  options: [
                    { label: 'Electric', value: 'Electric', disabled: false },
                    { label: 'Diesel', value: 'Diesel', disabled: false },
                    { label: 'Other', value: 'Other', disabled: false }
                  ],
                  isAsteriskNotRequired: true
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent),
                }
              },
              {
                key: 'pumpSupvervision',
                type: 'rf-yes-no',
                className:'rightAlignSupervision',
                props: {
                  label: 'Supervision',
                  isCardEnabled: true,
                  rightAlign: true,
                  isAsteriskNotRequired: true
                },
                expressions: {
                  'props.isDisabled': (field: FormlyFieldConfig) => {
                    const model = field?.options?.formState?.service?._riskReportService?.model;
                    const isSupervisedPump = model?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isSupervisedPump;
                    if (isSupervisedPump) {
                      return true;
                    }
                    return false;
                  },
                  'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent),
                },
                validators: {
                  pumpSupverVision: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      if (formlyBuilderService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isSupervisedPump
                        && !field?.model?.pumpSupvervision) {
                        return false;
                      }
                      return true;
                    },
                    message: 'Pump Supervision on Fire Pump Evaluation is marked as Yes, verify entries.'
                  },
                },
                hooks: {
                  onInit:(field: FormlyFieldConfig) => {
                    const root = field?.form?.root?.value;
                    if(root?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isSupervisedPump) {
                      field.formControl?.setValue(true);
                    }
                  }
                }                
              },
              {
                key: 'pumpController',
                type: 'simple-input',
                className: 'fire-pump-input-width',
                props: {
                  label: 'Controller',
                  isCardEnabled: true,
                  isHorizontalOrientation: true,
                  rightAlign: true,
                  maxLength: 100,
                  isAsteriskNotRequired: true
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent),
                }
              },
              {
                key: 'pumpListed',
                type: 'rf-yes-no',
                props: {
                  label: 'Listed Info',
                  isCardEnabled: true,
                  rightAlign: true,
                  isAsteriskNotRequired: true
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => formlyBuilderService.firePumpTestsService.isTestMostRecentOne(field?.parent?.parent),
                }
              },
            ]
          },
          {
            key: 'firePumpTestResults',
            type: 'rf-table',
            className: 'fire-pump-test-results-container',
            wrappers: ['simple-section-wrapper'],
            props: {
              label: 'Test Results',
              className: 'padding-bottom',
              tableHeaderColumn: [
                { name: 'Test Point', className: 'colwidth-122' },
                { name: 'Suction', className: 'colwidth-92' },
                { name: 'Discharge', className: 'colwidth-92' },
                { name: 'Total Flow', className: 'colwidth-124' },
                { name: 'RPM', className: 'colwidth-92' }
              ],
              tableHeaderRow: ['Churn (0%)', 'Rated (100%)', 'Max (150%)']
            },
            fieldArray: {
              fieldGroup: [
                {
                  key: 'ratedCapacity'
                },
                {
                  key: 'suctionPsi',
                  type: 'simple-input',
                  className: 'fire-pump-input-field colwidth-92',
                  props: {
                    unit: 'PSI',
                    isHorizontalOrientation: true,
                    type: 'number',
                    inputmode: 'tel',
                  },
                },
                {
                  key: 'dischargePsi',
                  type: 'simple-input',
                  className: 'fire-pump-input-field colwidth-92',
                  props: {
                    unit: 'PSI',
                    isHorizontalOrientation: true,
                    type: 'number',
                    inputmode: 'tel',
                  },
                },
                {
                  key: 'totalDischargeGpm',
                  type: 'simple-input',
                  className: 'fire-pump-input-field colwidth-124',
                  props: {
                    unit: 'GPM',
                    isHorizontalOrientation: true,
                    type: 'number',
                    inputmode: 'tel'
                  },                  
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      if (field?.parent['index'] === 0) {
                        field?.formControl?.setValue(0);
                        field.props.readonly = true;
                      }
                    }
                  }
                },
                {
                  key: 'rotationsPerMinute',
                  type: 'simple-input',
                  className: 'fire-pump-input-field colwidth-92',
                  props: {
                    isHorizontalOrientation: true,
                    type: 'number',
                    inputmode: 'tel',
                  },
                }
              ]
            },
          }

        ]
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          if(field.parent?.parent?.props?.isFromSystemTesting){
            const ss1 = field.form.root.value?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1;
            const ss2 = field.form.root.value?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss2?.supplySourceTypeSs2;
            if ((ss1 && ss1 === "Sole Source Pump") || (ss2 && (ss2 === "Sole Source Pump" || ss2 === "Booster Pump"))) {
              return false
            }
            return !true;
          }
          else if (field.parent?.key === 'ss1') {
            const ss1 = field.form.root.value?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1;
            return !(ss1 === "Sole Source Pump");
          }
          else if (field.parent?.key === 'ss2') {
            const ss2 = field.form.root.value?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss2?.supplySourceTypeSs2;
            const ss1 = field.form.root.value?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1;
            if (ss1 && ss1 === "Sole Source Pump" && ss2 === "Sole Source Pump") {
              return true;
            }
            return !((ss2 === "Booster Pump") || (ss2 === "Sole Source Pump"));
          }
          else {
            return true;
          }
        },
        'props.showOnlyRecentOne': (field: FormlyFieldConfig) => {
          return ((field.parent?.key === 'ss1') || (field.parent?.key === 'ss2'))
        },
        'props.parentKey' : (field: FormlyFieldConfig) => field.parent?.key,
        'props.isDelete': (field: FormlyFieldConfig) => field.parent?.parent?.props?.isFromSystemTesting ? true : false,
        'props.actionButtons[0].isDisabled': (field: FormlyFieldConfig) => {
          const isEvaluatedThroughBypassOnly =
            field.form.root.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
          return isEvaluatedThroughBypassOnly;
        }
      }
    }
    );
  }
}
